var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Config
import { FORM_MESSAGES } from '../../../../../../../../utils/constants';
// Contexts
import { useFullScreen } from '../../../../../../../../context/FullScreenContext';
// Hooks
import useScreenSize from '../../../../../../../../utils/Hooks/useScreenSize';
// Components
import PanelForm from './PanelForm';
import FullScreenForm from './FullScreenForm';
// Utils
import { scrollTopSmooth } from '../../../helper/scrollTo';
const UpliftedForm = (_a) => {
    var { form, fullScreenOnly, viewOnly, preFillData, onSubmit, onChange, onCancel, offsetTop, uploadDocument, enableScrollTop, overrideOnCancelConfirm, overrideSubmit, submitModal, cancelModal } = _a, props = __rest(_a, ["form", "fullScreenOnly", "viewOnly", "preFillData", "onSubmit", "onChange", "onCancel", "offsetTop", "uploadDocument", "enableScrollTop", "overrideOnCancelConfirm", "overrideSubmit", "submitModal", "cancelModal"]);
    const { isNineteenTwentyOrMorePixels } = useScreenSize();
    const { setFullScreen, closeFullScreen } = useFullScreen();
    const [formData, setFormData] = useState(preFillData);
    const [openFullScreen, setOpenFullScreen] = useState(fullScreenOnly || !isNineteenTwentyOrMorePixels);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const internalSubmitModal = useRef(submitModal);
    const internalCancelModal = useRef(cancelModal);
    const memoizedOffsetTop = useMemo(() => offsetTop, []);
    useEffect(() => {
        internalSubmitModal.current = submitModal;
        internalCancelModal.current = cancelModal;
    }, [openFullScreen, submitModal, cancelModal]);
    const hasFormData = (data) => {
        return form.pages[0].components
            .filter((component) => !!(component === null || component === void 0 ? void 0 : component.id))
            .map((component) => component.id)
            .map((field) => {
            var _a;
            if (Array.isArray(data === null || data === void 0 ? void 0 : data[field])) {
                return !!((_a = data === null || data === void 0 ? void 0 : data[field]) === null || _a === void 0 ? void 0 : _a.length);
            }
            return !!(data === null || data === void 0 ? void 0 : data[field]);
        })
            .some((item) => !!item);
    };
    const onInternalChange = (data) => {
        setFormData(data);
        onChange(data);
    };
    const onClose = () => {
        if (enableScrollTop) {
            scrollTopSmooth();
        }
    };
    const onInternalSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (internalSubmitModal.current) {
            setShowSubmitModal(true);
            return;
        }
        yield onSubmit(data);
        onClose();
    });
    const discardForm = () => {
        onCancel();
        onClose();
    };
    const onInternalCancel = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (!hasFormData(data)) {
            discardForm();
            return;
        }
        if (internalCancelModal.current) {
            setShowCancelModal(true);
            return;
        }
        if (overrideOnCancelConfirm) {
            discardForm();
            return;
        }
        // Use the browser default.
        // eslint-disable-next-line no-alert
        if (confirm(FORM_MESSAGES.ON_CANCELLATION)) {
            discardForm();
        }
    });
    const setupModal = (modal, setShowModal) => {
        return modal ? React.cloneElement(modal, {
            onProceed: () => {
                setShowModal(false);
                modal.props.onProceed();
                onClose();
            },
            onCancel: () => {
                setShowModal(false);
                modal.props.onCancel();
            },
        }) : null;
    };
    const memoizedModal = useMemo(() => {
        if (showSubmitModal) {
            return setupModal(submitModal, setShowSubmitModal);
        }
        if (showCancelModal) {
            return setupModal(cancelModal, setShowCancelModal);
        }
        return null;
    }, [showSubmitModal, submitModal, showCancelModal, cancelModal, openFullScreen]);
    useEffect(() => {
        if (fullScreenOnly || !isNineteenTwentyOrMorePixels) {
            setFullScreen(_jsx(FullScreenForm, Object.assign({ form: form, viewOnly: viewOnly, preFillData: formData, onSubmit: onInternalSubmit, onChange: onInternalChange, onCancel: onInternalCancel, uploadDocument: uploadDocument, modal: memoizedModal, overrideSubmit: overrideSubmit }, props)));
            setOpenFullScreen(true);
        }
        else {
            setOpenFullScreen(false);
        }
        return () => closeFullScreen();
    }, [fullScreenOnly, isNineteenTwentyOrMorePixels, formData, memoizedModal]);
    if (openFullScreen) {
        return null;
    }
    return (_jsx(PanelForm, Object.assign({ form: form, viewOnly: viewOnly, preFillData: formData, onSubmit: onInternalSubmit, onChange: onInternalChange, onCancel: onInternalCancel, style: { paddingTop: memoizedOffsetTop }, uploadDocument: uploadDocument, modal: memoizedModal, overrideSubmit: overrideSubmit }, props)));
};
UpliftedForm.propTypes = {
    form: PropTypes.object.isRequired,
    fullScreenOnly: PropTypes.bool,
    viewOnly: PropTypes.bool,
    enableScrollTop: PropTypes.bool,
    uploadDocument: PropTypes.bool,
    overrideOnCancelConfirm: PropTypes.bool,
    overrideSubmit: PropTypes.bool,
    preFillData: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    offsetTop: PropTypes.number,
    submitModal: PropTypes.node,
    cancelModal: PropTypes.node,
};
UpliftedForm.defaultProps = {
    fullScreenOnly: false,
    viewOnly: false,
    preFillData: null,
    onSubmit: () => { },
    onChange: () => { },
    onCancel: () => { },
    offsetTop: 0,
    uploadDocument: false,
    overrideOnCancelConfirm: false,
    overrideSubmit: false,
    enableScrollTop: true,
    submitModal: null,
    cancelModal: null,
};
export default UpliftedForm;
