// Global import(s)
import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import DepartureStatus from '../../../../../../components/DepartureStatus/DepartureStatus';
import TaskJourneyDetail from './TaskJourneyDetail';

// Utils
import CommonUtil from '../../../../../../utils/Common/Uplift/commonUtil';
import FlightUtil from '../../../../../../utils/Flight/Uplift/flightUtil';
import PersonUtil from '../../../../../../utils/Person/Uplift/personUtil';
import RisksUtil from '../../../../../../utils/Risks/Uplift/risksUtil';
import MovementUtil from '../../../../../../utils/Movement/Uplift/movementUtil';
import { getTaskId } from '../../../../../../utils/Task/taskUtil';

// Styling
import '../../__assets__/TaskDetail.scss';

const TaskDetail = ({ task }) => {
  const taskId = getTaskId(task);
  const ref = useRef(null);
  const person = PersonUtil.get(task);
  const flight = FlightUtil.get(task);
  const [taskHeaderWidth, setTaskHeaderWidth] = useState(0);
  const departureStatus = FlightUtil.departureStatus(flight);
  const flightNumber = FlightUtil.flightNumber(flight);
  const formattedRisks = RisksUtil.formatHighestThreat(task);
  let groupSize = MovementUtil.groupSize(task);
  const fullName = PersonUtil.formattedName(PersonUtil.firstname(person))((PersonUtil.lastname(person)));
  const iso2NationalityCode = PersonUtil.nationality(person);
  const iso3NationalityCode = CommonUtil.iso3Code(iso2NationalityCode);

  // 20483
  // The groupSize -should- be always positive, but sometimes it comes
  // through as negative, so we're going to alter it;
  if (flightNumber !== null && groupSize < 1) {
    groupSize = 1;
  }

  useLayoutEffect(() => {
    const updateSummary = () => {
      if (ref.current) {
        setTimeout(() => {
          const thisWidth = ref?.current?.offsetWidth;
          setTaskHeaderWidth(thisWidth);
        }, 0);
      }
    };

    updateSummary();

    window.addEventListener('resize', updateSummary);

    return () => window.removeEventListener('resize', updateSummary);
  }, []);

  const getHeaderContainerClass = (newClass) => {
    let sizeClass = 'task-header-mobile';
    if (taskHeaderWidth > 0) {
      if (taskHeaderWidth > 1085) {
        sizeClass = 'task-header-screen';
      } else if (taskHeaderWidth > 790) {
        sizeClass = 'task-header-tablet';
      }
    }
    const headerClassNames = classNames(
      'detail-container__inner',
      'task-header-detail',
      sizeClass,
      newClass,
    );
    return headerClassNames;
  };

  const getHeaderInnerComponent = () => {
    if (!flightNumber && !groupSize) {
      return (
        <div className={getHeaderContainerClass('no-margin')} />
      );
    }

    return (
      <div className={getHeaderContainerClass()}>
        <div className="task-detail">
          <div className="flight-details" id={`flight-details-${taskId}`}>
            <ComponentWrapper show={flightNumber}>
              <p id={`flight-number-${taskId}`} className="ho-body">
                {flightNumber}
              </p>
            </ComponentWrapper>
          </div>
          <div className="journey-detail">
            <TaskJourneyDetail task={task} />
          </div>
          <div className="passenger-count-detail">
            <ComponentWrapper show={groupSize}>
              <p id={`matched-passengers-${taskId}`} className="ho-body">
                1 of {groupSize} pax
              </p>
            </ComponentWrapper>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div ref={ref} className="detail-container__outer">
      {getHeaderInnerComponent()}
      <div className="detail-container__rules">
        <ComponentWrapper show={flightNumber}>
          <hr className="detail-line" />
        </ComponentWrapper>
        <div className="rule-detail">
          <div className="matched-rules">
            <ComponentWrapper show={formattedRisks}>
              <p id={`matched-rule-name-${taskId}`} className="ho-heading-s govuk-!-font-weight-bold">
                {formattedRisks}
              </p>
            </ComponentWrapper>
            <p id={`matched-rule-task-id-${taskId}`} className="ho-secondary-text-xs">
              TID: {taskId}
            </p>
          </div>
          <div className="passenger-details">
            <ComponentWrapper show={fullName}>
              <p id={`passenger-name-${taskId}`} className="ho-body">
                {fullName}
              </p>
            </ComponentWrapper>
            <ComponentWrapper show={iso3NationalityCode}>
              <p id={`passenger-nationality-${taskId}`} className="ho-secondary-text-xs">
                {iso3NationalityCode}
              </p>
            </ComponentWrapper>
          </div>
          <div>
            <DepartureStatus id={`passenger-departure-status-${taskId}`} departureStatus={departureStatus} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TaskDetail);
