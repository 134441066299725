var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { trimEnd } from 'lodash';
// Form
import domainsForm from '../../forms/domains';
import AxiosRequests from '../../api/axiosRequests';
// Configs
import config from '../../utils/config';
import { DOMAIN_TYPE, DOMAIN_SUFFIX } from './constants';
import { PATHS, RBAC } from '../../utils/constants';
// Contexts
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
// Hooks
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import useFetchDomains from '../../utils/Hooks/useFetchDomains';
// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../components/RenderForm/RenderForm';
// Style
import './EditDomains.scss';
const EditDomains = () => {
    const keycloak = useKeycloak();
    const axiosClient = useAxiosInstance(keycloak, config.taskApiUrl);
    const { hasRole } = usePermission();
    const navigate = useNavigate();
    const { domains, isLoading } = useFetchDomains(DOMAIN_TYPE.APPROVED);
    if (!hasRole(RBAC.TARGETER_AIRPAX_OSDT)) {
        return _jsx("p", { children: "You are not authorised to view this page." });
    }
    if (isLoading) {
        return _jsx(LoadingSpinner, { loading: true, children: null });
    }
    // Adapters
    const adaptToPreFillData = () => {
        // If no domains then add an empty one
        if (!domains || domains.length === 0) {
            return { domains: [{ domain: '', id: uuidv4() }] };
        }
        return {
            domains: domains.map(
            // Need to strip domain suffix (.gov.uk) from the domains
            (domain) => ({ domain: trimEnd(domain, DOMAIN_SUFFIX), id: uuidv4() })),
        };
    };
    const adaptFormDataToDomains = (data) => data.domains
        .map(({ domain }) => domain + DOMAIN_SUFFIX);
    // Actions
    const navigateToViewPage = () => navigate(PATHS.APPROVED_DOMAINS + PATHS.VIEW);
    const onCancel = navigateToViewPage;
    const onSubmit = (_a) => __awaiter(void 0, [_a], void 0, function* ({ data }) {
        const domainsPayload = adaptFormDataToDomains(data);
        yield AxiosRequests.updateDomains(axiosClient, DOMAIN_TYPE.APPROVED, domainsPayload);
        navigateToViewPage();
    });
    return (_jsx("div", { id: "edit-domains", children: _jsx(RenderForm, { form: domainsForm(domains), preFillData: adaptToPreFillData(), onSubmit: onSubmit, onCancel: onCancel }) }));
};
export default EditDomains;
