// Global import(s)
import { BackToTop } from '@ukhomeoffice/cop-react-components';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

// Config
import config from '../../../utils/config';

// Context
import { useKeycloak } from '../../../context/Keycloak';
import { useTask } from '../../../context/TaskContext';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import LinkButton from '../../../components/Buttons/LinkButton';
import OfficialWarning from './components/header/OfficialWarning';
import ScanAppOutcome from './components/outcomes/ScanAppOutcome';
import TargetDetails from './components/target/TargetDetails';
import TargetHeader from './components/header/TargetHeader';
import TargetInformation from './components/movement/information/TargetInformation';
import Warnings from './components/banners/Warnings';

// Services
import AxiosRequests from '../../../api/axiosRequests';

// Hook(s)
import useRedirectIfRequired from '../../../utils/Hooks/useRedirectIfRequired';
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';

// Styling
import './TargetSheetPage.scss';

const PreviewTargetSheetPage = ({
  backToCreateTarget,
  mode,
  targetId,
  isSettingView,
  isSubmittedForXrayAnalysis,
  isSubmittingForXrayAnalysis,
  informationSheet,
  submissionPayload,
}) => {
  const [targetSheet, setTargetSheet] = useState(null);
  const {
    taskId,
  } = useTask();
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  if (isSubmittingForXrayAnalysis || isSettingView) {
    return <ApplicationSpinner />;
  }

  if (isSubmittedForXrayAnalysis) {
    return (<ScanAppOutcome movement={informationSheet?.movement} targetId={targetId} />);
  }

  useRedirectIfRequired(mode, targetId);

  const getTaskData = async () => {
    await AxiosRequests.createPreviewTarget(apiClient, submissionPayload)
      .then((res) => {
        setTargetSheet(res);
      })
      .catch(() => {
        setTargetSheet(null);
      });
  };

  useEffect(() => {
    getTaskData();
    return () => {
      AxiosRequests.cancel(source);
    };
  }, [taskId]);

  if (!targetSheet) {
    return null;
  }

  return (
    <div id="preview-target-sheet-page" className="target-sheet-page">
      <LinkButton
        id="back-to-create-target"
        className="govuk-back-link"
        onClick={() => backToCreateTarget()}
      >
        Back to Create target
      </LinkButton>
      <OfficialWarning position="top" preview />
      <TargetHeader
        canRecordOutcome={false}
        onRecordOutcome={() => {}}
        mode={mode}
        informationSheet={targetSheet}
      />
      <Warnings informationSheet={targetSheet} />
      <TargetDetails informationSheet={targetSheet} />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetInformation informationSheet={targetSheet} preview />
        </div>
      </div>
      <BackToTop />
      <OfficialWarning position="bottom" preview />
    </div>
  );
};

export default PreviewTargetSheetPage;
