import { FORM_ACTIONS } from '../../utils/constants';

export const TARGET_RECIPIENT_ID = 'targetRecipient';

const targetRecipient = (canAddFrontlineTeam = false) => {
  const frontlineTeam = {
    value: 'FRONTLINE_TEAM',
    label: 'Frontline team',
    nested: [
      {
        id: 'FRONTLINE_TEAM',
        fieldId: 'FRONTLINE_TEAM',
        label: 'Select a frontline team to add',
        type: 'autocomplete',
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: 'Select a frontline team',
        item: {
          value: 'customName',
          label: 'customName',
        },
        custom_errors: [
          {
            type: 'required',
            message: 'Select a frontline team to send the target to',
          },
        ],
      },
    ],
  };

  const borderOfficer = {
    value: 'BORDER_OFFICER',
    label: 'UK Border Force officer',
    nested: [
      {
        id: 'BORDER_OFFICER',
        fieldId: 'BORDER_OFFICER',
        label: 'Select a Border Force officer to add',
        'aria-label': 'Select a Border Force officer to add',
        type: 'autocomplete',
        required: true,
        clearable: true,
        openOnClick: true,
        multi: true,
        placeholder: 'Select a Border Force officer to add',
        data: {
          url: '/cop-targeting-api/v2/users/front-line-officers',
        },
        item: {
          value: 'email',
          label: 'email',
        },
        custom_errors: [
          {
            type: 'required',
            message: 'Select a UK Border Force officer to send the target to',
          },
        ],
      },
    ],
  };

  const recipientOptions = [
    ...(canAddFrontlineTeam ? [frontlineTeam] : []),
    borderOfficer,
  ];

  return {
    id: 'targetRecipient',
    version: '1.0.0',
    name: 'targetRecipient',
    title: 'Target recipient',
    type: 'form',
    components: [],
    pages: [{
      id: 'targetRecipient',
      name: 'targetRecipient',
      components: [
        {
          id: 'recipOptions',
          fieldId: 'recipOptions',
          label: 'Select a type of recipient to add',
          type: 'checkboxes',
          data: {
            options: recipientOptions,
          },
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Select a type of recipient to add',
            },
          ],
        },

      ],
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Add recipients and close',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    }],
  };
};

export default targetRecipient;
