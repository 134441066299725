import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

// 1912px is to be the screen width that controls displaying content on same page or another page.
export const DEFAULT_DESKTOP_SCREEN_WIDTH = 1912;
export const DEFAULT_TABLET_SCREEN_WIDTH = 992;
export const DEFAULT_SMALL_TABLET_SCREEN_WIDTH = 864;
export const DEFAULT_SMALLER_DEVICE_SCREEN_WIDTH = 640;

const useScreenSize = () => {
  const { width } = useWindowSize();
  const [isNineteenTwentyOrMorePixels, setIsNineteenTwentyOrMorePixels] = useState(width >= DEFAULT_DESKTOP_SCREEN_WIDTH);
  const [isWidthDesktop, setIsWidthDesktop] = useState(width >= DEFAULT_TABLET_SCREEN_WIDTH);
  const [isWidthTabletLandscape, setIsWidthTabletLandscape] = useState(width >= DEFAULT_SMALL_TABLET_SCREEN_WIDTH && width < DEFAULT_TABLET_SCREEN_WIDTH);
  const [isWidthSmallTabletLandscape, setIsWidthSmallTabletLandscape] = useState(width >= DEFAULT_SMALLER_DEVICE_SCREEN_WIDTH && width < DEFAULT_SMALL_TABLET_SCREEN_WIDTH);
  const [isWidthSmallerDevice, setIsWidthSmallerDevice] = useState(width < DEFAULT_SMALLER_DEVICE_SCREEN_WIDTH);

  useEffect(() => {
    setIsNineteenTwentyOrMorePixels(width >= DEFAULT_DESKTOP_SCREEN_WIDTH);
    setIsWidthDesktop(width >= DEFAULT_TABLET_SCREEN_WIDTH);
    setIsWidthTabletLandscape(width >= DEFAULT_SMALL_TABLET_SCREEN_WIDTH && width < DEFAULT_TABLET_SCREEN_WIDTH);
    setIsWidthSmallTabletLandscape(width >= DEFAULT_SMALLER_DEVICE_SCREEN_WIDTH && width < DEFAULT_SMALL_TABLET_SCREEN_WIDTH);
    setIsWidthSmallerDevice(width < DEFAULT_SMALLER_DEVICE_SCREEN_WIDTH);
  }, [width]);

  return { isNineteenTwentyOrMorePixels, isWidthTabletLandscape, isWidthSmallTabletLandscape, isWidthSmallerDevice, isWidthDesktop };
};

export default useScreenSize;
