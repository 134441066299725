import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading } from '@ukhomeoffice/cop-react-components';
import { withCount } from 'pluralise';
// Config
import { ALL_SECURITY_LABELS, SECURITY_LABELS, VIEW } from '../../../../../../../../utils/constants';
// Component
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import HeaderModule from '../generics/HeaderModule';
import ThreatType from './ThreatType';
// Context
import { useView } from '../../../../../../../../context/ViewContext';
// Utils
import { getMatchedRules, getS4RulesTotalScore } from '../../../../../../../../utils/Risks/Uplift/risksUtil';
import { getTotalNumberOfPersons, getAllPersonsOfInterest } from '../../../../../../../../utils/Person/personUtil';
// Styling
import './ThreatTypeModule.scss';
const ThreatTypeModule = ({ task }) => {
    var _a;
    const { view } = useView();
    // General Aviation has no threats;
    if (view === VIEW.GENERAL_AVIATION) {
        return null;
    }
    const totalPersonsOfInterest = getAllPersonsOfInterest(task).length;
    let rules = getMatchedRules(task);
    const removeNonViewTriggeredRules = (basicRules) => {
        if (!view) {
            return basicRules;
        }
        const invalidLabels = ALL_SECURITY_LABELS.filter((label) => { var _a; return (_a = SECURITY_LABELS[view]) === null || _a === void 0 ? void 0 : _a.includes(label); });
        const newRules = basicRules.reduce((prev, current) => {
            var _a;
            const newLabels = (_a = current.securityLabels) === null || _a === void 0 ? void 0 : _a.filter((label) => !invalidLabels.includes(label));
            if (newLabels.length > 0) {
                prev.push(current);
            }
            return prev;
        }, []);
        return newRules;
    };
    if (rules && rules.length > 0) {
        rules = removeNonViewTriggeredRules(rules);
    }
    const getRulesHeaderText = () => {
        const rulesLength = (rules === null || rules === void 0 ? void 0 : rules.length) || 0;
        const totalPassengers = getTotalNumberOfPersons(task);
        return `${withCount(rulesLength, '% rule', '% rules')} matched on ${totalPersonsOfInterest} of ${totalPassengers} pax`;
    };
    const containsS4RuleEntity = () => {
        return rules.some((rule) => !!(rule === null || rule === void 0 ? void 0 : rule.s4Rule));
    };
    const hasS4RulesTotalScore = (value) => {
        return !!value;
    };
    const toS4TotalScoreText = () => {
        return `Total score : ${getS4RulesTotalScore(task)}`;
    };
    const getPersonsMatchingRule = (rule) => {
        var _a;
        return (_a = rule.taskIds) === null || _a === void 0 ? void 0 : _a.map((taskId) => getAllPersonsOfInterest(task)
            .find((person) => person.taskId === taskId));
    };
    const shouldShowTotalRulesScore = containsS4RuleEntity() && hasS4RulesTotalScore((_a = task.risks) === null || _a === void 0 ? void 0 : _a.s4RulesTotalScore);
    return (_jsxs("div", { children: [_jsx(Heading, { id: "threat-section-header", size: "l", children: "Threat/abuse type" }), _jsx("div", { className: "govuk-grid-column-full", children: _jsx(HeaderModule, { id: "rules-matched-header", items: [
                        {
                            content: (_jsxs(_Fragment, { children: [_jsx("div", { className: `grid-item-span-${shouldShowTotalRulesScore ? '3' : '4'}`, children: _jsx(Heading, { id: "rules-matched-count", size: "m", children: getRulesHeaderText() }) }, "header"), _jsx(ComponentWrapper, { show: shouldShowTotalRulesScore, children: _jsx("div", { children: _jsx("p", { id: "rules-total-score", children: toS4TotalScoreText() }) }, "rules-total-score") }), _jsx("div", { id: "total-tasks", children: _jsx("p", { children: `${withCount(totalPersonsOfInterest, '% task', '% tasks')} on this booking` }) }, "total-tasks")] })),
                        },
                    ] }) }), rules.length > 0
                && (rules === null || rules === void 0 ? void 0 : rules.map((rule, idx) => _jsx(ThreatType, { rule: rule, index: idx, personsMatchingRule: getPersonsMatchingRule(rule) }, `${rule.id}-${idx}`)))] }));
};
export default ThreatTypeModule;
