/* eslint-disable import/prefer-default-export */
// Config(s)
import { VIEW } from '../utils/constants';

const COMMON_DATE_FILTER_COMPONENTS = [
  'arrivalDate',
  'arrivalIsStartDate',
  'arrivalIsFromStartDate',
  'arrivalIsToEndDate',
  'arrivalRangeIsStartDate',
  'arrivalRangeIsEndDate',
  'departureDate',
  'departureIsStartDate',
  'departureIsFromStartDate',
  'departureIsToEndDate',
  'departureRangeIsStartDate',
  'departureRangeIsEndDate',
  'taskCreatedDate',
  'taskCreatedDateIsStartDate',
  'taskCreatedDateIsFromStartDate',
  'taskCreatedDateIsToEndDate',
  'taskCreatedDateRangeIsStartDate',
  'taskCreatedDateRangeIsEndDate',
];

const AIRPAX_FILTER_COMPONENTS = [
  'claimedByMe',
  'targetingTeams',
  'rules',
  'selectors',
  'journeyDirections',
  'passengerStatuses',
  'journeyId',
  ...COMMON_DATE_FILTER_COMPONENTS,
  'searchText',
];

const GENERAL_AVIATION_FILTER_COMPONENTS = [
  'claimedByMe',
  'journeyDirections',
  ...COMMON_DATE_FILTER_COMPONENTS,
  'searchText',
];

const COMPONENT_ORDER = {
  [VIEW.AIRPAX]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.AIRPAX_V2]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.GENERAL_AVIATION]: [
    ...GENERAL_AVIATION_FILTER_COMPONENTS,
  ],
  [VIEW.COMMODITIES]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.CTBP]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.IDP]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.OSDT]: [
    ...AIRPAX_FILTER_COMPONENTS,
  ],
  [VIEW.RORO]: [
    'claimedByMe',
    'searchText',
    'mode',
    'taskGenerationTypes',
    'selectorGroupReferences',
    'rules',
    'journeyDirections',
    'portOfArrival',
    'arrivalDate',
    'arrivalIsStartDate',
    'arrivalIsFromStartDate',
    'arrivalIsToEndDate',
    'arrivalRangeIsStartDate',
    'arrivalRangeIsEndDate',
  ],
  [VIEW.RORO_IO]: [
    'categories',
    'movementModes',
    'journeyDirections',
    'groupCodes',
    'targetsBy',
  ],
  [VIEW.AIRPAX_RCCU]: [
    'categories',
    'journeyDirections',
    'groupCodes',
    'targetsBy',
  ],
  [VIEW.AIRPAX_HO]: [
    'journeyDirections',
    'groupCodes',
  ],
  [VIEW.AIRPAX_IO]: [
    'journeyDirections',
  ],
};

export { COMPONENT_ORDER };
