// Config
import { FORM_ACTIONS } from '../../utils/constants';

// Util(s)
import { PersonUtil } from '../../utils';

const toBookingOption = (booking) => {
  if (!booking) {
    return null;
  }

  return {
    value: 'BOOKING',
    label: 'Booking',
    className: 'add-task-detail-booking',
    nested: [
      {
        id: 'bookingDetails',
        fieldId: 'bookingDetails',
        label: 'Enter content to add to this section',
        'aria-label': 'enter details or note',
        className: 'add-task-detail-booking-textarea',
        type: 'textarea',
        rows: 3,
        required: true,
        description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
        custom_errors: [
          {
            type: 'required',
            message: 'Enter content to add to Booking',
          },
        ],
      },
    ],
  };
};

const toBaggageOption = (baggage) => {
  if (!baggage) {
    return null;
  }

  return {
    value: 'BAGGAGE',
    label: 'Baggage',
    className: 'add-task-detail-baggage-checkbox',
    nested: [
      {
        id: 'baggageDetails',
        fieldId: 'baggageDetails',
        label: 'Enter content to add to this section',
        'aria-label': 'enter details or note',
        className: 'add-task-detail-baggage-textarea',
        type: 'textarea',
        rows: 3,
        required: true,
        description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
        custom_errors: [
          {
            type: 'required',
            message: 'Enter content to add to Baggage',
          },
        ],
      },
    ],
  };
};

const hasContentWithImage = (person) => {
  return !!person.additionalContent.find((content) => !!content?.url);
};

const toPassengerOption = (person, index, isLastPerson) => {
  const toPersonDocuments = (personDocuments, position) => {
    const documents = [];
    personDocuments.forEach((document, documentPosition) => {
      const adjectedPosition = position + 1;
      documents.push({
        value: `PASSENGER_${adjectedPosition}_DOCUMENT_${documentPosition + 1}_${document?.number}`,
        label: `Document - ${document?.number}`,
        className: 'passenger-document add-task-detail-passenger-document',
        nested: [
          {
            id: `passenger${adjectedPosition}DocumentDetails_${documentPosition + 1}_${document?.number}`,
            fieldId: `passenger${adjectedPosition}DocumentDetails_${documentPosition + 1}_${document?.number}`,
            label: 'Enter content to add to this section',
            'aria-label': 'enter details or note',
            className: 'add-task-detail-passenger-document-textarea',
            type: 'textarea',
            rows: 3,
            required: true,
            description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
            custom_errors: [
              {
                type: 'required',
                message: `Enter content to add to Passenger ${adjectedPosition} document - ${document?.number}`,
              },
            ],
          },
        ],
      });
    });
    return documents;
  };
  const adjustedPosition = index + 1;
  return [
    `Passenger ${adjustedPosition} - ${PersonUtil.formattedName(PersonUtil.firstname(person))(PersonUtil.lastname(person), true)}`,
    {
      value: `PASSENGER_${adjustedPosition}`,
      label: 'Passenger',
      className: 'add-task-detail-passenger',
      nested: [
        {
          id: `passenger${adjustedPosition}Details`,
          fieldId: `passenger${adjustedPosition}Details`,
          label: 'Enter content to add to this section',
          'aria-label': 'enter details or note',
          className: 'add-task-detail-passenger-textarea',
          type: 'textarea',
          required: true,
          rows: 3,
          description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
          custom_errors: [
            {
              type: 'required',
              message: `Enter content to add to Passenger ${adjustedPosition}`,
            },
          ],
        },
      ],
    },
    ...(!hasContentWithImage(person) ? [
      {
        value: `PASSENGER_${adjustedPosition}_PHOTO`,
        label: 'Add a photo',
        className: 'add-task-detail-photo',
        nested: [
          {
            id: `passenger${adjustedPosition}Photo`,
            fieldId: `passenger${adjustedPosition}Photo`,
            type: 'container',
            components: [
              {
                id: 'photo',
                fieldId: 'photo',
                label: 'Add a photo',
                'aria-label': 'add a photo',
                hint: 'Photo will be included on a target sheet, if issued.',
                type: 'file',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: `Choose a photo to add to Passenger ${adjustedPosition}`,
                  },
                ],
              },
              {
                id: 'source',
                fieldId: 'source',
                label: 'Enter the source of the photo',
                'aria-label': 'enter the source of the photo',
                className: 'indent-element',
                type: 'text',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Enter the source of the photo',
                  },
                ],
                show_when: {
                  field: './photo.type',
                  op: 'contains',
                  value: 'image/',
                },
              },
              {
                id: 'approximateDate',
                fieldId: 'approximateDate',
                label: 'Enter the approximate date of the photo, if available',
                'aria-label': 'enter the approximate date of the photo, if available',
                className: 'indent-element',
                type: 'text',
                show_when: {
                  field: './photo.type',
                  op: 'contains',
                  value: 'image/',
                },
              },
            ],
          },
        ],
      },
    ] : []),
    ...toPersonDocuments(person.documents, index),
    (isLastPerson && ' '), // Add blank divider if person is the last person.
  ];
};

const toPassengerOptions = (persons) => {
  const options = [];
  persons.forEach((person, index) => {
    const isLastPerson = persons.length - 1 === index;
    options.push(...toPassengerOption(person, index, isLastPerson));
  });
  return options.filter((option) => !!option);
};

const addDetail = (booking, baggage, persons) => {
  return {
    id: 'addDetail',
    version: '1.0.1',
    name: 'addDetail',
    title: 'Add task detail',
    type: 'form',
    components: [],
    pages: [{
      id: 'addDetail',
      name: 'addDetail',
      components: [
        {
          type: 'heading',
          size: 'l',
          content: 'Add task detail',
        },
        {
          type: 'html',
          tagName: 'p',
          content: 'Select the section you want to add to and enter the content.',
        },
        {
          type: 'html',
          tagName: 'p',
          className: 'font--grey',
          content: 'Additions are editable until you either unclaim the task, issue a target or complete your assessment of this task. Photos are editable until a task is completed.',
        },
        {
          type: 'html',
          tagName: 'p',
          className: 'font--grey',
          content: 'Task detail additions are included on target sheets.',
        },
        {
          id: 'addDetail',
          fieldId: 'addDetail',
          label: '', // This is blank intentionally
          'aria-label': 'add detail',
          type: 'checkboxes',
          className: 'add-task-detail-voyage',
          classBlock: 'add-task-detail-voyage govuk-checkboxes',
          required: true,
          data: {
            options: [
              {
                value: 'VOYAGE',
                label: 'Voyage',
                'aria-label': 'voyage',
                className: 'add-task-detail-voyage',
                nested: [
                  {
                    id: 'voyageDetails',
                    fieldId: 'voyageDetails',
                    className: 'add-task-detail-voyage-textarea',
                    label: 'Enter content to add to this section',
                    'aria-label': 'enter details or note',
                    type: 'textarea',
                    rows: 3,
                    required: true,
                    description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
                    custom_errors: [
                      {
                        type: 'required',
                        message: 'Enter content to add to Voyage',
                      },
                    ],
                  },
                ],
              },
              toBookingOption(booking),
              ...toPassengerOptions(persons),
              toBaggageOption(baggage),
            ].filter((item) => !!item),
          },
          description: 'Is there an error message to be displayed when an option is not selected and a save action is performed?',
          custom_errors: [
            {
              type: 'required',
              message: 'Select a section or Cancel',
            },
          ],
        },
      ],
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          className: 'add-task-detail-continue',
          label: 'Continue',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          className: 'add-task-detail-cancel',
          classModifiers: 'secondary',
        },
      ],
    }],
  };
};

export default addDetail;
