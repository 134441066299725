import { Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config(s)
import { PNC_OUTCOMES } from '../TargetInformation/constants';

// Util(s)
import { CredibilityChecksUtil } from '../index';

const PNC_CATEGORIES = {
  CLASS: {
    A: 'CLASS_A',
    B: 'CLASS_B',
    C: 'CLASS_C',
  },
  SMUGGLING: {
    GOODS: {
      EXCISE: 'EXCISE',
      FALSE_IMPORT: 'FALSE_IMPORT',
      MONEY_LAUNDERING: 'MONEY_LAUNDERING',
    },
    PEOPLE: {
      HUMAN_TRAFFICKING: 'HUMAN_TRAFFICKING',
      FALSE_DOCUMENTS: 'FALSE_DOCUMENTS',
    },
  },
  WEAPONS: {
    EXPLOSIVE_MATERIALS: 'EXPLOSIVE_MATERIALS',
    FIREARMS: 'FIREARMS',
    OFFENSIVE_WEAPONS: 'OFFENSIVE_WEAPONS',
  },
};

const FORMATTED_OUTCOMES = {
  [PNC_OUTCOMES.INSUFFICIENT_TIME]: 'There was not enough time to complete a PNC search',
  [PNC_OUTCOMES.CHECK_NOT_RELEVANT_FOR_PERSON]: 'A PNC check is not relevant for this person',
  [PNC_OUTCOMES.INSUFFICIENT_IDENTITY_INFORMATION]: 'There was not enough identity information available to perform a PNC check',
  [PNC_OUTCOMES.IRRELEVANT_INFORMATION_FOUND]: 'A PNC record was found but it does not contain any relevant information',
  [PNC_OUTCOMES.NO_RECORDS_FOUND]: 'No PNC records were found based on the identity information available',
};

const toFormattedPncValue = (value) => {
  switch (value) {
    case PNC_CATEGORIES.CLASS.A: {
      return 'Class A';
    }
    case PNC_CATEGORIES.CLASS.B: {
      return 'Class B';
    }
    case PNC_CATEGORIES.CLASS.C: {
      return 'Class C';
    }
    case PNC_CATEGORIES.SMUGGLING.GOODS.EXCISE: {
      return 'Actively relating to excise goods';
    }
    case PNC_CATEGORIES.SMUGGLING.GOODS.FALSE_IMPORT: {
      return 'False import licenses';
    }
    case PNC_CATEGORIES.SMUGGLING.GOODS.MONEY_LAUNDERING: {
      return 'Money laundering';
    }
    case PNC_CATEGORIES.WEAPONS.EXPLOSIVE_MATERIALS: {
      return 'Explosive materials';
    }
    case PNC_CATEGORIES.WEAPONS.FIREARMS: {
      return 'Firearms';
    }
    case PNC_CATEGORIES.WEAPONS.OFFENSIVE_WEAPONS: {
      return 'Offensive weapons (including non-lethal firearms)';
    }
    case PNC_CATEGORIES.SMUGGLING.PEOPLE.HUMAN_TRAFFICKING: {
      return 'Human trafficking';
    }
    case PNC_CATEGORIES.SMUGGLING.PEOPLE.FALSE_DOCUMENTS: {
      return 'False identity documents';
    }
    default: {
      return null;
    }
  }
};

export const toFormattedPncType = (value) => {
  switch (value) {
    case 'VIOLENCE': {
      return 'Violence';
    }
    case 'FIREARMS': {
      return 'Firearms';
    }
    case 'DRUG_USE': {
      return 'Drug Use';
    }
    case 'SELF_HARM': {
      return 'Self Harm';
    }
    case 'MISSING': {
      return 'Missing';
    }
    case 'WANTED': {
      return 'Wanted';
    }
    case 'MENTAL': {
      return 'Mental';
    }
    case 'STAFF_CONCERN': {
      return 'Staff Concern';
    }
    case 'POI_CONCERN': {
      return 'POI Concern';
    }
    default: {
      return null;
    }
  }
};

export const toPncWarningTag = (person) => {
  // This change is to account for this function being used by both
  // task and target sheet related code;
  let personPnc = CredibilityChecksUtil.getPnc(person) ?? CredibilityChecksUtil.getPncResult(person);

  const pc = CredibilityChecksUtil.isPotentialPnc(personPnc);
  if (!pc) {
    return null;
  }

  return <Tag id="pnc-warnings-tag" className="tag tag--warning" text="PNC Warnings" />;
};

export const toFormattedValue = (value) => {
  if (!value) {
    return 'None';
  }
  return value;
};

const hasDrugsPossession = (categories) => {
  return categories?.drugsPossession && categories?.drugsPossession?.length;
};

const hasDrugsSupply = (categories) => {
  return categories?.drugsSupply && categories?.drugsSupply?.length;
};

const hasDrugsTrafficking = (categories) => {
  return categories?.drugsTrafficking && categories?.drugsTrafficking?.length;
};

const hasWeaponsPossession = (categories) => {
  return categories?.weaponsPossession && categories?.weaponsPossession?.length;
};

const hasWeaponsSupply = (categories) => {
  return categories?.weaponsSupply && categories?.weaponsSupply?.length;
};

const hasGoodsSmuggling = (categories) => {
  return categories?.goodsSmuggling && categories?.goodsSmuggling?.length;
};

const hasPeopleSmuggling = (categories) => {
  return categories?.peopleSmuggling && categories?.peopleSmuggling?.length;
};

const toRecordsFoundItem = (data, linePrefix = '', lineSuffix = '') => {
  return (
    <p className="govuk-!-margin-top-0">
      {`${linePrefix}${data
        .map((item) => toFormattedPncValue(item))
        .filter((item) => !!item)
        .join(', ')}${lineSuffix}`}
    </p>
  );
};

export const toRecordsFound = (categories) => {
  if (!categories) {
    return toFormattedValue(null);
  }

  const items = [];
  if (hasDrugsPossession(categories)) {
    items.push(toRecordsFoundItem(categories?.possessionClass || categories?.drugsPossession, 'Possession of ', ' drugs'));
  }
  if (hasDrugsSupply(categories)) {
    items.push(toRecordsFoundItem(categories?.supplyClass || categories?.drugsSupply, 'Supply of ', ' drugs'));
  }
  if (hasDrugsTrafficking(categories)) {
    items.push(toRecordsFoundItem(categories?.traffickingClass || categories?.drugsTrafficking, 'Trafficking of ', ' drugs'));
  }
  if (hasWeaponsPossession(categories)) {
    items.push(toRecordsFoundItem(categories?.weaponsPossessionOpts || categories?.weaponsPossession, 'Possession of '));
  }
  if (hasWeaponsSupply(categories)) {
    items.push(toRecordsFoundItem(categories?.weaponsSupplyOptions || categories?.weaponsSupply, 'Supply of '));
  }
  if (hasGoodsSmuggling(categories)) {
    items.push(toRecordsFoundItem(categories?.goodsSmugglingOpts || categories?.goodsSmuggling));
  }
  if (hasPeopleSmuggling(categories)) {
    items.push(toRecordsFoundItem(categories?.peopleSmugglingOpts || categories?.peopleSmuggling));
  }

  if (!items.length) {
    return toFormattedValue(null);
  }

  return (
    <div>
      {items.map((item, index) => (
        <div key={`record-found-${index + 1}`}>{item}</div>
      ))}
    </div>
  );
};

export const toFormattedOutcome = (outcome) => {
  return FORMATTED_OUTCOMES[outcome] || null;
};
