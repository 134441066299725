import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '@ukhomeoffice/cop-react-components/dist/Heading';
// Component(s)
import Person from './Person';
const Persons = ({ persons, booking, flight, journey, movementId, movementIds, s4Person, title = 'Passenger' }) => {
    if (!(persons === null || persons === void 0 ? void 0 : persons.length)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { id: `${title.toLowerCase()}-section-header`, size: "m", children: title === 'Passenger' ? 'Persons of interest' : 'Co-traveller' }), persons.map((person, index) => (_jsx(Person, { positionedAt: index, booking: booking, person: person, flight: flight, journey: journey, movementId: movementId, movementIds: movementIds, s4Person: index === 0 ? s4Person : null, title: title }, person.id)))] }));
};
export default Persons;
