import { v4 as uuidv4 } from 'uuid';
import EntityUtil from '../../../../../../utils/Entity/entityUtil';
const addContent = (people, addition, type, targetSheetAddition, dateTimeStamp, user) => {
    people.forEach((person) => {
        var _a, _b;
        if (EntityUtil.isMatchingEntity(person.entityId, addition.entityId)) {
            if (!person[type] && targetSheetAddition) {
                person[type] = person.additionalContent;
            }
            person[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                url: addition.content.url,
                filename: addition.content.filename,
                source: addition.content.source,
                approximateDateTaken: (_a = addition.content) === null || _a === void 0 ? void 0 : _a.approximateDateTaken,
                user,
                timestamp: dateTimeStamp,
                editable: true,
            });
            if (targetSheetAddition) {
                person.photograph = {
                    approxPhotoTaken: ((_b = addition.content) === null || _b === void 0 ? void 0 : _b.approximateDateToken) || null,
                    uri: addition.content.url,
                };
            }
        }
    });
};
const addNonPhoto = (people, addition, type, dateTimeStamp, user) => {
    people.forEach((person) => {
        if (EntityUtil.isMatchingEntity(person.entityId, addition.entityId)) {
            person[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                content: addition.content.content,
                user,
                timestamp: dateTimeStamp,
                editable: true,
            });
        }
    });
};
const addDocument = (people, addition, type, dateTimeStamp, user) => {
    people.forEach((person) => {
        if (person.documents) {
            person.documents.forEach((document) => {
                if (EntityUtil.isMatchingEntity(document.entityId, addition.entityId)) {
                    document[type].unshift({
                        id: addition.content.id,
                        type: addition.content.type,
                        content: addition.content.content,
                        user,
                        timestamp: dateTimeStamp,
                        editable: true,
                    });
                }
            });
        }
    });
};
const addAdditionalContentToTask = (task, additionalContent, user, dateTimeStamp, addActivity, targetSheetAddition = false) => {
    const addTaskActivity = (activities) => {
        const activity = {
            id: uuidv4(),
            content: 'additional details added',
            user,
            timestamp: dateTimeStamp,
        };
        activities === null || activities === void 0 ? void 0 : activities.unshift(activity);
    };
    // #19801 The reason this is here is because
    //   tasks have additionalContent array (singular)
    //   targets has additionalContents array (plural)
    // We added the targetSheetAddition prop so we can differentiate between
    // these if we need to, like when the preview target sheet needs the
    // task data but with target structure;
    const type = targetSheetAddition ? 'additionalContents' : 'additionalContent';
    additionalContent === null || additionalContent === void 0 ? void 0 : additionalContent.forEach((addition) => {
        var _a, _b, _c, _d, _e, _f;
        if (addition.type === 'BOOKING') {
            task.movement.booking[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                content: addition.content.content,
                user,
                timestamp: dateTimeStamp,
                editable: true,
            });
        }
        else if (addition.type === 'BAGGAGE') {
            task.movement.baggage[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                content: addition.content.content,
                user,
                timestamp: dateTimeStamp,
                editable: true,
            });
        }
        else if (addition.type === 'VOYAGE') {
            task.movement.journey[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                content: addition.content.content,
                user,
                timestamp: dateTimeStamp,
                editable: true,
            });
        }
        else if (addition.type === 'PASSENGER') {
            const poi = (_b = (_a = task === null || task === void 0 ? void 0 : task.movement) === null || _a === void 0 ? void 0 : _a.personsOfInterest) !== null && _b !== void 0 ? _b : [];
            const others = (_d = (_c = task === null || task === void 0 ? void 0 : task.movement) === null || _c === void 0 ? void 0 : _c.otherPersons) !== null && _d !== void 0 ? _d : [];
            if (addition.content.type === 'PHOTO') {
                addContent(poi, addition, type, targetSheetAddition, dateTimeStamp, user);
                addContent(others, addition, type, targetSheetAddition, dateTimeStamp, user);
            }
            else {
                addNonPhoto(poi, addition, type, dateTimeStamp, user);
                addNonPhoto(others, addition, type, dateTimeStamp, user);
            }
            task.movement.personsOfInterest = poi;
            task.movement.otherPersons = others;
        }
        else if (addition.type === 'DOCUMENT') {
            const poi = (_e = task === null || task === void 0 ? void 0 : task.movement) === null || _e === void 0 ? void 0 : _e.personsOfInterest;
            const others = (_f = task === null || task === void 0 ? void 0 : task.movement) === null || _f === void 0 ? void 0 : _f.otherPersons;
            addDocument(poi, addition, type, dateTimeStamp, user);
            addDocument(others, addition, type, dateTimeStamp, user);
            task.movement.personsOfInterest = poi;
            task.movement.otherPersons = others;
        }
    });
    if (addActivity) {
        addTaskActivity(task === null || task === void 0 ? void 0 : task.activities);
    }
    return task;
};
const addAdditionalContent = (task, submissionPayload, user, dateTime, addTaskActivity, targetSheetAddition) => {
    const updatedTask = addAdditionalContentToTask(task, submissionPayload, user, dateTime, addTaskActivity, targetSheetAddition);
    return updatedTask;
};
export default addAdditionalContent;
