const getForm = (isFirstDomain) => ({
    id: 'editDomains',
    version: '1.0.0',
    name: 'editDomains',
    title: 'Edit Domains',
    type: 'form',
    components: [
        {
            id: 'domains',
            fieldId: 'domains',
            type: 'collection',
            labels: {
                item: 'Email domain',
                initial: 'Add domain',
                add: 'Add another domain',
            },
            countOffset: 0,
            item: [
                {
                    id: 'domain',
                    fieldId: 'domain',
                    required: true,
                    'aria-label': 'domain',
                    type: 'text',
                    prefix: '@',
                    suffix: '.gov.uk',
                },
            ],
        },
    ],
    pages: [
        {
            id: 'domainsPage',
            name: 'domainsPage',
            title: 'Edit approved email domains',
            components: [
                'Add, edit or remove domains from the list of UK government and law enforcement agencies.',
                {
                    type: 'heading',
                    size: 'm',
                    content: isFirstDomain ? 'Add an email domain' : 'Approved email domains',
                },
                {
                    use: 'domains',
                },
            ],
            actions: [
                {
                    type: 'submit',
                    validate: true,
                    label: 'Save and close',
                },
                {
                    type: 'cancel',
                    validate: false,
                    label: 'Cancel',
                    classModifiers: 'secondary',
                },
            ],
        },
    ],
});
const getDomainsForm = (currentDomains) => {
    const isFirstDomain = !Array.isArray(currentDomains) || currentDomains.length === 0;
    return getForm(isFirstDomain);
};
export default getDomainsForm;
