import React, { forwardRef } from 'react';

// Config(s)
import config from '../../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../../context/TaskContext';

// Services
import AxiosRequests from '../../../../../../../../api/axiosRequests';

// Components
import NoteModal from './NoteModal';
import UpliftedForm from '../forms/UpliftedForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Form
import form from '../../../../../../../../forms/uplift/addNote';

// Styling
import './AddNote.scss';

const AddNote = forwardRef(({ onClose, offsetTop, ...props }, ref) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { setTask, taskId } = useTask();

  useSetTabTitle(TAB_TITLES.ADD_TASK_NOTE);

  const onSubmit = async ({ data }) => {
    await AxiosRequests.saveTaskGroupNote(apiClient, taskId, [
      {
        value: data?.addNote,
      },
    ])
      .then((updatedTask) => {
        setTask(updatedTask);
        onClose();
      })
      .catch((exception) => console.error(exception.message));
  };

  return (
    <div ref={ref} {...props}>
      <UpliftedForm
        form={form}
        onSubmit={onSubmit}
        onCancel={onClose}
        offsetTop={offsetTop}
        cancelModal={<NoteModal onProceed={onClose} />}
      />
    </div>
  );
});

export default AddNote;
