import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useKeycloak } from '../../../../../../context/Keycloak';

// context
import { ApplicationContext } from '../../../../../../context/ApplicationContext';
import { ViewContext } from '../../../../../../context/ViewContext';

// Services
import AxiosRequests from '../../../../../../api/axiosRequests';

// Components
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../components/RenderForm/RenderForm';

// Config
import config from '../../../../../../utils/config';
import { VIEW } from '../../../../../../utils/constants';

// Hook(s)
import useFetchInformationSheet from '../../../../../../utils/Hooks/useFetchInformationSheet';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../utils/Hooks/useSetTabTitle';

// Utils
import getTisForm from '../../../../../../utils/Form/ReactForm/getTisForm';
import { useAxiosInstance } from '../../../../../../utils/Axios/axiosInstance';
import { TargetInformationUtil } from '../../../../../../utils';

// Styling
import './IssueTarget.scss';

const IssueTarget = ({ isSubmitted, onCancel, setSubmitted, taskData, children }) => {
  const { taskId } = useParams();
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const apiRefDataClient = useAxiosInstance(keycloak, config.refdataApiUrl);
  const [isLoading, setIsLoading] = useState(true);
  const { getInformationSheet, storeInformationSheet } = useContext(ApplicationContext);
  const { view } = useContext(ViewContext);
  const { informationSheet, isLoading: isLoadingTargetInformationSheet } = useFetchInformationSheet(taskId);

  const fetchDocTypes = async () => {
    return AxiosRequests.fetchRefData(
      apiRefDataClient,
      '/v2/entities/documenttype?sort=shortdescription.asc&mode=dataOnly&validDateTime=true',
    );
  };

  const fetchSystemChecks = async () => {
    return AxiosRequests.fetchRefData(
      apiRefDataClient,
      '/v2/entities/partnersystems?sort=name.asc&mode=dataOnly&validDateTime=true',
    );
  };

  const { data: systemChecks } = useQuery({
    queryKey: 'systemChecks',
    queryFn: () => fetchSystemChecks(),
    initialData: null,
    cacheTime: 0,
    enabled: true,
  });

  const { data: docTypes } = useQuery({
    queryKey: 'docTypes',
    queryFn: () => fetchDocTypes(),
    initialData: null,
    cacheTime: 0,
    enabled: true,
  });

  const toTabTitle = () => {
    const placeholder = TAB_TITLES.TARGET_INFORMATION_SHEET;

    if ([VIEW.AIRPAX, VIEW.AIRPAX_V2].includes(view)) {
      return `${placeholder} (Airpax)`;
    }

    if (view === VIEW.RORO) {
      return `${placeholder} (RoRo)`;
    }

    return placeholder;
  };

  useSetTabTitle(toTabTitle());

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, [docTypes, systemChecks]);

  useEffect(() => {
    if (!isLoadingTargetInformationSheet) {
      storeInformationSheet(TargetInformationUtil.prefillPayload(informationSheet, view));
      setIsLoading(false);
    }
  }, [informationSheet]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  const systemChecksWithoutPNC = () => {
    return systemChecks?.filter((check) => check.name !== 'Police National Computer');
  };

  return (
    <div id="issue-target-form">
      <RenderForm
        viewOnly={false}
        cacheTisFormData
        form={getTisForm(view, taskData, systemChecksWithoutPNC())}
        preFillData={getInformationSheet()}
        onSubmit={
        async ({ data }) => {
          try {
            await AxiosRequests.createTarget(apiClient,
              TargetInformationUtil.submissionPayload(taskData, data, keycloak, docTypes));
            data?.meta?.documents.forEach((document) => delete document.file);
            storeInformationSheet({});
            setSubmitted();
            window.scrollTo(0, 0);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (e) {
            storeInformationSheet(TargetInformationUtil.convertToPrefill(data));
          }
        }
      }
        onCancel={onCancel}
      />
    </div>
  );
};

export default IssueTarget;
