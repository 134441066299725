// Config(s)
import { PNC_OUTCOMES } from '../TargetInformation/constants';

// Util(s)
import StringUtil from '../String/stringUtil';

const getSystems = (person) => {
  return person?.credibilityChecks?.credibilityChecks?.map((credibilityCheck) => credibilityCheck.system) || [];
};

const getNotRequiredReason = (credibilityChecks) => {
  if (!credibilityChecks?.notNecessaryReason) {
    return null;
  }

  return credibilityChecks.notNecessaryReason;
};

const isCheckNotRequired = (credibilityChecks) => {
  return !!credibilityChecks?.notNecessary;
};

const findCredibilityCheck = (collection, system) => {
  if (!collection || !collection.length) {
    return null;
  }
  return collection.find((credibilityCheck) => credibilityCheck.system === system);
};

const getPncCategories = (pncCheck) => {
  if (!pncCheck?.result?.categories) {
    return null;
  }
  return pncCheck.result.categories;
};

const toFormattedSafetyInformation = (safetyInformation) => {
  return StringUtil.boolToString(safetyInformation);
};

const getPersonOfInterestConcerns = (pncCheck) => {
  if (!pncCheck?.result?.details?.poiConcerns) {
    return null;
  }
  return pncCheck.result.details.poiConcerns;
};

const getStaffConcerns = (pncCheck) => {
  if (!pncCheck?.result?.details?.staffConcerns) {
    return null;
  }
  return pncCheck.result.details.staffConcerns;
};

const getPncWarningTypes = (pncCheck) => {
  const EXCLUDE = ['STAFF_CONCERN', 'POI_CONCERN'];
  const details = pncCheck?.result ? pncCheck?.result?.details : pncCheck?.details;
  return details?.types?.filter((item) => !EXCLUDE.includes(item) && !!item) ?? [];
};

const getPncTypes = (pncCheck) => {
  if (pncCheck?.details?.types) {
    return pncCheck?.details?.types;
  }

  return pncCheck?.result?.details?.types ?? [];
};

const getPncSafety = (pncCheck) => {
  // This is a boolean value so a null check will suffice.
  if ([undefined, null].includes(pncCheck?.result?.found?.safetyInformation)) {
    return null;
  }
  return pncCheck.result.found.safetyInformation;
};

const getPncOutcome = (pncCheck) => {
  if (!pncCheck?.result?.outcome && !pncCheck?.outcome) {
    return null;
  }
  const outcome = pncCheck?.result?.outcome || pncCheck?.outcome;
  return outcome;
};

const getPncRoRoOutcome = (person) => {
  if (!person?.pncResult?.outcome) {
    return null;
  }
  return person?.pncResult?.outcome;
};

const getPncId = (pncCheck) => {
  if (!pncCheck?.result?.found?.pncId) {
    return null;
  }
  return pncCheck.result.found.pncId;
};

const getAllNonPncCredibilityChecks = (person) => {
  return person?.credibilityChecks?.credibilityChecks?.filter((credibilityCheck) => credibilityCheck.system !== 'PNC');
};

const getPnc = (person) => {
  if (person?.pncResult) {
    return person?.pncResult;
  }
  return person?.credibilityChecks?.credibilityChecks?.find((credibilityCheck) => credibilityCheck.system === 'PNC');
};

const hasPncCheck = (person) => {
  if (!person) {
    return false;
  }

  const pncCheck = getPnc(person);
  return !!pncCheck?.result;
};

const isPotentialPncCheck = (pncCheck, person = undefined) => {
  if (person !== undefined) {
    return getPncRoRoOutcome(person) === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND;
  }
  return getPncOutcome(pncCheck) === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND;
};

const getPncCheck = (person) => {
  if (!person) {
    return null;
  }

  const pncCheck = getPnc(person);
  if (!pncCheck) {
    return null;
  }

  return pncCheck;
};

const getPncResult = (person) => {
  return person?.pncResult;
};

const getCredibilityChecksWithoutPnc = (person) => {
  if (!person) {
    return [];
  }

  const credibilityChecks = getAllNonPncCredibilityChecks(person);
  if (!credibilityChecks || !credibilityChecks?.length) {
    return [];
  }

  return credibilityChecks;
};

const CredibilityChecksUtil = {
  categories: getPncCategories,
  findCheck: findCredibilityCheck,
  hasPnc: hasPncCheck,
  isPotentialPnc: isPotentialPncCheck,
  getPnc: getPncCheck,
  getPncResult,
  getChecksWithoutPnc: getCredibilityChecksWithoutPnc,
  outcome: getPncOutcome,
  notRequired: isCheckNotRequired,
  notRequiredReason: getNotRequiredReason,
  pncId: getPncId,
  poiConcerns: getPersonOfInterestConcerns,
  safety: getPncSafety,
  staffConcerns: getStaffConcerns,
  systems: getSystems,
  types: getPncTypes,
  warningTypes: getPncWarningTypes,
  format: {
    safetyInformation: toFormattedSafetyInformation,
  },
};

export default CredibilityChecksUtil;

export {
  getPncCategories,
  findCredibilityCheck,
  hasPncCheck,
  isPotentialPncCheck,
  getPncCheck,
  getPncResult,
  getCredibilityChecksWithoutPnc,
  getPncOutcome,
  isCheckNotRequired,
  getNotRequiredReason,
  getPncId,
  getPersonOfInterestConcerns,
  getPncSafety,
  getStaffConcerns,
  getSystems,
  getPncWarningTypes,
  getPncTypes,
  toFormattedSafetyInformation,
};
