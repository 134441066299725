import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Utils
import PersonUtil from '../../../../../../../../utils/Person/personUtil';
// Styles
import './ThreatTypeSummary.scss';
const ThreatTypeSummary = ({ rule, personsMatchingRule }) => {
    const ruleName = rule.name;
    const personNamesMatchingRule = personsMatchingRule === null || personsMatchingRule === void 0 ? void 0 : personsMatchingRule.map((person) => PersonUtil.formattedNameFromPerson(person, true));
    return (_jsxs("div", { className: "threat-type-summary-container", children: [_jsx("div", { className: "threat-type-summary-rule-name", children: ruleName }), _jsx("div", { className: "threat-type-summary-people-container", children: personNamesMatchingRule === null || personNamesMatchingRule === void 0 ? void 0 : personNamesMatchingRule.map((name) => (_jsx("span", { className: "threat-type-summary-person-name", children: name }, name))) })] }));
};
export default ThreatTypeSummary;
