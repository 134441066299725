import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SpinnerItem from './SpinnerItem';
import './LoadingSpinner.scss';
const LoadingSpinner = ({ loading = true, children }) => {
    if (!loading) {
        return children;
    }
    return (_jsxs("div", { className: "loading-spinner", children: [_jsxs("div", { className: "loading-spinner--inner", children: [_jsxs("svg", { version: "1.1", fill: "#0b0c0c", width: "50px", height: "50px", className: "icon-loading", viewBox: "-25 -25 50 50", preserveAspectRatio: "xMidYMid meet", children: [_jsx("title", { children: "Loading" }), _jsx(SpinnerItem, { transform: "rotate(0, 0, 2) translate(10 0)", style: { animationDelay: '0ms' } }), _jsx(SpinnerItem, { transform: "rotate(30, 0, 2) translate(10 0)", style: { animationDelay: '83ms' } }), _jsx(SpinnerItem, { transform: "rotate(60, 0, 2) translate(10 0)", style: { animationDelay: '166ms' } }), _jsx(SpinnerItem, { transform: "rotate(90, 0, 2) translate(10 0)", style: { animationDelay: '249ms' } }), _jsx(SpinnerItem, { transform: "rotate(120, 0, 2) translate(10 0)", style: { animationDelay: '332ms' } }), _jsx(SpinnerItem, { transform: "rotate(150, 0, 2) translate(10 0)", style: { animationDelay: '415ms' } }), _jsx(SpinnerItem, { transform: "rotate(180, 0, 2) translate(10 0)", style: { animationDelay: '498ms' } }), _jsx(SpinnerItem, { transform: "rotate(210, 0, 2) translate(10 0)", style: { animationDelay: '581ms' } }), _jsx(SpinnerItem, { transform: "rotate(240, 0, 2) translate(10 0)", style: { animationDelay: '664ms' } }), _jsx(SpinnerItem, { transform: "rotate(270, 0, 2) translate(10 0)", style: { animationDelay: '747ms' } }), _jsx(SpinnerItem, { transform: "rotate(300, 0, 2) translate(10 0)", style: { animationDelay: '830ms' } }), _jsx(SpinnerItem, { transform: "rotate(330, 0, 2) translate(10 0)", style: { animationDelay: '913ms' } })] }), _jsx("div", { className: "loading-spinner--overlay" })] }), children] }));
};
export default LoadingSpinner;
