// Global import(s)
import { Label, Tag } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { FRONTLINE_OUTCOME_NAME, FRONTLINE_OUTCOME, TARGET_STATUS, VIEW, UPLIFTED_FRONTLINE_OUTCOME_NAME } from '../../../../../utils/constants';

// Context(s)
import { useView } from '../../../../../context/ViewContext';

// Util(s)
import { TargetInformationUtil } from '../../../../../utils';

// Styling
import './TargetOutcome.scss';

const RoRoTargetOutcome = ({ outcome, isTargetList }) => {
  let classname = 'generic-outcome';
  if (outcome === FRONTLINE_OUTCOME.POSITIVE) {
    classname = 'positive-outcome';
  }

  if (isTargetList) {
    return (
      <Tag id="target-outcome-tag" className={`tag tag--${classname} centered`} text={FRONTLINE_OUTCOME_NAME[outcome]} />
    );
  }

  return (
    <div id="target-status-container">
      <Label id="target-outcome-label" required className="govuk-!-font-weight-bold">
        Outcome:&nbsp;<Tag id="target-outcome-tag" className={`tag tag--${classname}`} text={FRONTLINE_OUTCOME_NAME[outcome]} />
      </Label>
    </div>
  );
};

const AirPaxTargetOutcome = ({ outcome, isTargetList, caseUrl }) => {
  let className;
  switch (outcome) {
    case FRONTLINE_OUTCOME.POSITIVE:
      className = 'positive';
      break;
    case FRONTLINE_OUTCOME.NEGATIVE:
      className = 'negative';
      break;
    case FRONTLINE_OUTCOME.NO_SHOW:
      className = 'no-show';
      break;
    case FRONTLINE_OUTCOME.MISSED:
      className = 'missed';
      break;
    case FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES:
      className = 'insufficient-resouces';
      break;
    default:
      className = null;
  }

  if (isTargetList) {
    const tag = (
      <Tag
        id="target-outcome-tag"
        className={classNames('outcome', 'font-bold', 'capitalize', 'ho-body-xs', className)}
        text={UPLIFTED_FRONTLINE_OUTCOME_NAME[outcome]}
      />
    );
    return caseUrl
      ? (
        <a
          href={caseUrl}
          aria-label={UPLIFTED_FRONTLINE_OUTCOME_NAME[outcome]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tag}
        </a>
      )
      : tag;
  }

  // TODO: We may need to change when we get the target details page spec
  return <RoRoTargetOutcome outcome={outcome} isTargetList={isTargetList} />;
};

const TargetOutcome = ({ targetSheet, isTargetList }) => {
  const { view } = useView();
  const status = TargetInformationUtil.status(targetSheet);
  const outcome = TargetInformationUtil.informationSheet.targetOutcome(targetSheet);
  const caseUrl = TargetInformationUtil.caseUrl(targetSheet);

  if (status !== TARGET_STATUS.COMPLETE || !outcome) {
    return null;
  }

  if (view === VIEW.RORO_IO) {
    return <RoRoTargetOutcome outcome={outcome} isTargetList={isTargetList} />;
  }

  return <AirPaxTargetOutcome outcome={outcome} isTargetList={isTargetList} caseUrl={caseUrl} />;
};

TargetOutcome.propTypes = {
  targetSheet: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  isTargetList: PropTypes.bool,
};

TargetOutcome.defaultProps = {
  isTargetList: false,
};

export default memo(TargetOutcome);
