var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// Global import(s)
import { Button, ButtonGroup, Heading, Label, MultiSelectAutocomplete, } from '@ukhomeoffice/cop-react-components';
import axios from 'axios';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useNavigate, useParams } from 'react-router-dom';
// Config(s)
import config from '../../../utils/config';
import { STATUS, TARGET_STATUS } from '../../../utils/constants';
// Context(s)
import { useKeycloak } from '../../../context/Keycloak';
import { useNotification } from '../../../context/NotificationContext';
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useUser } from '../../../context/UserContext';
import { useView } from '../../../context/ViewContext';
// Services
import AxiosRequests from '../../../api/axiosRequests';
// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import ReAssignOutcome from './ReAssignOutcome';
import Table from '../../../components/Table/Table';
// Hook(s)
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';
import useFetchTargetSheet from '../../../utils/Hooks/useFetchTargetSheet';
import useRefDataFetch from '../../../utils/Hooks/useRefDataFetch';
import useFetchFrontlineOfficers from '../../../utils/Hooks/useFetchFrontlineOfficers';
// Util(s)
import CommonUtil from '../../../utils/Common/commonUtil';
// Styling
import './ReAssignTarget.scss';
const ReAssignTarget = () => {
    const navigate = useNavigate();
    const keycloak = useKeycloak();
    const source = axios.CancelToken.source();
    const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
    const [status, setStatus] = useState(STATUS.PENDING);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [permission, setPermission] = useState({ canReAssign: false, reason: '' });
    const [currentAssignees, setCurrentAssignees] = useState([]);
    const [unAssignees, setUnAssignees] = useState([]);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { targetId } = useParams();
    const { setupTabNotificationIfRequired } = useNotification();
    const { DEFAULTS, selectedTab, setTabIndex, setSelectedTab } = useTabs();
    const { canUpdateTarget } = usePermission();
    const { isHigherOfficer, isRCCUOfficer } = useUser();
    const { isSettingView, view } = useView();
    // Hook(s)
    const { targetSheet, isLoading: isLoadingTargetSheet } = useFetchTargetSheet(targetId, !!targetId);
    const { data: refDataFrontlineTeams, isLoading: isLoadingFrontlineTeams } = useRefDataFetch('/v2/entities/team?mode=dataOnly&sort=name.asc&select=name,code&filter=departmentid=eq.1');
    const { frontlineOfficers, isLoading: isLoadingFrontlineOfficers } = useFetchFrontlineOfficers();
    const canBeReassignedByHigherOfficer = (targetStatus) => {
        return [TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED].includes(targetStatus) && isHigherOfficer;
    };
    const canBeReassignedByRCCUOfficer = (targetStatus) => {
        return [TARGET_STATUS.INCOMING, TARGET_STATUS.RECEIPTED, TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED]
            .includes(targetStatus) && isRCCUOfficer;
    };
    const setupRedirectConditions = () => __awaiter(void 0, void 0, void 0, function* () {
        setTabIndex(CommonUtil.findTabIndex(DEFAULTS[view].tabs, selectedTab));
        setSelectedTab(selectedTab);
    });
    const toCommonTeamsOptions = (_options) => {
        return ((_options === null || _options === void 0 ? void 0 : _options.map((option) => {
            return Object.assign(Object.assign({}, option), { label: option.name, value: option.name, isTeam: true });
        })) || []);
    };
    const toCommonOfficersOptions = (_options) => {
        return ((_options === null || _options === void 0 ? void 0 : _options.map((option) => {
            return Object.assign(Object.assign({}, option), { label: `${option.firstName} ${option.lastName} - ${option.email}`, value: `${option.firstName} ${option.lastName} - ${option.email}`, isTeam: false });
        })) || []);
    };
    const setupUnAssignees = () => {
        var _a, _b;
        const newAssignees = currentAssignees.map(({ label }) => label);
        setUnAssignees([
            ...toCommonOfficersOptions((_a = targetSheet === null || targetSheet === void 0 ? void 0 : targetSheet.assignees) === null || _a === void 0 ? void 0 : _a.users),
            ...toCommonTeamsOptions((_b = targetSheet === null || targetSheet === void 0 ? void 0 : targetSheet.assignees) === null || _b === void 0 ? void 0 : _b.teams),
        ].filter(({ label }) => !newAssignees.includes(label)));
    };
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setInProgress(true);
        const payload = {
            assignees: currentAssignees.filter((item) => !item.isTeam),
            teams: currentAssignees.filter((item) => item.isTeam).map(({ code }) => code),
        };
        yield AxiosRequests.reassignTarget(apiClient, targetId, payload)
            .then((data) => {
            setupTabNotificationIfRequired(DEFAULTS[view].tabs, selectedTab, data.status);
            setIsSubmitted(true);
            setInProgress(false);
        })
            .catch(() => setInProgress(false));
    });
    const isAlreadyAnAssignee = (selectedOption) => {
        return !!currentAssignees.find(({ label }) => label === selectedOption.label);
    };
    const onAutocompleteChange = ({ target }) => {
        const { value } = target;
        if (!value) {
            // Do nothing
            return;
        }
        if (isAlreadyAnAssignee(value)) {
            // Do not add entry if it already exists
            return;
        }
        setCurrentAssignees((prev) => {
            return [...prev, value];
        });
        setShowAutocomplete(false);
    };
    const onRemoveAssignee = ({ target }) => {
        setCurrentAssignees(currentAssignees.filter(({ label }) => label !== target.dataset.assignee));
    };
    const onAddAssignee = () => setShowAutocomplete(true);
    const checkAndSetupFormPermissions = () => {
        if (targetSheet) {
            const targetStatus = targetSheet === null || targetSheet === void 0 ? void 0 : targetSheet.status;
            if ((canBeReassignedByRCCUOfficer(targetStatus) || canBeReassignedByHigherOfficer(targetStatus)) && canUpdateTarget) {
                setPermission((prev) => (Object.assign(Object.assign({}, prev), { reason: '', canReAssign: true })));
            }
            else {
                setPermission({
                    canReAssign: false,
                    reason: `You are not authorised to reassign this target (${targetId}).`,
                });
            }
        }
        setStatus(STATUS.COMPLETE);
    };
    const setupRelevantOptions = () => {
        var _a, _b;
        const assignedUsers = toCommonOfficersOptions(((_a = targetSheet === null || targetSheet === void 0 ? void 0 : targetSheet.assignees) === null || _a === void 0 ? void 0 : _a.users) || []);
        const assignedTeams = toCommonTeamsOptions(((_b = targetSheet === null || targetSheet === void 0 ? void 0 : targetSheet.assignees) === null || _b === void 0 ? void 0 : _b.teams) || []);
        const frontlineUsers = toCommonOfficersOptions(frontlineOfficers);
        const frontlineTeams = toCommonTeamsOptions(refDataFrontlineTeams);
        // Set up the current assignees (teams and users)
        setCurrentAssignees(CommonUtil.orderCollection([...assignedUsers, ...assignedTeams], 'label'));
        // Set up the autocomplete options
        setAutocompleteOptions(CommonUtil.orderCollection([...frontlineUsers, ...frontlineTeams], 'label'));
    };
    const assignedOfficers = currentAssignees.filter((item) => !item.isTeam);
    const assignedTeams = currentAssignees.filter((item) => item.isTeam);
    useDeepCompareEffect(() => {
        checkAndSetupFormPermissions();
        setupRelevantOptions();
        return () => AxiosRequests.cancel(source);
    }, [targetSheet, refDataFrontlineTeams, frontlineOfficers]);
    const toAssignedOfficersRows = () => {
        return currentAssignees
            .filter((assignee) => !assignee.isTeam)
            .map((assignee) => {
            return (_jsxs("tr", { children: [_jsxs("td", { id: `assignee-name-${assignee.firstName}-${assignee.lastName}`, children: [assignee.firstName, " ", assignee.lastName] }), _jsx("td", { id: `assignee-email-${assignee.email}`, children: assignee.email }), _jsx("td", { children: _jsx(Button, { id: `remove-assignee-${assignee.email}`, "aria-label": `remove assignee ${assignee.email}`, classModifiers: "secondary", "data-assignee": assignee.label, onClick: onRemoveAssignee, children: "Remove assignee" }) })] }, assignee.email));
        });
    };
    const toAssignedTeamsRows = () => {
        return currentAssignees
            .filter((assignee) => assignee.isTeam)
            .map((team) => {
            return (_jsxs("tr", { children: [_jsx("td", { id: `assignee-team-${team.code}`, children: team.name }), _jsx("td", { children: _jsx(Button, { id: `remove-assignee-${team.code}`, "aria-label": `remove assignee ${team.name}`, classModifiers: "secondary", "data-assignee": team.label, onClick: onRemoveAssignee, children: "Remove assignee" }) })] }, team.code));
        });
    };
    if (isLoadingTargetSheet || isLoadingFrontlineOfficers || isLoadingFrontlineTeams || inProgress || status === STATUS.PENDING || isSettingView) {
        return _jsx(ApplicationSpinner, {});
    }
    if (!targetSheet) {
        return null;
    }
    if (!permission.canReAssign) {
        return _jsx("p", { className: "govuk-body-l", children: permission.reason });
    }
    if (isSubmitted) {
        return (_jsx(ReAssignOutcome, { assignees: CommonUtil.orderCollection(currentAssignees, ['label']), unAssignees: CommonUtil.orderCollection(unAssignees, ['label']), onFinish: () => __awaiter(void 0, void 0, void 0, function* () {
                yield setupRedirectConditions().then(() => {
                    navigate(DEFAULTS[view].redirectPath);
                });
            }) }));
    }
    return (_jsx("div", { className: "govuk-grid-row", children: _jsxs("div", { className: "govuk-grid-column-full", children: [_jsx(Heading, { size: "l", useHodsTag: true, children: "Assign target" }), _jsx(ComponentWrapper, { show: assignedOfficers.length || assignedTeams.length, children: _jsx(Heading, { size: "m", useHodsTag: true, children: "Current assignees" }) }), _jsx(ComponentWrapper, { show: assignedOfficers.length, children: _jsx(Table, { id: "current-assignees-officers", className: "current-assignees-officers", headings: [
                            'Name',
                            'Email',
                            '',
                        ], rows: toAssignedOfficersRows() }) }), _jsx(ComponentWrapper, { show: assignedTeams.length, children: _jsx(Table, { id: "current-assignees-teams", className: "current-assignees-teams", headings: [
                            'Frontline Team',
                            '',
                        ], rows: toAssignedTeamsRows() }) }), _jsx("div", { className: "govuk-grid-row", children: _jsxs("div", { className: "govuk-grid-column-two-thirds", children: [_jsx(ComponentWrapper, { show: showAutocomplete, children: _jsxs(_Fragment, { children: [_jsx(Label, { id: "name-email-officer", hideOptionalSuffix: true, children: "Enter name or email address of officer, or name of Frontline team" }), _jsx(MultiSelectAutocomplete, { autoFocus: true, id: "officer-teams-details-autocomplete", fieldId: "officer-teams-details-autocomplete", clearable: true, options: autocompleteOptions, item: { value: 'value', label: 'label' }, onChange: onAutocompleteChange, value: null })] }) }), _jsx(Button, { id: "add-assignee", "aria-label": "add assignee", classModifiers: "secondary", onClick: onAddAssignee, style: { bottom: '10px' }, children: "Add assignee" })] }) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { id: "cancel", "aria-label": "submit", classModifiers: "secondary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield setupRedirectConditions().then(() => {
                                    navigate(DEFAULTS[view].redirectPath);
                                });
                            }), children: "Cancel" }), _jsx(Button, { id: "submit", "aria-label": "submit", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setupUnAssignees();
                                yield onSubmit();
                            }), children: "Submit" })] })] }) }));
};
export default ReAssignTarget;
