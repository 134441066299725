/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, useEffect } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { initAll } from 'govuk-frontend';
import { useKeycloak } from '../context/Keycloak';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS, RBAC } from '../utils/constants';
import config from '../utils/config';

// Context(s)
import { usePermission } from '../context/PermissionContext';
import { useUser } from '../context/UserContext';

// Components/ Pages
import AccessibilityStatement from './Accessibility/AccessibilityStatement';
import AdminPanel from './Admin/AdminPanel';
import EditDomains from './Approved Domains/EditDomains';
import Layout from '../components/Layout/Layout';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import Redirect from '../components/Navigate/Redirect';
import SignOut from '../components/Navigate/Signout';
import TaskRouter from './Task/TaskRouter';
import TargetRouter from './Target/TargetRouter';
import ViewApprovedDomains from './Approved Domains/ViewApprovedDomains';

// HOC(s)
import withWebSocketSession from '../components/HOC/withWebSocketSession';

// Util
import { CommonUtil } from '../utils';

const AppRouter = () => {
  const keycloak = useKeycloak();
  const { isLoading: isUserInfoLoading } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    hasRole,
    canReadTarget,
    hasRoRoFrontLineOfficerGroup,
    hasAirFrontLineOfficerGroup,
    isLiveServicesUser,
  } = usePermission();

  initAll();

  if (!keycloak || isUserInfoLoading) {
    return null;
  }

  // Defaults to a path based on the users' rbac role.
  const toDefaultPathByRole = () => {
    if (hasRole(RBAC.TASK_RORO_READ)) {
      return PATHS.RORO;
    }

    if (hasRole(RBAC.TASK_AIRPAX_READ)) {
      return PATHS.UPLIFT.AIRPAX;
    }

    if (hasRole(RBAC.TASK_GA_READ)) {
      return PATHS.GENERAL_AVIATION;
    }

    if (canReadTarget && hasRoRoFrontLineOfficerGroup()) {
      return PATHS.TARGETS.RORO;
    }

    if (canReadTarget && hasAirFrontLineOfficerGroup()) {
      return PATHS.TARGETS.AIRPAX;
    }

    if (isLiveServicesUser) {
      return PATHS.ADMIN;
    }

    return PATHS.UPLIFT.AIRPAX;
  };

  const goBack = () => {
    const previousLocation = localStorage.getItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION);

    const isTargetsPage = location?.pathname.startsWith('/targets');
    const shouldNavigateToCopUi = isTargetsPage && !previousLocation;

    if (shouldNavigateToCopUi) {
      try {
        navigate(config.copUiUrl);
      } catch (error) {
        console.error('A navigation error occurred', error);
      }
    }
    navigate(-1);
  };

  document.body.className = document.body.className ? `${document.body.className} js-enabled` : 'js-enabled';

  const navigateToLastKnownLocation = async (lastKnownLocation) => {
    if (lastKnownLocation?.length > 0) {
      const navigateTo = CommonUtil.strip(lastKnownLocation);
      if (navigateTo !== location?.pathname) {
        try {
          navigate(navigateTo);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('A navigation error occurred');
        }
      }
      localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_KNOWN_LOCATION);
    }
  };

  useEffect(() => {
    if (location?.pathname !== PATHS.SIGN_OUT && location?.pathname !== '/') {
      const lastKnownLocation = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_KNOWN_LOCATION);
      navigateToLastKnownLocation(lastKnownLocation);
    }
    return () => {};
  }, [location]);

  if (PATHS.TASK_PATHS().some((path) => location?.pathname?.startsWith(path) || false)) {
    return <TaskRouter />;
  }

  if (PATHS.TARGET_PATHS().some((path) => location?.pathname?.startsWith(path) || false)) {
    return <TargetRouter />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {/* Redirects */}
        <Route
          path={PATHS.APPROVED_DOMAINS}
          element={<Redirect to={PATHS.APPROVED_DOMAINS + PATHS.VIEW} />}
        />
        {[
          {
            path: '/',
            redirect: toDefaultPathByRole(),
            enabled: toDefaultPathByRole() !== null,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={<Redirect to={item.redirect} />}
              replace
            />
          ))}
        <Route
          key={PATHS.ADMIN}
          path={PATHS.ADMIN}
          element={(
            <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
              <AdminPanel />
            </Layout>
          )}
        />
        {[
          PATHS.ACCESSIBILITY_STATEMENT,
          `/v2${PATHS.ACCESSIBILITY_STATEMENT}`,
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={(
              <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                <AccessibilityStatement />
              </Layout>
            )}
          />
        ))}
        {/* Domains */}
        <Route
          path={PATHS.APPROVED_DOMAINS + PATHS.VIEW}
          element={(
            <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button">Home</Link>}>
              <ViewApprovedDomains />
            </Layout>
          )}
        />
        <Route
          path={PATHS.APPROVED_DOMAINS + PATHS.EDIT}
          element={(
            <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
              <EditDomains />
            </Layout>
          )}
        />
        <Route
          key={PATHS.SIGN_OUT}
          path={PATHS.SIGN_OUT}
          element={(
            <Layout>
              <SignOut />
            </Layout>
          )}
        />
        <Route
          key="/"
          path="/"
          element={(
            <Layout>
              You are not authorised to view this site
            </Layout>
          )}
        />
      </Routes>
    </Suspense>
  );
};

export default withWebSocketSession(AppRouter);
