// Global import(s)
import { NotificationBanner } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config
import { TEXT_DEFAULTS, VIEW } from '../../../../../utils/constants';

// Context
import { useView } from '../../../../../context/ViewContext';

// Util(s)
import { CommonUtil, TargetPersonUtil, TargetRisksUtil, CredibilityChecksUtil } from '../../../../../utils';

// Styling
import './Warnings.scss';

const Warnings = ({ informationSheet }) => {
  const { view } = useView();
  const persons = TargetPersonUtil.getAll(informationSheet);
  const others = TargetPersonUtil.getRoRoOthers(informationSheet);
  const people = [...persons, ...others];

  // Only want to display warning banner if persons have pncs with warning types
  const pncsWithWarnings = CommonUtil.uniqueId(people)
    .filter((p) => {
      const pncCheck = CredibilityChecksUtil.getPnc(p);
      return CredibilityChecksUtil.isPotentialPnc(pncCheck, pncCheck === true ? p : undefined);
    })
    .map((person) => CredibilityChecksUtil.getPnc(person))
    .filter((pnc) => CredibilityChecksUtil.types(pnc).length > 0);

  if (!pncsWithWarnings?.length) {
    return null;
  }

  const toContent = (value, classnames = null, id = null) => {
    if (!value) {
      return null;
    }

    return (
      <>
        <br />
        <span id={id} className={classNames('govuk-body', classnames)}>{value}</span>
        <br />
      </>
    );
  };

  const toTotalWarnings = () => {
    const warnings = people?.reduce((prev, person) => {
      const pnc = CredibilityChecksUtil.getPnc(person);
      const _warnings = CredibilityChecksUtil.warningTypes(pnc);
      const formattedWarnings = _warnings.map((item) => TargetRisksUtil.warning.format(item));
      return [...prev, ...formattedWarnings];
    }, []);
    return [...new Set(warnings)].join(', ');
  };

  const toFrontlineSafetyContent = () => {
    return persons
      .map((person) => {
        const pnc = CredibilityChecksUtil.getPnc(person);
        if (!CredibilityChecksUtil.staffConcerns(pnc)) {
          return null;
        }

        return (
          <span key={person.id}>
            {toContent(
              `Other concerns for the safety of front line staff relating to ${TargetPersonUtil.firstName(
                person,
              )} ${TargetPersonUtil.lastName(person)}`,
              TEXT_DEFAULTS.CSS.GOV_BOLD,
              'frontline-safety-label',
            )}
            {toContent(CredibilityChecksUtil.staffConcerns(pnc), null, 'frontline-safety-value')}
          </span>
        );
      })
      .filter((item) => !!item);
  };

  const toPoiConcernsContent = () => {
    return persons
      .map((person) => {
        const pnc = CredibilityChecksUtil.getPnc(person);
        if (!CredibilityChecksUtil.poiConcerns(pnc)) {
          return null;
        }

        return (
          <span key={person.id}>
            {toContent(
              `Other concerns for passenger safety related to ${TargetPersonUtil.firstName(person)} ${TargetPersonUtil.lastName(
                person,
              )}`,
              TEXT_DEFAULTS.CSS.GOV_BOLD,
              'poi-safety-label',
            )}
            {toContent(CredibilityChecksUtil.poiConcerns(pnc), null, 'poi-safety-value')}
          </span>
        );
      })
      .filter((item) => !!item);
  };

  const isAirPaxView = () => {
    return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU, VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.OSDT, VIEW.CTBP, VIEW.IDP].includes(view);
  };

  const getBannerTitle = () => {
    if (isAirPaxView()) {
      return 'Important';
    }
    return 'Important Information';
  };

  return (
    <NotificationBanner className="target-warnings-notification-banner" title={getBannerTitle()}>
      {toContent('Summary of all warnings for all travellers', TEXT_DEFAULTS.CSS.GOV_BOLD, 'warnings-summary-label')}
      {toContent(toTotalWarnings(), null, 'warnings-summary-value')}
      {toFrontlineSafetyContent()}
      {toPoiConcernsContent()}
    </NotificationBanner>
  );
};

Warnings.propTypes = {
  informationSheet: PropTypes.shape(PropTypes.shape({})),
};

Warnings.defaultProps = {
  informationSheet: undefined,
};

export default memo(Warnings);
