// Global import(s)
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS, VIEW, RBAC, STRINGS } from '../utils/constants';

// Context(s)
import { useUser } from './UserContext';
import { usePermission } from './PermissionContext';

// Util(s)
import { CommonUtil, StorageUtil } from '../utils';

const ViewContext = createContext({});

const getMainAirpaxView = (hasRole) => {
  const localStoredTaskListView = localStorage.getItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE);
  if (localStoredTaskListView && localStoredTaskListView !== STRINGS.AIRPAX) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE);
    return localStoredTaskListView;
  }

  if (hasRole(RBAC.TARGETER_AIRPAX_CTBP)) {
    return VIEW.CTBP;
  }

  if (hasRole(RBAC.TARGETER_AIRPAX_OSDT)) {
    return VIEW.OSDT;
  }

  if (hasRole(RBAC.TARGETER_AIRPAX_COMMODITIES)) {
    return VIEW.COMMODITIES;
  }

  if (hasRole(RBAC.TARGETER_AIRPAX_IDP)) {
    return VIEW.IDP;
  }

  // Could not find relevant view, return default view
  return VIEW.COMMODITIES;
};

const ViewProvider = ({ children }) => {
  // Custom Hooks
  const { userRole } = useUser();
  const { hasRole } = usePermission();
  const location = useLocation();
  // State
  const [showHandlingInstructions, setShowHandlingInstructions] = useState(false);
  const [isVisibleTargetActionsToolbar, setIsVisibleTargetActionsToolbar] = useState(false);
  const [disableStickyContainer, setDisableStickyContainer] = useState(false);
  const [view, setView] = useState(() => CommonUtil.viewByPathAndRole(location.pathname, userRole, getMainAirpaxView(hasRole)));
  const [isUseFullScreen, setIsUseFullScreen] = useState(false);
  // Derived state / Memos
  const isTaskListPage = useMemo(() => PATHS.TASK_LISTS().includes(location.pathname), [location.pathname]);
  const isTargetsListPage = useMemo(() => PATHS.TARGET_LISTS().includes(location.pathname), [location.pathname]);
  const useUpliftLayout = useMemo(() => [
    PATHS.UPLIFT.PREFIX,
    PATHS.TARGETS.AIRPAX,
    PATHS.TARGETS.RORO,
    PATHS.GENERAL_AVIATION,
    PATHS.PREVIOUS_TASKS.TASKS_LIST,
    PATHS.APPROVED_DOMAINS,
  ].some((path) => location?.pathname?.startsWith(path)), [location?.pathname]);
  const isTargetsPath = [PATHS.TARGETS.RORO, PATHS.TARGETS.AIRPAX]
    .some((path) => location.pathname.startsWith(path));
  const isTasksPath = [PATHS.RORO, PATHS.AIRPAX, PATHS.UPLIFT.AIRPAX, PATHS.GENERAL_AVIATION].some((path) => location.pathname.startsWith(path));

  useEffect(() => {
    setView(CommonUtil.viewByPathAndRole(location.pathname, userRole, getMainAirpaxView(hasRole)));

    setTimeout(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.VIEW_SWAP);
    }, 500);
  }, [userRole, location.pathname, hasRole]);

  useEffect(() => {
    StorageUtil.remove(LOCAL_STORAGE_KEYS.CACHED_TAB);
  }, [view]);

  const value = useMemo(() => ({
    view,
    setView,
    useUpliftLayout,
    isTargetsPath,
    isTasksPath,
    isTaskListPage,
    isTargetsListPage,
    disableStickyContainer,
    setDisableStickyContainer,
    showHandlingInstructions,
    setShowHandlingInstructions,
    isUseFullScreen,
    setIsUseFullScreen,
    isVisibleTargetActionsToolbar,
    setIsVisibleTargetActionsToolbar,
  }), [
    view,
    useUpliftLayout,
    isTargetsPath,
    isTaskListPage,
    isTargetsListPage,
    isTasksPath,
    disableStickyContainer,
    showHandlingInstructions,
    setShowHandlingInstructions,
    isUseFullScreen,
    setIsUseFullScreen,
    isVisibleTargetActionsToolbar,
    setIsVisibleTargetActionsToolbar,
  ]);

  return (
    <ViewContext.Provider value={value}>
      {children}
    </ViewContext.Provider>
  );
};

/**
 * @returns {{
 *     view,
 *     isSettingView,
 *     useUpliftLayout,
 *     isTargetsPath,
 *     isTaskListPage,
 *     isTargetsListPage,
 *     isTasksPath,
 *     disableStickyContainer,
 *     showHandlingInstructions,
 *     setShowHandlingInstructions,
 *     isUseFullScreen,
 *     setIsUseFullScreen,
 *     isVisibleTargetActionsToolbar,
 *     setIsVisibleTargetActionsToolbar,
 *     setView,
 *   }}
 */
const useView = () => useContext(ViewContext);

ViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ViewContext, ViewProvider, useView };
