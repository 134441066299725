// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Config(s)
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchFrontlineOfficers = () => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  const fetchOfficers = async () => {
    return AxiosRequests.frontlineOfficers(apiClient);
  };

  const { data: frontlineOfficers, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.FRONTLINE_OFFICERS],
    queryFn: () => fetchOfficers(),
    initialData: [],
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { frontlineOfficers, isLoading };
};

export default useFetchFrontlineOfficers;
