import config from '../config';

import { STRINGS } from '../constants';

const getEndDate = (justification) => {
  return justification?.endDate || STRINGS.UNKNOWN;
};

const getStartDate = (justification) => {
  return justification?.startDate || STRINGS.UNKNOWN;
};

const getJustificationReference = (justification) => {
  return justification?.reference || STRINGS.UNKNOWN;
};

const getProportionalityAnswer = (justification) => {
  return justification?.proportionalityAnswer || STRINGS.UNKNOWN;
};

const getJustificationReason = (justification) => {
  return justification?.requestReasonAnswer || STRINGS.UNKNOWN;
};

const getId = (justification) => {
  return justification?.id || STRINGS.UNKNOWN;
};

const hasJustifications = (justifications) => {
  return !!justifications?.length;
};

const toCreateJustificationURL = (redirectUrl) => {
  if (!redirectUrl) {
    return null;
  }
  return `${config.justificationUIUrl}new?redirect=*cop-url*&step=1`
    .replace('*cop-url*', encodeURIComponent(redirectUrl));
};

const navigateToJustificationUI = () => {
  window.location.replace(toCreateJustificationURL(window.location.href));
};

const JustificationUtil = {
  endDate: getEndDate,
  has: hasJustifications,
  id: getId,
  proportionality: getProportionalityAnswer,
  reason: getJustificationReason,
  reference: getJustificationReference,
  startDate: getStartDate,
  createJustificationURL: toCreateJustificationURL,
  toJustificationUI: navigateToJustificationUI,
};

export default JustificationUtil;

export {
  getEndDate,
  getId,
  getJustificationReason,
  getJustificationReference,
  getProportionalityAnswer,
  getStartDate,
  hasJustifications,
  toCreateJustificationURL,
  navigateToJustificationUI,
};
