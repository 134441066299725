import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading } from '@ukhomeoffice/cop-react-components';
// Components(s)
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Contents from '../../content/Contents';
import HeaderModule from '../../generics/HeaderModule';
import SubHeaderModule from '../../generics/SubHeaderModule';
import ValueField from '../../generics/ValueField';
// Util(s)
import BaggageUtil from '../../../../../../../../../utils/Baggage/baggageUtil';
const Baggage = (props) => {
    const { baggage, isBaggageTotalModule } = props;
    if (!baggage && !isBaggageTotalModule) {
        return null;
    }
    let idPrefix = '';
    if (!isBaggageTotalModule) {
        const { positionedAt, type } = props;
        idPrefix = `${type.toLowerCase()}-${positionedAt}-`;
    }
    const weight = BaggageUtil.weight(baggage);
    const bagCount = BaggageUtil.bagCount(baggage);
    const tags = BaggageUtil.tags(baggage);
    const additionalContent = BaggageUtil.additionalContent(baggage);
    const ModuleComponent = isBaggageTotalModule ? HeaderModule : SubHeaderModule;
    const headingSize = isBaggageTotalModule ? 'm' : 's';
    const headingTitle = `Baggage${isBaggageTotalModule ? ' total' : ''}`;
    return (_jsxs("div", { id: `${idPrefix}baggage-module`, className: isBaggageTotalModule ? 'govuk-grid-column-full' : '', children: [_jsx(ModuleComponent, { id: `${idPrefix}baggage-details`, items: [
                    {
                        content: (_jsx("div", { className: "grid-item", children: _jsx(Heading, { id: `${idPrefix}baggage-header`, size: headingSize, children: headingTitle }) }, "1")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: bagCount, children: _jsx("div", { className: "grid-item", children: _jsx(ValueField, { id: `${idPrefix}baggage-total`, value: BaggageUtil.format.bagCount(bagCount) }) }) }, "2")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: weight, children: _jsx("div", { className: "grid-item", children: _jsx(ValueField, { id: `${idPrefix}baggage-weight`, value: BaggageUtil.format.weight(weight) }) }) }, "3")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: tags === null || tags === void 0 ? void 0 : tags.length, children: _jsx("div", { className: "grid-item grid-item-span-2", children: _jsx(ValueField, { id: `${idPrefix}baggage-tags`, value: BaggageUtil.format.tags(tags) }) }) }, "4")),
                    },
                ] }), _jsx(Contents, { id: `${idPrefix}baggage-contents`, contents: additionalContent })] }));
};
export default Baggage;
