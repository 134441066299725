export const TEXT_DEFAULTS = {
    CHECKS: {
        ADTL_CCHECK: 'Additional credibility check/s',
    },
    CSS: {
        GOV_BOLD: 'govuk-!-font-weight-bold',
        GOV_MARGIN_B0: 'govuk-!-margin-bottom-0',
    },
    DATE: {
        DMY1: 'DD MMMM YYYY',
        DMY2: 'DD-MM-YYYY',
        DMY3: 'DD MMM YYYY',
    },
    TEXT: {
        TARGETS_RECEIVED_FROM_NBTC: 'Targets received from NBTC, awaiting confirmation of receipt',
        INCOMING_TARGETS: 'Incoming targets',
        TARGETS_WITHDRAWN: 'Targets withdrawn from you',
    },
};
export const API_VERSIONS = {
    V2: 'v2',
    V3: 'v3',
    V4: 'v4',
};
// This constant will eventually be removed. replaced by TIME_FORMATS.
export const DATE_FORMATS = {
    LONG: 'D MMM YYYY [at] HH:mm',
    LONG_DAY_DATE: 'ddd D MMM YYYY [at] HH:mm',
    LONG_DAY_DATE_FORMAT: 'D MMMM YYYY [at] HH:mm',
    SHORT: 'DD/MM/YYYY',
    SHORT_ALT: TEXT_DEFAULTS.DATE.DMY3,
    SHORT_JUSTIFICATION: 'D MMMM YYYY',
    UTC: 'YYYY-MM-DDTHH:mm:ss[Z]',
    CUSTOM_HOUR_MINUTE: 'HH:mm',
    CUSTOM_CARD_EXPIRY: 'MM/YY',
    CUSTOM_LONG: 'DD/MM/YYYY [at] HH:mm:ss',
    CUSTOM_LONG_FULL_MONTH: 'DD MMMM YYYY  HH:mm',
    CUSTOM_LONG_FULL_MONTH_AT: 'DD MMM YYYY [at] HH:mm',
};
export const TIME_FORMATS = {
    CONTENT: 'HH:mm DD MMM YYYY',
    NOTES: 'HH:mm DD MMM YYYY',
    VOYAGE: {
        DEFAULT: 'ddd DD MMM YYYY HH:mm',
        DAY_SHORT: 'ddd DD MMM YYYY',
        TIME_SHORT: 'HH:mm',
    },
    SUMMARY: 'HH:mm DD MMM YYYY', // This is a duplicate of CONTENT however, still worth keeping the separation due to changing designs
    DETAILS: 'DD MMMM YYYY [at] HH:mm',
    BOOKING_CHECK_IN: 'DD MMM YYYY HH:mm',
    DATE: {
        DEFAULT: TEXT_DEFAULTS.DATE.DMY3,
        TIME: 'HH:mm',
        YEAR_MONTH_DAY: 'YYYY-MM-DD',
    },
    DOB: TEXT_DEFAULTS.DATE.DMY3,
    UTC: 'YYYY-MM-DDTHH:mm:ss[Z]',
    DOCUMENT: TEXT_DEFAULTS.DATE.DMY1,
    LONG_DATE: 'YYYY-MM-DD HH:mm:ss',
    LONG_DAY_DATE: 'D MMMM YYYY [at] HH:mm',
    SHORT: 'DD/MM/YYYY',
};
export const SCRIM_TYPE = {
    FULL: 'FULL',
    PARTIAL: 'PARTIAL',
};
export const EUROPE_LONDON = 'Europe/London';
export const UNITS = {
    BOOKING_DATETIME: {
        name: 'BOOKING_DATETIME',
    },
    CHANGED: {
        name: 'CHANGED',
    },
    DATETIME: {
        name: 'DATETIME',
    },
    DISTANCE: {
        name: 'DISTANCE',
        unit: 'm',
    },
    WEIGHT: {
        name: 'WEIGHT',
        unit: 'kg',
    },
    CURRENCY: {
        name: 'CURRENCY',
        unit: '£',
    },
    SHORT_DATE: {
        name: 'SHORT_DATE',
        unit: 'days',
    },
    SHORT_DATE_ALT: {
        name: 'SHORT_DATE_ALT',
        unit: 'days',
    },
};
export const GENDERS = {
    MALE: {
        value: 'Male',
        unit: 'M',
    },
    FEMALE: {
        value: 'Female',
        unit: 'F',
    },
};
export const TAB_ID = {
    NEW: 'new',
    SELECTED_FOR_DISMISSAL: 'selectedForDismissal',
    IN_PROGRESS: 'inProgress',
    ISSUED: 'issued',
    RECEIVED: 'received',
    COMPLETE: 'complete',
};
export const FILTER_TAB_ID = {
    FILTERS: 'filters',
    TOOLS: 'tools',
};
export const FILTER_DISPLAY_CHOICE = 'hide-show-filters';
export const FILTER_DISPLAY_DEFAULT = 'hide';
export const FILTER_DISPLAY_SHOW = 'show';
export const FILTER_DISPLAY_HIDE = 'hide';
export const TASK_CARD_WIDTH = {
    MINIMUM: 750,
    STANDARD: 900,
    MAXIMUM: 1075,
};
export const TASK_STATUS = {
    NEW: 'NEW',
    SELECTED_FOR_DISMISSAL: 'SELECTED_FOR_DISMISSAL',
    IN_PROGRESS: 'IN_PROGRESS',
    ISSUED: 'ISSUED',
    RECEIVED: 'RECEIVED',
    COMPLETE: 'COMPLETE',
};
export const TASK_DETAIL_PAGE_UPDATING = 'task-detail-page-updating';
export const TAB_WORDING_MAP = {
    [TAB_ID.NEW]: 'new',
    [TAB_ID.SELECTED_FOR_DISMISSAL]: 'selected for dismissal',
    [TAB_ID.IN_PROGRESS]: 'in progress',
    [TAB_ID.ISSUED]: 'issued',
    [TAB_ID.RECEIVED]: 'received',
    [TAB_ID.COMPLETE]: 'complete',
};
export const TAGS = {
    RELISTED: 'RELISTED',
    UPDATED: 'UPDATED',
    WITHDRAWN: 'TARGET WITHDRAWN',
    PERSON_OF_INTEREST: 'PERSON OF INTEREST',
};
export const TARGETER_COMPLETION_REASON = {
    ARRIVED: 'ARRIVED',
    FALSE_SELECTOR_MATCH: 'FALSE_SELECTOR_MATCH',
    FALSE_MATCH: 'FALSE_MATCH',
    NO_TARGET_REQUIRED: 'NO_TARGET_REQUIRED',
    OTHER: 'OTHER',
    FALSE_RULE_MATCH: 'FALSE_RULE_MATCH',
    RESOURCE_REDIRECTED: 'RESOURCE_REDIRECTED',
};
export const TARGETER_COMPLETION_REASON_NAME = {
    [TARGETER_COMPLETION_REASON.ARRIVED]: 'Arrived',
    [TARGETER_COMPLETION_REASON.FALSE_SELECTOR_MATCH]: 'False selector match',
    [TARGETER_COMPLETION_REASON.FALSE_MATCH]: 'False match',
    [TARGETER_COMPLETION_REASON.NO_TARGET_REQUIRED]: 'No target required',
    [TARGETER_COMPLETION_REASON.OTHER]: 'Other',
    [TARGETER_COMPLETION_REASON.FALSE_RULE_MATCH]: 'False rule matched',
    [TARGETER_COMPLETION_REASON.RESOURCE_REDIRECTED]: 'Resource redirected',
};
export const TARGETER_OUTCOME = {
    TARGET_ISSUED: 'TARGET_ISSUED',
    DISMISSED: 'DISMISSED',
    ASSESSMENT_COMPLETED: 'ASSESSMENT_COMPLETED',
    WITHDRAWN: 'TARGET_WITHDRAWN',
    HIT: 'HIT',
    DISSMISSED_OTHER: 'DISMISSED_OTHER',
    TARGET_PENDING: 'TARGET_PENDING',
    DISMISSED_NO_TARGET_REQUIRED: 'DISMISSED_NO_TARGET_REQUIRED',
};
export const TARGETER_OUTCOME_NAME = {
    [TARGETER_OUTCOME.TARGET_ISSUED]: 'Target Issued',
    [TARGETER_OUTCOME.DISMISSED]: 'Dismissed',
    [TARGETER_OUTCOME.ASSESSMENT_COMPLETED]: 'Assessment Complete',
    [TARGETER_OUTCOME.WITHDRAWN]: 'Target Withdrawn',
    [TARGETER_OUTCOME.HIT]: 'Hit',
    [TARGETER_OUTCOME.DISSMISSED_OTHER]: 'Dismissed - Other',
    [TARGETER_OUTCOME.TARGET_PENDING]: 'Target Pending',
    [TARGETER_OUTCOME.DISMISSED_NO_TARGET_REQUIRED]: 'Dismissed - No target required',
};
export const FRONTLINE_OUTCOME = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    NO_SHOW: 'NO_SHOW',
    MISSED: 'MISSED',
    INSUFFICIENT_RESOURCES: 'INSUFFICIENT_RESOURCES',
    WITHDRAWN: 'WITHDRAWN',
    HIT_AWAITING_FEEDBACK: 'HIT_AWAITING_FEEDBACK',
    CLOSED_HIT_WITHOUT_FEEDBACK: 'CLOSED_HIT_WITHOUT_FEEDBACK',
    CLOSED_HIT_WITH_FEEDBACK: 'CLOSED_HIT_WITH_FEEDBACK',
};
export const FRONTLINE_OUTCOME_NAME = {
    [FRONTLINE_OUTCOME.POSITIVE]: 'Positive Exam',
    [FRONTLINE_OUTCOME.NEGATIVE]: 'Negative Exam',
    [FRONTLINE_OUTCOME.NO_SHOW]: 'No Show',
    [FRONTLINE_OUTCOME.MISSED]: 'Missed Target',
    [FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES]: 'Insufficient Resources',
    [FRONTLINE_OUTCOME.WITHDRAWN]: 'Target Withdrawn',
    [FRONTLINE_OUTCOME.HIT_AWAITING_FEEDBACK]: 'Hit Awaiting Feedback',
    [FRONTLINE_OUTCOME.CLOSED_HIT_WITHOUT_FEEDBACK]: 'Closed Hit Without Feedback',
    [FRONTLINE_OUTCOME.CLOSED_HIT_WITH_FEEDBACK]: 'Closed Hit with Feedback',
};
export const UPLIFTED_FRONTLINE_OUTCOME_NAME = {
    [FRONTLINE_OUTCOME.POSITIVE]: 'Positive',
    [FRONTLINE_OUTCOME.NEGATIVE]: 'Negative',
    [FRONTLINE_OUTCOME.NO_SHOW]: 'No Show',
    [FRONTLINE_OUTCOME.MISSED]: 'Missed',
    [FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES]: 'Insufficient Resources',
    [FRONTLINE_OUTCOME.WITHDRAWN]: 'Withdrawn',
    [FRONTLINE_OUTCOME.HIT_AWAITING_FEEDBACK]: 'Hit Awaiting Feedback',
    [FRONTLINE_OUTCOME.CLOSED_HIT_WITHOUT_FEEDBACK]: 'Closed Hit Without Feedback',
    [FRONTLINE_OUTCOME.CLOSED_HIT_WITH_FEEDBACK]: 'Closed Hit with Feedback',
};
export const TARGET_STATUS = {
    ACCEPTED: 'ACCEPTED',
    ACKNOWLEDGED: 'ACKNOWLEDGED',
    ASSIGNED: 'ASSIGNED',
    CAPTURED: 'CAPTURED',
    COMPLETE: 'COMPLETE',
    INCOMING: 'INCOMING',
    RCCU_INCOMING: 'RCCU_INCOMING',
    RCCU_RECEIPTED: 'RCCU_RECEIPTED',
    RECEIPTED: 'RECEIPTED',
    NEW: 'NEW',
    WITHDRAWN: 'WITHDRAWN',
};
export const TARGETER_ACTIONS_MONITOR = 'Monitor only';
export const TASK_OUTCOME = Object.assign(Object.assign({}, FRONTLINE_OUTCOME), { [TARGETER_OUTCOME.DISMISSED]: TARGETER_OUTCOME.DISMISSED, [TARGETER_OUTCOME.ASSESSMENT_COMPLETED]: TARGETER_OUTCOME.ASSESSMENT_COMPLETED });
export const TASK_OUTCOME_NAME = Object.assign(Object.assign({}, FRONTLINE_OUTCOME_NAME), { [TARGETER_OUTCOME.DISMISSED]: TARGETER_OUTCOME_NAME.DISMISSED, [TARGETER_OUTCOME.ASSESSMENT_COMPLETED]: TARGETER_OUTCOME_NAME.ASSESSMENT_COMPLETED });
export const MOVEMENT_DESCRIPTION = {
    INDIVIDUAL: 'individual',
    GROUP: 'group',
};
export const MOVEMENT_MODES = {
    TOURIST: 'RORO_TOURIST',
    UNACCOMPANIED_FREIGHT: 'RORO_UNACCOMPANIED_FREIGHT',
    ACCOMPANIED_FREIGHT: 'RORO_ACCOMPANIED_FREIGHT',
    AIR_PASSENGER: 'AIR_PASSENGER',
    FAST_PARCEL: 'FAST_PARCEL',
    AIR_FREIGHT: 'AIR_FREIGHT',
    GENERAL_AVIATION: 'GENERAL_AVIATION',
};
export const SUB_MODES = {
    COMMODITIES: 'COMMODITIES',
    CTBP: 'CTBP',
    IDP: 'IDP',
    RORO: 'RORO',
    GENERAL_AVIATION: 'GA',
};
export const MOVEMENT_MODE_NAMES = {
    [MOVEMENT_MODES.TOURIST]: 'RoRo Tourist',
    [MOVEMENT_MODES.UNACCOMPANIED_FREIGHT]: 'RoRo unaccompanied freight',
    [MOVEMENT_MODES.ACCOMPANIED_FREIGHT]: 'RoRo accompanied freight',
    [MOVEMENT_MODES.AIR_PASSENGER]: 'Air passenger',
    [MOVEMENT_MODES.FAST_PARCEL]: 'Fast parcel',
    [MOVEMENT_MODES.AIR_FREIGHT]: 'Air freight',
    [MOVEMENT_MODES.GENERAL_AVIATION]: 'General aviation',
};
export const MOVEMENT_ROLE = {
    AIR_CREW: 'CREW',
    PILOT: 'PILOT',
    DRIVER: 'DRIVER',
    PASSENGER: 'PASSENGER',
};
export const ICON = {
    CAR: 'car',
    INDIVIDUAL: 'person',
    GROUP: 'group',
    TRAILER: 'trailer',
    HGV: 'hgv',
    VAN: 'van',
    AIRCRAFT: 'aircraft',
    SHIP: 'ship',
    NONE: '',
};
export const ICON_MAPPING = {
    RORO_ACCOMPANIED_FREIGHT: {
        'vehicle-with-trailer': ICON.HGV,
        'vehicle-only': ICON.VAN,
        'trailer-only': ICON.TRAILER,
        'without-vehicle-and-trailer': ICON.NONE,
    },
    RORO_UNACCOMPANIED_FREIGHT: {
        'trailer-only': ICON.TRAILER,
        'without-trailer': ICON.NONE,
    },
    RORO_TOURIST: {
        vehicle: ICON.CAR,
        individual: ICON.INDIVIDUAL,
        group: ICON.GROUP,
    },
};
export const DESCRIPTION_MAPPING = {
    vehicle: 'Vehicle',
    individual: 'Single passenger',
    group: 'Group',
};
export const STRINGS = {
    DEFAULT_BOOKING_STRING_PREFIX: 'Booked',
    UNKNOWN: 'Unknown',
    AIRPAX: 'AIRPAX',
    A_TITLE_CASE_TEXT: 'A ',
    A_SMALL_TEXT: 'a ',
    AN_TITLE_CASE_TEXT: 'An ',
    AN_SMALL_TEXT: 'an ',
    AFTER_TRAVEL_TEXT: 'after travel',
    AGO_TEXT: 'ago',
    ARRIVAL_TEXT: 'Arrival',
    BEFORE_TRAVEL_TEXT: 'before travel',
    CURRENTLY_UNAVAILABLE_TEXT: 'Currently unavailable',
    DAYJS_FUTURE: 'before travel',
    DAYJS_FUTURE_REPLACE: 'before departure',
    DAYJS_PAST: 'ago',
    DESCRIPTIONS: {
        VEHICLE: 'Vehicle',
        TRAILER: 'Trailer',
        GROUP: 'Group',
        SINGLE_PASSENGER: 'Single passenger',
        FOOT_PASSENGER: 'foot passenger',
        PREPOSITIONS: {
            WITH: 'with',
        },
    },
    FORM: {
        NOT_REQUIRED: 'NOT_REQUIRED',
        OTHER: 'OTHER',
    },
    HIDDEN: 'hidden',
    NO: 'No',
    NOOPENER: 'noopener noreferrer',
    NONE: 'None',
    LATER_TEXT: 'later',
    TASK_MANAGEMENT_INLINE_HEADERS: {
        AIRPAX: 'AirPax',
        COMMODITIES: 'Commodities RBT Tasks',
        CTBP: 'CTBP RBT Tasks',
        GENERAL_AVIATION: 'General Aviation',
        IDP: 'Documents RBT Tasks',
        OSDT: 'OSDT RBT Tasks',
        RORO: 'RoRo',
    },
    TASK_LINK_HEADERS: {
        ADMIN: 'Admin',
        AIRPAX: 'Airpax tasks',
        AIRPAX_UPLIFT: 'Uplifted Airpax tasks',
        COMMODITIES: 'Commodities',
        CTBP: 'CTBP',
        IDP: 'IDP',
        OSDT: 'OSDT',
        GENERAL_AVIATION: 'General Aviation',
        RORO: 'RoRo tasks',
    },
    YES: 'Yes',
};
export const FONT_CLASSES = {
    0: 'font__bold',
    1: 'font__light',
};
export const OPERATION = {
    ADD: 'ADD',
    CHANGE: 'CHANGE',
    SUBTRACT: 'SUBTRACT',
};
export const RECIPIENT_TYPE = {
    FRONTLINE: 'FRONTLINE',
    BORDER: 'BORDER',
};
export const TARGET_OPERATION = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
};
export const UNKNOWN_TIME_DATA = { hours: null, minutes: null, seconds: null };
export const FORM_MESSAGES = {
    ON_CANCELLATION: 'Are you sure you want to cancel?',
};
export const COMPONENT_TYPES = {
    AUTOCOMPLETE: 'autocomplete',
    CONTAINER: 'container',
    DATE: 'date',
    EMAIL: 'email',
    DATETIME: 'datetime',
    SELECT: 'select',
    CHECKBOXES: 'checkboxes',
    RADIOS: 'radios',
    SECTION_LINK: 'section-link',
    TEXT_INPUT: 'text',
    SELECTORS_SUMMARY: 'selectorsSummary',
};
export const DEPARTURE_STATUS = {
    DEPARTURE_CONFIRMED: {
        value: 'DEPARTURE_CONFIRMED',
        classname: 'green',
        description: 'Departure confirmed',
        code: 'DC',
    },
    BOOKED_PASSENGER: {
        value: 'BOOKED_PASSENGER',
        classname: 'purple',
        description: 'Booked passenger',
        code: 'BP',
    },
    CHECKED_IN: {
        value: 'CHECKED_IN',
        classname: 'blue',
        description: 'Checked-in',
        code: 'CI',
    },
    DEPARTURE_EXCEPTION: {
        value: 'DEPARTURE_EXCEPTION',
        classname: 'red',
        description: 'Departure exception',
        code: 'DX',
    },
};
export const LOCAL_STORAGE_KEYS = {
    ADDITIONAL_CONTENT_TRACKER: 'additional-content-tracker',
    ADD_PNC_CHECK_TRACKER: 'add-pnc-check-tracker',
    ADD_TASK_NOTE_TRACKER: 'add-task-note-tracker',
    ADD_TASK_DETAIL_TRACKER: 'add-task-detail-tracker',
    AIRPAX_FILTERS: 'airpax-filters',
    AIRPAX_TARGET_FILTERS: 'airpax-targets-filters',
    CACHED_CREDIBILITY_CHECKS(taskId = null) {
        return `cached-credibility-checks${taskId ? `-${taskId}` : ''}`;
    },
    CACHED_TAB: 'cached-tab',
    DO_NOT_STORE_LOCATION: 'do-not-store-location',
    FILTER_PAGE_TRACKER: 'pre-filter-page',
    GENERAL_AVIATION_FILTERS: 'general-aviation-filters',
    LAST_KNOWN_LOCATION: 'last-known-location',
    MOVEMENT_TASK_ENABLED: 'movement-task',
    PASSENGER_CC: 'passenger-cc',
    PNR_SELECTIONS: 'pnr-selections',
    PREVIOUS_TASK_ENABLED: 'previous-task',
    PREVIOUS_KNOWN_LOCATION: 'previous-known-location',
    RORO_FILTERS: 'roro-filters',
    RORO_TARGETS_FILTERS: 'roro-targets-filters',
    SESSION_ID: 'kc-session-id',
    TARGETS_SORT_ORDER: 'targets-sort-order',
    TASKS_SORT_ORDER: 'tasks-sort-order',
    TAB_TRACKER: 'tab-tracker',
    TARGET_INFO_PATH: 'target-info-path',
    TARGET_DETAILS_BACK_PATH: 'target-details-back-path',
    TASK_STATUS: 'task-status',
    TASK_LIST_PAGE: 'task-list-page',
    RECORD_OUTCOME_PATH: 'record-outcome-path',
    VIEW: 'VIEW',
    VIEW_SWAP: 'view-swap',
};
export const FORM_ACTIONS = {
    ADD_RECIPIENTS: 'Add recipients and close',
    CANCEL: 'cancel',
    COMPLETE_ASSESSMENT: 'complete-assessment',
    CREATE_TARGET: 'create-target',
    FINISH: 'finish',
    NAVIGATE: 'navigate',
    NEXT: 'next',
    SILENT_SUBMIT: 'silent-submit',
    SUBMIT: 'submit',
};
export const PATHS = {
    ACCESSIBILITY_STATEMENT: '/accessibility-statement',
    ADMIN: '/admin',
    APPROVED_DOMAINS: '/approved-domains',
    AIRPAX: '/tasks/airpax',
    BULK_DISMISSALS: '/bulk-dismissals',
    CREATE_GENERAL_AVIATION_TASK: '/create-a-task',
    DEPRECATED: {
        AIRPAX: '/airpax/tasks',
        RORO: '/roro/tasks',
    },
    EDIT: '/edit',
    GENERAL_AVIATION: '/tasks/general-aviation',
    HANDLING_INSTRUCTIONS: '/handling-instructions',
    PREVIOUS_TASKS: {
        AIRPAX: '/previous-tasks/airpax',
        RORO: '/previous-tasks/roro',
        TASKS_LIST: '/previous-tasks',
    },
    RORO: '/tasks/roro',
    RORO_V1: '/tasks',
    SIGN_OUT: '/sign-out',
    TARGETS: {
        AIRPAX: '/targets/airpax',
        RORO: '/targets/roro',
    },
    VIEW: '/view',
    TASK_LISTS() {
        return [this.RORO, this.UPLIFT.AIRPAX, this.AIRPAX, this.GENERAL_AVIATION];
    },
    TARGET_LISTS() {
        return [this.TARGETS.AIRPAX, this.TARGETS.RORO];
    },
    TASK_PATHS() {
        return [
            this.DEPRECATED.AIRPAX,
            this.DEPRECATED.RORO,
            this.RORO_V1,
            this.PREVIOUS_TASKS.AIRPAX,
            this.PREVIOUS_TASKS.RORO,
            this.PREVIOUS_TASKS.TASKS_LIST,
            this.AIRPAX,
            this.RORO,
            this.UPLIFT.AIRPAX,
            this.GENERAL_AVIATION,
        ];
    },
    TARGET_PATHS() {
        return [
            this.TARGETS.AIRPAX,
            this.TARGETS.RORO,
        ];
    },
    UPLIFT: {
        PREFIX: '/v2',
        AIRPAX: '/v2/tasks/airpax',
        RORO: '/v2/tasks/roro',
        TASK_MESSAGE_HISTORY: '/message-history',
    },
};
export const PATH_TO_MODE_ASSOC = {
    [PATHS.RORO]: [MOVEMENT_MODES.ACCOMPANIED_FREIGHT, MOVEMENT_MODES.UNACCOMPANIED_FREIGHT, MOVEMENT_MODES.TOURIST],
    [PATHS.TARGETS.AIRPAX]: [MOVEMENT_MODES.AIR_PASSENGER],
    [PATHS.TARGETS.RORO]: [MOVEMENT_MODES.ACCOMPANIED_FREIGHT, MOVEMENT_MODES.UNACCOMPANIED_FREIGHT, MOVEMENT_MODES.TOURIST],
    [PATHS.UPLIFT.AIRPAX]: [MOVEMENT_MODES.AIR_PASSENGER], // TODO: Clean up once old airpax task list has been completely removed.
    [PATHS.AIRPAX]: [MOVEMENT_MODES.AIR_PASSENGER], // TODO: Clean up once old airpax task list has been completely removed.
};
export const STATUS_CODES = {
    RANGE_400: 400,
    RANGE_500: 500,
};
export const DATE_REGEXS = [
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/,
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{9}Z/,
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}\+\d{4}/,
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z/,
    /\d{2}-\d{2}-\d{4} \d{2}:\d{2}/,
];
export const RBAC = {
    COP_ADMIN: 'cop_admin',
    LIVE_SERVICES: 'live_services',
    PNR_ACCESS: 'pnr_access',
    FRONTLINE_PNR_ACCESS: 'frontline_pnr_access',
    TARGET_CREATE: 'target_create',
    TARGET_READ: 'target_read',
    TARGET_UPDATE: 'target_update',
    TASK_CREATE: 'task_create',
    TASK_AIRPAX_READ: 'task_airpax_read',
    TASK_GA_READ: 'task_ga_read',
    TASK_READ: 'task_read',
    TASK_RORO_READ: 'task_roro_read',
    TASK_UPDATE: 'task_update',
    TASK_AIRPAX_COMMODITIES_READ: 'task_airpax_commodities_read',
    TASK_AIRPAX_IDP_READ: 'task_airpax_idp_read',
    TASK_AIRPAX_CTBP_READ: 'task_airpax_ctbp_read',
    TASK_AIRPAX_OSDT_READ: 'task_airpax_osdt_read',
    TASK_RULE_BULK_DELETE: 'task_rule_bulk_delete',
    TARGETER_AIR_PASSENGER: 'targeter_air_passenger',
    TARGETER_AIRPAX_OSDT: 'targeter_airpax_osdt',
    TARGETER_AIRPAX_IDP: 'targeter_airpax_idp',
    TARGETER_AIRPAX_COMMODITIES: 'targeter_airpax_commodities',
    TARGETER_AIRPAX_CTBP: 'targeter_airpax_ctbp',
    TARGETER_DOCUMENTS_ANALYST: 'targeter-documents-analyst',
    TARGETER_COMMODITIES_ANALYST: 'targeter-commodities-analyst',
};
export const TASK_MODES = {
    RORO_TASK: ['RORO'],
    GA_TASK: ['GA'],
    COMMODITIES_TASK: ['COMMODITIES'],
    CTBP_TASK: ['CTBP'],
    IDP_TASK: ['IDP'],
    OSDT_TASK: ['COMMODITIES', 'IDP'],
};
export const SECURITY_LABELS = {
    COMMODITIES: ['Data_Commodities_Tasks'],
    IDP: ['Data_IDP_Tasks'],
    OSDT: ['Data_OSDT_Tasks', 'Data_IDP_Tasks', 'Data_Commodities_Tasks'],
    CTBP: ['Data_CTBP_Tasks'],
};
export const ALL_SECURITY_LABELS = [...new Set([...Object.values(SECURITY_LABELS)])];
export const SYSTEMS = {
    COP: 'COP',
    CERBERUS: 'CERBERUS',
};
export const LOG_TYPE = {
    STRING: 'STRING',
    LINK: 'LINK',
    TARGET_OUTCOME: 'TARGET_OUTCOME',
};
export const CONTENT_TYPE = {
    TEXT: 'TEXT',
    PHOTO: 'PHOTO',
};
export const GROUP_MEMBER = {
    AIRPAX: 'AIRPAX',
    BOTH: 'BOTH',
    NONE: 'NONE',
    RORO: 'RORO',
};
export const ROLE = {
    RCCU: 'RCCU',
    HIGHER_OFFICER: 'HIGHER_OFFICER',
    INTERCEPTING_OFFICER: 'INTERCEPTING_OFFICER',
};
export const STATUS = {
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
};
export const VIEW = {
    AIRPAX_IO: 'AIRPAX_IO',
    AIRPAX_HO: 'AIRPAX_HO',
    AIRPAX_RCCU: 'AIRPAX_RCCU',
    AIRPAX_V2: 'AIRPAX_V2',
    AIRPAX: 'AIRPAX',
    COMMODITIES: 'COMMODITIES',
    CTBP: 'CTBP',
    GENERAL_AVIATION: 'GA',
    IDP: 'IDP',
    RORO: 'RORO',
    RORO_IO: 'RORO_IO',
    OSDT: 'OSDT',
};
export const DOCUMENT_TYPES = {
    PASSPORT: 'PASSPORT',
    OTHER: 'OTHER',
};
export const PHASE_BANNER = {
    NEW_SERVICE_1: 'This is a new service - your',
    NEW_SERVICE_2: 'feedback',
    NEW_SERVICE_3: 'will help us to improve it',
};
export const TARGET_PAGE_ACCESS_CONTROL = {
    [GROUP_MEMBER.BOTH]: [PATHS.TARGETS.RORO, PATHS.TARGETS.AIRPAX, PATHS.UPLIFT.AIRPAX],
    [GROUP_MEMBER.RORO]: [PATHS.TARGETS.RORO],
    [GROUP_MEMBER.AIRPAX]: [PATHS.TARGETS.AIRPAX],
    [GROUP_MEMBER.NONE]: [],
};
export const TARGET_SHEET_WIDTH = 1175;
export const TARGET_SHEET_SECTIONS = {
    TARGET_DETAILS: 'target-details',
    PERSONS_OF_INTEREST: 'persons-of-interest',
    CO_TRAVELLERS: 'co-travellers',
    UNDER_18S: 'under-18s',
    BOOKING_DETAILS: 'booking-details',
    TARGET_ISSUED_BY: 'target-issued-by',
};
export const TARGET_SHEET_ORDER = [
    TARGET_SHEET_SECTIONS.TARGET_DETAILS,
    TARGET_SHEET_SECTIONS.PERSONS_OF_INTEREST,
    TARGET_SHEET_SECTIONS.CO_TRAVELLERS,
    TARGET_SHEET_SECTIONS.UNDER_18S,
    TARGET_SHEET_SECTIONS.BOOKING_DETAILS,
    TARGET_SHEET_SECTIONS.TARGET_ISSUED_BY,
];
export const SUMMARY_LIST_TYPES = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    VERTICAL_SMALL: 'vertical-small',
};
export const MAX_MOBILE_PHONE_WIDTH = 640;
export const Seconds = {
    MOMENT: 10,
    MINUTE: 60,
    HOUR: 3600,
    DAY: 86400,
    THREE_MONTHS: 7.884e6,
    YEAR: 3.154e7,
};
