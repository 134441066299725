// Util(s)
import toAutoPopulationPayload from './adapt/toAutoPopulationPayload';
import toSubmissionPayload from './adapt/toTisSubmissionPayload';
import toFormData from './adapt/toFormData';
import { toTitleCase } from '../String/stringUtil';

const toFormattedSuspectedAbuseTypes = (suspectedAbuseTypes) => {
  if (!suspectedAbuseTypes || !suspectedAbuseTypes?.length) {
    return null;
  }

  const toCleanedAbuseType = (abuseType) => {
    if (!abuseType) {
      return null;
    }

    const cleanedStr = abuseType.split('_').join(' ');
    return toTitleCase(cleanedStr);
  };

  // TODO: Update this implementation once it has changed in the api.
  return suspectedAbuseTypes
    .map(toCleanedAbuseType)
    .filter((suspectedAbuseType) => !!suspectedAbuseType)
    .join(', ');
};

const getSuspectedAbuseTypes = (informationSheet) => {
  if (!informationSheet?.suspectedAbuseTypes || !informationSheet?.suspectedAbuseTypes?.length) {
    return [];
  }

  return informationSheet.suspectedAbuseTypes;
};

const getSelectionReasoning = (informationSheet) => {
  return informationSheet?.selectionReasoning || null;
};

const getWithdrawalReason = (targetSheet) => {
  return targetSheet?.withdrawalReason || null;
};
const getTargetOutcome = (targetData) => {
  return targetData?.frontLineAction?.outcome || null;
};

const getCaseUrl = (targetData) => {
  return targetData?.frontLineAction?.caseUrl || null;
};

const getTargetIssueDate = (informationSheet) => {
  return informationSheet?.submissionDate || informationSheet?.form?.submissionDate || null;
};

const getIssuingHubName = (informationSheet) => {
  return informationSheet?.issuingHub?.name || null;
};

const getIssuingHubTelephone = (informationSheet) => {
  return informationSheet?.issuingHub?.telephone || null;
};

const getSubmittingUser = (informationSheet) => {
  return informationSheet?.submittingUser || null;
};

const getAdditionalInformation = (informationSheet) => {
  return informationSheet?.additionalInformation || null;
};

const getPublicInterestImmunity = (informationSheet) => {
  return informationSheet?.publicInterestImmunity ? 'Yes' : 'No';
};

const getXrayAnalysisAvailability = (targetSheet) => {
  return targetSheet?.xrayAnalysisAvailable || false;
};

const getTargetStatus = (targetSheet) => {
  return targetSheet?.status || null;
};

const getTargetRecipients = (targetSheet) => {
  return targetSheet?.targetRecipients || targetSheet?.informationSheet?.targetRecipients || null;
};

const getInformationSheet = (targetSheet) => {
  return targetSheet?.informationSheet || null;
};

const getImmigrationChecks = (informationSheet) => {
  return informationSheet?.immigrationChecks || null;
};

const getCustomsChecks = (informationSheet) => {
  return informationSheet?.customsChecks || null;
};

const getIntelligenceGatheringChecks = (informationSheet) => {
  return informationSheet?.intelligenceGatheringChecks || null;
};

const getCrewChecks = (informationSheet) => {
  return informationSheet?.crewChecks || null;
};

const getAdditionalChecks = (informationSheet) => {
  return informationSheet?.additionalChecks || null;
};

const TargetInformationUtil = {
  prefillPayload: toAutoPopulationPayload,
  submissionPayload: toSubmissionPayload,
  convertToPrefill: toFormData,
  status: getTargetStatus,
  targetRecipients: getTargetRecipients,
  informationSheet: {
    get: getInformationSheet,
    interestImmunity: getPublicInterestImmunity,
    additionalInformation: getAdditionalInformation,
    targetDetails: {
      issueDate: getTargetIssueDate,
      issuingHub: getIssuingHubName,
      issuingHubTel: getIssuingHubTelephone,
      submittingUser: getSubmittingUser,
    },
    targetOutcome: getTargetOutcome,
  },
  interception: {
    immigrationChecks: getImmigrationChecks,
    customsChecks: getCustomsChecks,
    intelligenceGatheringChecks: getIntelligenceGatheringChecks,
    crewChecks: getCrewChecks,
    additionalChecks: getAdditionalChecks,
  },
  format: {
    abuseTypes: toFormattedSuspectedAbuseTypes,
  },
  selectionReason: getSelectionReasoning,
  suspectedAbuseTypes: getSuspectedAbuseTypes,
  withdrawalReason: getWithdrawalReason,
  xrayAnalysisAvailable: getXrayAnalysisAvailability,
  caseUrl: getCaseUrl,
};

export default TargetInformationUtil;

export {
  getAdditionalInformation,
  getInformationSheet,
  getIssuingHubName,
  getIssuingHubTelephone,
  getPublicInterestImmunity,
  getSubmittingUser,
  getTargetIssueDate,
  getTargetStatus,
  getWithdrawalReason,
  getXrayAnalysisAvailability,
};
