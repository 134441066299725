// Config(s)
import { COMPONENT_IDS, FRONTLINE_CHECK_OPTION_VALUES, FRONTLINE_CHECK_VALUE_OPTIONS } from '../constants';
import { STRINGS } from '../../../../../../../../../utils/constants';

// Util(s)
import RisksUtil from '../../../../../../../../../utils/Risks/risksUtil';

const hasOtherTypes = (suspectedAbuseTypes) => {
  return suspectedAbuseTypes.some((suspectedAbuseType) => suspectedAbuseType.startsWith(STRINGS.FORM.OTHER));
};

const toSuspectedAbuseOtherTypes = (informationSheet) => {
  if (!informationSheet?.suspectedAbuseTypes || !informationSheet?.suspectedAbuseTypes?.length) {
    return null;
  }

  if (!hasOtherTypes(informationSheet.suspectedAbuseTypes)) {
    return null;
  }

  const otherAbuseType = informationSheet.suspectedAbuseTypes.find((suspectedAbuseType) => suspectedAbuseType.startsWith(STRINGS.FORM.OTHER));
  return otherAbuseType.split('-')[1].trim();
};

const toSuspectedAbuseTypes = (informationSheet) => {
  if (!informationSheet?.suspectedAbuseTypes || !informationSheet?.suspectedAbuseTypes?.length) {
    return null;
  }

  return informationSheet.suspectedAbuseTypes.map((suspectedAbuseType) => {
    if (suspectedAbuseType.startsWith(STRINGS.FORM.OTHER)) {
      return STRINGS.FORM.OTHER;
    }
    return suspectedAbuseType;
  });
};

const toTargetCategory = (informationSheet) => {
  const risks = RisksUtil.getRisks(informationSheet);
  if (!risks?.selector) {
    return null;
  }

  const category = risks?.selector?.category;
  if (!category) {
    return null;
  }

  return category;
};

export const toRecipients = (recipients) => {
  if (recipients?.groups && recipients?.groups?.length) {
    return recipients.groups.map((group) => {
      return {
        ...group,
        value: group.displayname,
        customName: group.displayname,
        telephone: null, // TODO: Default to null for now we have no telephone number associated with each group.
      };
    });
  }

  if (recipients?.group) {
    const newGroup = recipients?.group;
    return [{
      ...newGroup,
      value: newGroup.displayname,
      customName: newGroup.displayname,
    }];
  }

  return [];
};

export const toBorderOfficers = (recipients) => {
  if (recipients?.users && recipients?.users?.length) {
    return recipients.users.map((user) => {
      return {
        ...user,
      };
    });
  }

  return [];
};

const toReferralReason = (informationSheet) => {
  return informationSheet?.selectionReasoning || null;
};

const toOperationName = (informationSheet) => {
  return informationSheet?.operation || null;
};

// Always return true (COP-19411)
const toPublicInterestImmunity = () => {
  return [true.toString()];
};

const toWatchlist = (informationSheet) => {
  return informationSheet?.movement?.personsOfInterest?.some((p) => {
    return p?.watchList?.added?.toString();
  }) || 'false';
};

const getOtherDetailCheck = (informationSheet, key, options) => {
  const optionValues = options.map((option) => option.value);
  return informationSheet?.[key]
    ?.filter((check) => !optionValues.includes(check))
    ?.[0];
};

const cleanChecks = (informationSheet, key, options) => {
  const optionValues = options.map((option) => option.value);
  return [
    ...informationSheet?.[key]?.filter((check) => optionValues.includes(check)) || [],
    ...(getOtherDetailCheck(informationSheet, key, options) && ['other']) || [],
  ];
};

const toImmigrationChecks = (informationSheet) => {
  return cleanChecks(
    informationSheet,
    'immigrationChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.IMMIGRATION_CHECKS,
  );
};

const toImmigrationChecksOtherDetail = (informationSheet) => {
  return getOtherDetailCheck(
    informationSheet,
    'immigrationChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.IMMIGRATION_CHECKS,
  );
};

const toCustomsChecks = (informationSheet) => {
  return cleanChecks(
    informationSheet,
    'customsChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.CUSTOMS_CHECKS,
  );
};

const toCustomsChecksOtherDetail = (informationSheet) => {
  return getOtherDetailCheck(
    informationSheet,
    'customsChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.CUSTOMS_CHECKS,
  );
};

const toIntelligenceChecks = (informationSheet) => {
  return cleanChecks(
    informationSheet,
    'intelligenceGatheringChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.INTELLIGENCE_GATHERING_CHECKS,
  );
};

const toIntelligenceChecksOtherDetail = (informationSheet) => {
  return getOtherDetailCheck(
    informationSheet,
    'intelligenceGatheringChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.INTELLIGENCE_GATHERING_CHECKS,
  );
};

const toCrewChecks = (informationSheet) => {
  return cleanChecks(
    informationSheet,
    'crewChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.CREW_CHECKS,
  );
};

const toCrewChecksOtherDetail = (informationSheet) => {
  return getOtherDetailCheck(
    informationSheet,
    'crewChecks',
    FRONTLINE_CHECK_VALUE_OPTIONS.CREW_CHECKS,
  );
};

const toAdditionalChecks = (informationSheet) => {
  return informationSheet?.additionalChecks?.[0] || null;
};

const toFrontLineChecks = (informationSheet) => {
  return [
    toImmigrationChecks(informationSheet).length > 0
      && FRONTLINE_CHECK_OPTION_VALUES.IMMIGRATION_CHECKS,
    toCustomsChecks(informationSheet).length > 0
      && FRONTLINE_CHECK_OPTION_VALUES.CUSTOMS_CHECKS,
    toIntelligenceChecks(informationSheet).length > 0
      && FRONTLINE_CHECK_OPTION_VALUES.INTELLIGENCE_GATHERING_CHECKS,
    toCrewChecks(informationSheet).length > 0
      && FRONTLINE_CHECK_OPTION_VALUES.CREW_CHECKS,
    toAdditionalChecks(informationSheet) !== null
      && FRONTLINE_CHECK_OPTION_VALUES.ADDITIONAL_CHECKS,
  ].filter((check) => check !== false);
};

const toAutopopulationPayload = (informationSheet) => {
  if (!informationSheet) {
    return null;
  }

  return {
    [COMPONENT_IDS.RECIPIENTS]: toRecipients(informationSheet.recipients),
    [COMPONENT_IDS.BORDER_OFFICERS]: toBorderOfficers(informationSheet.recipients),
    [COMPONENT_IDS.TARGET_CATEGORY]: toTargetCategory(informationSheet),
    [COMPONENT_IDS.ABUSE_TYPES]: toSuspectedAbuseTypes(informationSheet),
    [COMPONENT_IDS.OTHER_TYPES]: toSuspectedAbuseOtherTypes(informationSheet),
    [COMPONENT_IDS.OPERATION]: toOperationName(informationSheet),
    [COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY]: toPublicInterestImmunity(),
    [COMPONENT_IDS.REFERRAL_REASON]: toReferralReason(informationSheet),
    [COMPONENT_IDS.WATCHLIST]: toWatchlist(informationSheet),
    [COMPONENT_IDS.FRONTLINE_CHECKS]: toFrontLineChecks(informationSheet),
    [COMPONENT_IDS.IMMIGRATION]: toImmigrationChecks(informationSheet),
    [COMPONENT_IDS.IMMIGRATION_OTHER]: toImmigrationChecksOtherDetail(informationSheet),
    [COMPONENT_IDS.CUSTOMS]: toCustomsChecks(informationSheet),
    [COMPONENT_IDS.CUSTOMS_OTHER]: toCustomsChecksOtherDetail(informationSheet),
    [COMPONENT_IDS.INTELLIGENCE]: toIntelligenceChecks(informationSheet),
    [COMPONENT_IDS.INTELLIGENCE_OTHER]: toIntelligenceChecksOtherDetail(informationSheet),
    [COMPONENT_IDS.CREW]: toCrewChecks(informationSheet),
    [COMPONENT_IDS.CREW_OTHER]: toCrewChecksOtherDetail(informationSheet),
    [COMPONENT_IDS.ADDITIONAL]: toAdditionalChecks(informationSheet),
  };
};

export default toAutopopulationPayload;
