import React from 'react';
import useForceInitialTabFocus from '../../utils/Hooks/useForceInitialTabFocus';

const SkipLink = () => {
  const ref = useForceInitialTabFocus();

  return (
    <a ref={ref} href="#main-content" className="govuk-skip-link">
      Skip to main content
    </a>
  );
};

export default SkipLink;
