import { FILTER_COUNT_TYPES } from '../../../routes/Task/TaskList/shared/constants';
import { COMPONENT_TYPES } from '../../../utils/constants';

const toFilterParamsFieldIdIfRequired = (component) => {
  if (component?.filterParamsFieldId) {
    return component.filterParamsFieldId;
  }

  return component.fieldId;
};

const getCountForOption = ({
  component,
  value,
  selectedTab,
  filterCounts,
}) => {
  const fieldId = component.fieldId;
  const counts = filterCounts?.[FILTER_COUNT_TYPES[fieldId]];
  if (Array.isArray(counts)) {
    const isMultiSelect = component.multi || component.type === COMPONENT_TYPES.CHECKBOXES;
    const paramsFilterFieldId = toFilterParamsFieldIdIfRequired(component);
    return counts?.find((item) => (
      isMultiSelect
        ? item.filterParams[paramsFilterFieldId]?.includes(value) || false
        : item.filterParams[paramsFilterFieldId] === value),
    )?.statusCounts[selectedTab];
  }
  return 0;
};

const setupOptions = (component, selectedTab, filterCounts) => {
  return component.data.options.map((opt) => {
    const count = getCountForOption({ component,
      value: opt.value,
      selectedTab,
      filterCounts }) ?? 0;
    const originalLabel = opt.originalLabel || opt.label;
    const label = count === null ? originalLabel : `${originalLabel} (${count})`;
    return {
      ...opt,
      count,
      originalLabel,
      label,
    };
  });
};

const setupComponentOptions = (component, selectedTab, filterCounts) => {
  if (component.data?.options && component.dynamicOptions) {
    component.data.options = setupOptions(component, selectedTab, filterCounts);
  }
  return component;
};

const setupFilterTaskCounts = (form, selectedTab, filterCounts) => {
  form.pages = form.pages.map((page) => {
    return {
      ...page,
      components: page.components.map((component) => {
        return setupComponentOptions(component, selectedTab, filterCounts);
      }),
    };
  });
  return form;
};

export default setupFilterTaskCounts;
