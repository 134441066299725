const addPhoto = (person, additionalContent, dateTimeStamp) => {
  if (person?.additionalContent.find((content) => content.id === additionalContent.id)) {
    const contentItem = person.additionalContent.find((content) => content.id === additionalContent.id);
    if (contentItem.type === 'PHOTO') {
      contentItem.filename = additionalContent.filename;
      contentItem.source = additionalContent.source;
      contentItem.url = additionalContent.url;
      contentItem.approximateDateTaken = additionalContent?.approximateDateTaken;
      contentItem.timestamp = dateTimeStamp;
    } else {
      contentItem.content = additionalContent.content;
      contentItem.timestamp = dateTimeStamp;
    }
  }

  if (person.documents) {
    person.documents.forEach((document) => {
      if (document.additionalContent.find((content) => content.id === additionalContent.id)) {
        const contentItem = document.additionalContent.find((content) => content.id === additionalContent.id);
        if (contentItem.type === 'PHOTO') {
          contentItem.filename = additionalContent.filename;
          contentItem.source = additionalContent.source;
          contentItem.url = additionalContent.url;
          contentItem.approximateDateTaken = additionalContent?.approximateDateTaken;
          contentItem.timestamp = dateTimeStamp;
        } else {
          contentItem.content = additionalContent.content;
          contentItem.timestamp = dateTimeStamp;
        }
      }
    });
  }
};

const updateAdditionalContentToTask = (task, additionalContent, dateTimeStamp) => {
  if (task.movement.booking?.additionalContent.find((content) => content.id === additionalContent.id)) {
    const contentItem = task.movement.booking.additionalContent.find((content) => content.id === additionalContent.id);
    contentItem.content = additionalContent?.content;
    contentItem.timestamp = dateTimeStamp;
  } else if (task.movement.baggage?.additionalContent.find((content) => content.id === additionalContent.id)) {
    const contentItem = task.movement.baggage.additionalContent.find((content) => content.id === additionalContent.id);
    contentItem.content = additionalContent?.content;
    contentItem.timestamp = dateTimeStamp;
  } else if (task.movement.journey?.additionalContent.find((content) => content.id === additionalContent.id)) {
    const contentItem = task.movement.journey.additionalContent.find((content) => content.id === additionalContent.id);
    contentItem.content = additionalContent?.content;
    contentItem.timestamp = dateTimeStamp;
  }

  const poi = task.movement.personsOfInterest ?? [];
  const others = task.movement.otherPersons ?? [];

  poi.forEach((p) => addPhoto(p, additionalContent, dateTimeStamp));
  others.forEach((p) => addPhoto(p, additionalContent, dateTimeStamp));

  task.movement.personsOfInterest = poi;
  task.movement.otherPersons = others;

  return task;
};

const updateAdditionalContent = (task, submissionPayload, dateTime) => {
  const updatedTask = updateAdditionalContentToTask(task, submissionPayload, dateTime);
  return updatedTask;
};

export default updateAdditionalContent;
