var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from '@tanstack/react-query';
// Contexts
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
// Services
import AxiosRequests from '../../api/axiosRequests';
// Hooks
import { useAxiosInstance } from '../Axios/axiosInstance';
// Configs
import config from '../config';
import QUERY_KEYS from './constants';
const useFetchDomains = (type) => {
    const keycloak = useKeycloak();
    const { canCreateTarget } = usePermission();
    const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
    const getDomains = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!canCreateTarget) {
            return [];
        }
        return AxiosRequests.getDomains(apiClient, type);
    });
    const { data: domains, isFetching: isLoading } = useQuery({
        queryKey: [QUERY_KEYS.DOMAINS, type],
        queryFn: () => getDomains(),
        initialData: [],
    });
    return { domains, isLoading };
};
export default useFetchDomains;
