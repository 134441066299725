// Global import(s)
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Config(s)
import config from '../../../../../utils/config';
import { PATH_TO_MODE_ASSOC } from '../../../../../utils/constants';

// Context(s)
import { useKeycloak } from '../../../../../context/Keycloak';
import { usePermission } from '../../../../../context/PermissionContext';
import { useTabs } from '../../../../../context/TabContext';
import { useView } from '../../../../../context/ViewContext';

// Services
import AxiosRequests from '../../../../../api/axiosRequests';

// Component(s)
import InternalTaskDetailsPage from './InternalTaskDetailsPage';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';

// Util(s)
import { CommonUtil, JustificationUtil, MovementUtil } from '../../../../../utils';

// Styling
import './TaskDetailsPage.scss';

const TaskDetailsPage = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const keycloak = useKeycloak();
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [taskData, setTaskData] = useState(null);
  const [targetSheet, setTargetSheet] = useState(null);
  const [hasEab, setHasEab] = useState(false);
  const [borderEvent, setBorderEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTaskData, setRefreshTaskData] = useState(false);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view, isSettingView } = useView();

  useSetTabTitle(TAB_TITLES.TASK_DETAILS);

  const getBorderEventData = async (targetId) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return;
    }

    await AxiosRequests.borderEventData(apiClient, targetId)
      .then((data) => setBorderEvent(data))
      .catch(() => setBorderEvent([]));
  };

  const getTargetSheet = async (targetId) => {
    await AxiosRequests.targetSheet(apiClient, targetId)
      .then(async (data) => {
        setTargetSheet(data);
      })
      .catch(() => setTargetSheet(null));
  };

  const getTaskData = async (justificationId = null) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      setTaskData(null);
      setIsLoading(false);
      return;
    }

    await AxiosRequests.taskData(apiClient, taskId, justificationId)
      .then(async (data) => {
        if (CommonUtil.requiresTargetSheet(data?.targeterAction)) {
          await getTargetSheet(data?.targetId);
        }

        if (CommonUtil.eabRaised(data)) {
          setHasEab(true);
        }

        // Clear the previously stored state value
        if (data?.withdrawn) {
          setTargetSheet(null);
        }

        setTaskData(data);
      })
      .catch(() => {
        setTaskData(null);
        setTargetSheet(null);
      })
      .finally(() => {
        setRefreshTaskData(false);
        setIsLoading(false);
      });
  };

  const findPathByMode = (mode) => {
    return Object.keys(PATH_TO_MODE_ASSOC).find((k) => PATH_TO_MODE_ASSOC[k].includes(mode));
  };

  const checkModeAndPageMismatch = async () => {
    const taskListPath = CommonUtil.getListPath(location?.pathname);
    const mode = MovementUtil.movementMode(taskData);
    if (PATH_TO_MODE_ASSOC[taskListPath]?.includes(mode)) {
      // Return undefined as the task is loaded with the correct path.
      return undefined;
    }
    const redirectPath = findPathByMode(mode);
    if (!redirectPath) {
      return undefined;
    }
    return `${redirectPath}/${taskId}`;
  };

  const getTaskWithJustification = async (justificationId) => {
    setIsLoading(true);
    if (justificationId === 'CREATE_JUSTIFICATION') {
      JustificationUtil.toJustificationUI();
    }

    await getTaskData(justificationId);
  };

  useEffect(() => {
    trackPageView();
    CommonUtil.removePreviousFlag();
    CommonUtil.removeMovementFlag();
  }, []);

  useEffect(() => {
    getTaskData();
    return () => {
      AxiosRequests.cancel(source);
    };
  }, [taskId]);

  useEffect(() => {
    if (refreshTaskData) {
      getTaskData();
    }
    return () => {
      AxiosRequests.cancel(source);
    };
  }, [refreshTaskData]);

  useEffect(() => {
    if (hasEab) {
      getBorderEventData(taskData?.targetId);
    }
  }, [hasEab, taskData]);

  useEffect(() => {
    checkModeAndPageMismatch()
      .then((redirectPath) => {
        if (!redirectPath) {
        // Do nothing and render page as is.
          return;
        }
        navigate(redirectPath);
      });
  }, [taskData]);

  if (isLoading || !view || isSettingView) {
    return <LoadingSpinner />;
  }

  if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
    return <p>You are not authorised to view this task.</p>;
  }

  return (
    <InternalTaskDetailsPage
      setRefreshTaskData={setRefreshTaskData}
      taskData={taskData}
      targetSheet={targetSheet}
      getTaskWithJustification={getTaskWithJustification}
      toCreateJustification={() => {
        setIsLoading(true);
        JustificationUtil.toJustificationUI();
      }}
      hasEab={hasEab}
      borderEvent={borderEvent}
    />
  );
};

export default TaskDetailsPage;
