// Config
import { DIRECTION } from '../../../../../utils/TargetInformation/constants';

// Util(s)
import DateTimeUtil from '../../../../../utils/Datetime/Uplift/datetimeUtil';
import JourneyUtil from '../../../../../utils/Journey/Uplift/journeyUtil';
import { getMaskedValue } from '../../../../../utils/Masking/maskingUtil';

// eslint-disable-next-line no-unused-vars
export const toEventTime = (datetime, location, timeFormat) => {
  /**
   * TODO:
   * Remove the commented out line and update all function that invoke this
   * function if it turns out that the times we are passed from cerberus is already in local date.
   */
  // return DateTimeUtil.local.format(datetime, DateTimeUtil.iataTimezone(location), timeFormat);
  return DateTimeUtil.format.asUTC(datetime, null, timeFormat);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toTimeZone = (direction) => {
  return (departureLocation) => {
    return (arrivalLocation) => {
      let timezone = null;

      if (!direction) {
        return null;
      }

      if (direction === DIRECTION.OUTBOUND) {
        timezone = DateTimeUtil.timezone(departureLocation);
      }

      timezone = DateTimeUtil.timezone(arrivalLocation);
      return timezone;
    };
  };
};

const toEventDate = (journey) => {
  const direction = JourneyUtil.direction(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);

  if (!direction || !departureLocation || !departureTime || !arrivalLocation || !arrivalTime) {
    return null;
  }

  if ([direction, departureLocation, departureTime, arrivalLocation, arrivalTime].includes(getMaskedValue())) {
    return getMaskedValue();
  }

  if (direction === DIRECTION.OUTBOUND) {
    return DateTimeUtil.asDate(departureTime).toDate();
  }

  return DateTimeUtil.asDate(arrivalTime).toDate();
};

export default toEventDate;
