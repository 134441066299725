// Config(s)
import {
  GROUP_MEMBER,
  LOCAL_STORAGE_KEYS,
  MOVEMENT_MODES,
  PATHS,
  ROLE,
  TARGET_STATUS,
  TEXT_DEFAULTS,
  VIEW,
} from '../../../utils/constants';
import { SORT_ORDER } from '../../Task/TaskList/shared/constants';
import { SYSTEM_CHECKS } from '../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/checks/constants';

export const TAB_STATUS = {
  INCOMING: 'incoming',
  RECEIPTED: 'receipted',
  CAPTURED: 'captured',
  COMPLETE: 'complete',
  ASSIGNED: 'assigned',
  ACCEPTED: 'accepted',
  NEW: 'new',
  WITHDRAWN: 'withdrawn',
};

export const GROUP_DROPDOWN_LABELS = {
  AIRPAX: 'AirPax',
  RORO: 'RoRo',
};

export const GROUP_EXTRACTOR = {
  AIRPAX: 'airPaxFrontLineGroups',
  RCCU: 'rccuGroups',
  RORO: 'roRoFrontLineGroups',
};

export const TIME_FORMATS = {
  DATE: 'DD MMM YYYY',
  TIME: 'HH:mm',
  FULL: 'DD MMM YYYY [at] HH:mm',
};

export const SYSTEM_CHECKS_LABELS = {
  [SYSTEM_CHECKS.ATLAS]: 'Atlas',
  [SYSTEM_CHECKS.CRS]: 'CRS',
  [SYSTEM_CHECKS.DDA]: 'DDA',
  [SYSTEM_CHECKS.DVA]: 'DVA',
  [SYSTEM_CHECKS.INTERPOL]: 'i24/7 (Interpol)',
  [SYSTEM_CHECKS.PEGA]: 'PEGA',
  [SYSTEM_CHECKS.ENTITY_SEARCH]: 'Entity search',
  [SYSTEM_CHECKS.HMRC]: 'HMRC',
  [SYSTEM_CHECKS.SIP]: 'SIP',
  [SYSTEM_CHECKS.OPEN_SOURCE]: 'Open source',
  [SYSTEM_CHECKS.CENTAUR]: 'Centaur',
  [SYSTEM_CHECKS.OTHER]: 'Other',
};

export const SORT_PARAMS = {
  EVENT_TIME: 'EVENT_TIME',
  ARRIVAL_TIME: 'ARRIVAL_TIME',
  SELECTOR_CATEGORY: 'SELECTOR_CATEGORY',
  JOURNEY_DIRECTION: 'JOURNEY_DIRECTION',
};

export const PAGE_ACCESS_CONTROL = {
  [GROUP_MEMBER.BOTH]: [PATHS.RORO, PATHS.AIRPAX],
  [GROUP_MEMBER.RORO]: [PATHS.RORO],
  [GROUP_MEMBER.AIRPAX]: [PATHS.AIRPAX],
  [GROUP_MEMBER.NONE]: [],
};

export const PATHS_MODES_ASSOC = {
  [PATHS.RORO]: [
    MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
    MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
    MOVEMENT_MODES.TOURIST,
  ],
  [PATHS.AIRPAX]: [MOVEMENT_MODES.AIR_PASSENGER],
};

const TABLE_HEADERS = {
  roro: [
    '',
    'Mode of transport',
    'Manufacturer',
    'Vehicle & trailer registration',
    "Driver's last name",
    'Category',
    'Warnings',
    'Targeter actions',
  ],
  airpax: [
    '',
    'Last name',
    'Document number',
    'Date of birth',
    'Nationality',
    'Category',
    'Warnings',
  ],
  options: {
    outcome: 'Outcome',
  },
};

export const GROUP_DROPDOWN_OPTIONS = [
  {
    defaultTab: TAB_STATUS.ASSIGNED,
    label: GROUP_DROPDOWN_LABELS.RORO,
    show: {
      enabled: true,
      groupMember: [GROUP_MEMBER.RORO, GROUP_MEMBER.BOTH],
    },
    targetsListPath: PATHS.TARGETS.RORO,
    value: VIEW.RORO_IO,
    view: VIEW.RORO_IO,
  },
  {
    defaultTab: TAB_STATUS.INCOMING,
    label: GROUP_DROPDOWN_LABELS.AIRPAX,
    show: {
      role: ROLE.RCCU,
      groupMember: [GROUP_MEMBER.AIRPAX, GROUP_MEMBER.BOTH],
    },
    targetsListPath: PATHS.TARGETS.AIRPAX,
    value: VIEW.AIRPAX_RCCU,
    view: VIEW.AIRPAX_RCCU,
  },
  {
    defaultTab: TAB_STATUS.INCOMING,
    label: GROUP_DROPDOWN_LABELS.AIRPAX,
    show: {
      role: ROLE.HIGHER_OFFICER,
      groupMember: [GROUP_MEMBER.AIRPAX, GROUP_MEMBER.BOTH],
    },
    targetsListPath: PATHS.TARGETS.AIRPAX,
    value: VIEW.AIRPAX_HO,
    view: VIEW.AIRPAX_HO,
  },
  {
    defaultTab: TAB_STATUS.ASSIGNED,
    label: GROUP_DROPDOWN_LABELS.AIRPAX,
    show: {
      role: ROLE.INTERCEPTING_OFFICER,
      groupMember: [GROUP_MEMBER.AIRPAX, GROUP_MEMBER.BOTH],
    },
    targetsListPath: PATHS.TARGETS.AIRPAX,
    value: VIEW.AIRPAX_IO,
    view: VIEW.AIRPAX_IO,
  },
];

export const SORT_ORDER_DROPDOWN_OPTIONS = [
  {
    label: 'Soonest first',
    value: SORT_ORDER.ASC,
  },
  {
    label: 'Latest first',
    value: SORT_ORDER.DESC,
  },
];

const DEFAULTS = {
  [VIEW.RORO_IO]: {
    dropDownDefault: GROUP_MEMBER.RORO,
    filters: {
      key: LOCAL_STORAGE_KEYS.RORO_TARGETS_FILTERS,
      default: {
        statuses: [TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED],
        groupCodes: [],
        journeyDirections: [],
      },
      movementModes: [
        MOVEMENT_MODES.TOURIST,
        MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
        MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
      ],
    },
    groupExtractors: [GROUP_EXTRACTOR.RORO],
    tabs: [
      {
        id: TAB_STATUS.ASSIGNED,
        label: TEXT_DEFAULTS.TEXT.INCOMING_TARGETS,
        heading: TEXT_DEFAULTS.TEXT.INCOMING_TARGETS,
        statuses: [TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.roro,
      },
      {
        id: TAB_STATUS.CAPTURED,
        label: 'Captured targets',
        heading: 'Captured targets',
        statuses: [TARGET_STATUS.CAPTURED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.roro,
      },
      {
        id: TAB_STATUS.WITHDRAWN,
        label: 'Withdrawn targets',
        heading: 'Withdrawn targets',
        statuses: [TARGET_STATUS.WITHDRAWN],
        displayCount: false,
        tableHeaders: TABLE_HEADERS.roro,
      },
    ],
    redirectPath: PATHS.TARGETS.RORO,
    filterByGroupCodes: true,
    filterByAssignees: false,
  },
  [VIEW.AIRPAX_IO]: {
    dropDownDefault: GROUP_MEMBER.AIRPAX,
    filters: {
      key: LOCAL_STORAGE_KEYS.AIRPAX_TARGET_FILTERS,
      default: {
        statuses: [TARGET_STATUS.ASSIGNED],
        groupCodes: [],
        journeyDirections: [],
      },
      movementModes: [MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION],
    },
    groupExtractors: [GROUP_EXTRACTOR.AIRPAX, GROUP_EXTRACTOR.RCCU],
    tabs: [
      {
        id: TAB_STATUS.ASSIGNED,
        label: 'Assigned',
        heading: 'Targets assigned to you',
        statuses: [TARGET_STATUS.INCOMING, TARGET_STATUS.RECEIPTED, TARGET_STATUS.ASSIGNED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.ACCEPTED,
        label: 'Accepted',
        heading: 'Targets accepted by you',
        statuses: [TARGET_STATUS.ACCEPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.COMPLETE,
        label: 'Completed',
        heading: 'Targets completed by you',
        statuses: [TARGET_STATUS.COMPLETE],
        displayCount: true,
        tableHeaders: [...TABLE_HEADERS.airpax, TABLE_HEADERS.options.outcome],
      },
      {
        id: TAB_STATUS.WITHDRAWN,
        label: 'Withdrawn',
        heading: TEXT_DEFAULTS.TEXT.TARGETS_WITHDRAWN,
        statuses: [TARGET_STATUS.WITHDRAWN],
        displayCount: false,
        tableHeaders: TABLE_HEADERS.airpax,
      },
    ],
    redirectPath: PATHS.TARGETS.AIRPAX,
    filterByAssignees: true,
    filterByGroupCodes: false,
    notifyOn: {
      [TAB_STATUS.ACCEPTED]: false,
    },
  },
  [VIEW.AIRPAX_HO]: {
    dropDownDefault: GROUP_MEMBER.AIRPAX,
    filters: {
      key: LOCAL_STORAGE_KEYS.AIRPAX_TARGET_FILTERS,
      default: {
        statuses: [TARGET_STATUS.INCOMING],
        groupCodes: [],
        journeyDirections: [],
      },
      movementModes: [MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION],
    },
    groupExtractors: [GROUP_EXTRACTOR.AIRPAX, GROUP_EXTRACTOR.RCCU],
    tabs: [
      {
        id: TAB_STATUS.INCOMING,
        label: 'Incoming',
        heading: TEXT_DEFAULTS.TEXT.TARGETS_RECEIVED_FROM_NBTC,
        statuses: [TARGET_STATUS.INCOMING],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.RECEIPTED,
        label: 'Receipted',
        heading: 'Receipted targets',
        statuses: [TARGET_STATUS.RECEIPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.ASSIGNED,
        label: 'Assigned',
        heading: 'Assigned targets',
        statuses: [TARGET_STATUS.ASSIGNED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.ACCEPTED,
        label: 'Accepted',
        heading: 'Accepted targets',
        statuses: [TARGET_STATUS.ACCEPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.COMPLETE,
        label: 'Completed',
        heading: 'Completed targets',
        statuses: [TARGET_STATUS.COMPLETE],
        displayCount: true,
        tableHeaders: [...TABLE_HEADERS.airpax, TABLE_HEADERS.options.outcome],
      },
      {
        id: TAB_STATUS.WITHDRAWN,
        label: 'Withdrawn',
        heading: TEXT_DEFAULTS.TEXT.TARGETS_WITHDRAWN,
        statuses: [TARGET_STATUS.WITHDRAWN],
        displayCount: false,
        tableHeaders: TABLE_HEADERS.airpax,
      },
    ],
    redirectPath: PATHS.TARGETS.AIRPAX,
    filterByAssignees: true,
    filterByGroupCodes: true,
    notifyOn: {
      [TAB_STATUS.ACCEPTED]: false,
      [TAB_STATUS.ASSIGNED]: false,
      [TAB_STATUS.RECEIPTED]: false,
    },
  },
  [VIEW.AIRPAX_RCCU]: {
    dropDownDefault: GROUP_MEMBER.AIRPAX,
    filters: {
      key: LOCAL_STORAGE_KEYS.AIRPAX_TARGET_FILTERS,
      default: {
        statuses: [TARGET_STATUS.RCCU_INCOMING],
        groupCodes: [],
        journeyDirections: [],
        targetsBy: 'UPCOMING',
      },
      movementModes: [MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION],
    },
    groupExtractors: [GROUP_EXTRACTOR.AIRPAX, GROUP_EXTRACTOR.RCCU],
    tabs: [
      {
        id: TAB_STATUS.INCOMING,
        label: 'Incoming',
        heading: TEXT_DEFAULTS.TEXT.TARGETS_RECEIVED_FROM_NBTC,
        statuses: [TARGET_STATUS.RCCU_INCOMING],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.RECEIPTED,
        label: 'Receipted',
        heading: 'Receipted targets',
        statuses: [TARGET_STATUS.RCCU_RECEIPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.ASSIGNED,
        label: 'Assigned',
        heading: 'Assigned targets',
        statuses: [TARGET_STATUS.INCOMING, TARGET_STATUS.RECEIPTED, TARGET_STATUS.ASSIGNED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.ACCEPTED,
        label: 'Accepted',
        heading: 'Accepted targets',
        statuses: [TARGET_STATUS.ACCEPTED],
        displayCount: true,
        tableHeaders: TABLE_HEADERS.airpax,
      },
      {
        id: TAB_STATUS.COMPLETE,
        label: 'Completed',
        heading: 'Completed targets',
        statuses: [TARGET_STATUS.COMPLETE],
        displayCount: true,
        tableHeaders: [...TABLE_HEADERS.airpax, TABLE_HEADERS.options.outcome],
      },
      {
        id: TAB_STATUS.WITHDRAWN,
        label: 'Withdrawn',
        heading: TEXT_DEFAULTS.TEXT.TARGETS_WITHDRAWN,
        statuses: [TARGET_STATUS.WITHDRAWN],
        displayCount: false,
        tableHeaders: TABLE_HEADERS.airpax,
      },
    ],
    redirectPath: PATHS.TARGETS.AIRPAX,
    filterByAssignees: false,
    filterByGroupCodes: true,
    notifyOn: {
      [TAB_STATUS.ACCEPTED]: false,
      [TAB_STATUS.ASSIGNED]: false,
      [TAB_STATUS.RECEIPTED]: false,
    },
  },
};

export default DEFAULTS;
