import { concat, difference, every, isEqual, omit, some } from 'lodash';

const inverseBoolean = (bool) => !bool;

/**
 * Data ID is present on original credibility checks but not the new.
 * Stripping out so that it doesn't affect the equality comparison
 * @param credibilityCheck
 * @returns {*}
 */
const removeDataId = (credibilityCheck) => ({
  ...credibilityCheck,
  otherChecks: credibilityCheck.otherChecks ? {
    ...credibilityCheck.otherChecks,
    OTHER: credibilityCheck.otherChecks?.OTHER?.map((other) => omit(other, 'dataId')),
  } : null,
});

const removeDataIdAndHmrcAndChecksList = (credibilityCheck) => {
  return removeDataId({
    ...credibilityCheck,
    otherChecks: {
      ...omit(credibilityCheck.otherChecks, ['checks', 'HMRC']),
    },
  });
};

const getHasCredibilityCheckChanged = (originalCredibilityCheck, newCredibilityCheck) => {
  return !isEqual(removeDataId(originalCredibilityCheck), removeDataId(newCredibilityCheck));
};

const getHasCheckChanged = (originalCredibilityChecks, newCredibilityChecks) => {
  return Object.values(originalCredibilityChecks).map((originalCredibilityCheck, index) => {
    const newCredibilityCheck = Object.values(newCredibilityChecks)[index];
    return getHasCredibilityCheckChanged(originalCredibilityCheck, newCredibilityCheck);
  });
};

/**
 * Calculate if all credibility checks across passengers have remained unchanged
 * @param originalCredibilityChecks
 * @param newCredibilityChecks
 * @returns {boolean}
 */
const getHasAllChecksNotChanged = (originalCredibilityChecks, newCredibilityChecks) => {
  return every(getHasCheckChanged(originalCredibilityChecks, newCredibilityChecks), inverseBoolean);
};

const hasOnlyHmrcChanged = (originalCredibilityCheck, newCredibilityCheck) => {
  const isOtherChecksEqualBarHmrc = isEqual(removeDataIdAndHmrcAndChecksList(originalCredibilityCheck).otherChecks, removeDataIdAndHmrcAndChecksList(newCredibilityCheck).otherChecks);
  const isHmrcChanged = !isEqual(originalCredibilityCheck.otherChecks?.HMRC, newCredibilityCheck.otherChecks?.HMRC);
  const isPncEqual = isEqual(originalCredibilityCheck.pnc, newCredibilityCheck.pnc);
  const checksAddedOrRemoved = concat(
    // Checks removed
    difference(originalCredibilityCheck.otherChecks?.checks, newCredibilityCheck.otherChecks?.checks),
    // Checks added
    difference(newCredibilityCheck.otherChecks?.checks, originalCredibilityCheck.otherChecks?.checks),
  );

  // For only HMRC to have changed then following must be true:
  // PNC must be equal
  return isPncEqual
    // All other check information bar HMRC must be equal
    && isOtherChecksEqualBarHmrc
    // Lastly one of the following two must be true
    && (
      // HMRC check information is changed and no other checks were added or removed
      (isHmrcChanged && checksAddedOrRemoved.length === 0)
      // Only HMRC was either added or removed
      || (checksAddedOrRemoved.length === 1 && checksAddedOrRemoved[0] === 'HMRC')
    );
};

/**
 * First we check for which credibility checks only have HMRC change
 * Then for all others we must ensure that no change has occurred
 * If some have only HMRC change and the rest have no change, then return true
 * @param originalCredibilityChecks
 * @param newCredibilityChecks
 * @returns {boolean}
 */
const getHasOnlyHmrcChangedAcrossAll = (originalCredibilityChecks, newCredibilityChecks) => {
  const hmrcChangePerCredibilityCheck = Object.values(originalCredibilityChecks).map((originalCredibilityCheck, index) => {
    const newCredibilityCheck = Object.values(newCredibilityChecks)[index];
    return hasOnlyHmrcChanged(originalCredibilityCheck, newCredibilityCheck);
  });

  const hasAnyChangePerCredibilityCheck = getHasCheckChanged(originalCredibilityChecks, newCredibilityChecks);
  return some(hmrcChangePerCredibilityCheck, Boolean)
    && every(
      hasAnyChangePerCredibilityCheck.filter(
        // filter for credibility checks where hmrcChangePerCredibilityCheck is false
        (_, i) => hmrcChangePerCredibilityCheck.reduce((acc, bool, index) => (!bool ? [...acc, index] : acc), []).includes(i)),
      inverseBoolean,
    );
};

const PassengerCredibilityChecksUtil = {
  hasAllChecksNotChanged: getHasAllChecksNotChanged,
  hasOnlyHmrcChangedAcrossAll: getHasOnlyHmrcChangedAcrossAll,
};

export default PassengerCredibilityChecksUtil;

export {
  getHasAllChecksNotChanged,
  getHasOnlyHmrcChangedAcrossAll,
};
