import PersonUtil from '../personUtil';

// Config(s)
import { CONTENT_TYPE } from '../../constants';

const getPassengerStatus = (person) => {
  const status = person?.passengerStatus ?? undefined;
  switch (status) {
    case 'CHECKED_IN': {
      return 'Checked in';
    }
    case 'DEPARTURE_CONFIRMED': {
      return 'Departure confirmed';
    }
    default:
      return 'Status not available';
  }
};

const getAdditionalPhotoContent = (entity) => {
  const additions = [...new Set(entity?.additionalContent)];
  return additions.find((addition) => addition.type === CONTENT_TYPE.PHOTO) || null;
};

const getAdditionalPhotoContents = (entity) => {
  const additions = [...new Set(entity?.additionalContents)];
  return additions.find((addition) => addition.type === CONTENT_TYPE.PHOTO) || null;
};

const getImageUrl = (additionalContent) => {
  return additionalContent?.url || null;
};

const getGenderNode = (person) => {
  return person?.gender || undefined;
};

const getFormattedGender = (person) => {
  if (!person) {
    return undefined;
  }
  return getGenderNode(person)?.name || undefined;
};

const getNationalityNode = (person) => {
  return person?.nationality || undefined;
};

const getNationality = (person) => {
  return person?.nationality?.nationality || undefined;
};

const getNationalityCountryIso2 = (person) => {
  return person?.nationality?.iso31661alpha2 || undefined;
};

const getNationalityCountryIso3 = (person) => {
  return person?.nationality?.iso31661alpha3 || undefined;
};

const getDocumentNumber = (document) => {
  return document?.number || undefined;
};

const getDob = (person) => {
  return person?.dateOfBirth || undefined;
};

const getDocument = (person) => {
  return person?.document || undefined;
};

const getDocuments = (person) => {
  return person?.documents || undefined;
};

const getFirstName = (person) => {
  return person?.name?.first || undefined;
};

const getLastName = (person) => {
  return person?.name?.last || undefined;
};

const getFullName = (person) => {
  return person?.name?.full || undefined;
};

const getOthers = (informationSheet) => {
  return informationSheet?.movement?.otherPersons ?? [];
};

const getPersons = (informationSheet) => {
  return informationSheet?.movement?.personsOfInterest ?? [];
};

const getAllPersons = (informationSheet) => {
  return [...getPersons(informationSheet), ...getOthers(informationSheet)].filter((val) => !!val);
};

const getWatchListAdded = (person) => {
  return person?.watchList?.added ? 'Yes' : 'No';
};

const getWatchListReferenceNumber = (person) => {
  return person?.watchList?.referenceNumber || 'Not provided';
};

const getSeatNumber = (person) => {
  return person?.seatNumber || undefined;
};

const getPersonsOfInterest = (person) => {
  return person?.ofInterest || null;
};

const getPersonRole = (person) => {
  return person?.role || null;
};

const getAllPersonsOnWhitelist = (informationSheet) => {
  const allPersons = getAllPersons(informationSheet);
  const allWatchedPersons = allPersons.map((a) => (a.watchList?.added ? 'Yes' : 'No')).filter((a) => a === 'Yes');
  return allPersons.length === allWatchedPersons.length;
};

const getAllPersonsOfInterest = (informationSheet) => {
  return getPersons(informationSheet);
};

const getCoTravellers = (informationSheet) => {
  return getOthers(informationSheet);
};

const getUnder18s = (informationSheet) => {
  const allPersons = getAllPersons(informationSheet);
  const ages = allPersons.reduce((acc, curr) => {
    if (PersonUtil.age(curr) < 18) {
      /* eslint-disable-next-line no-plusplus */
      acc++;
    }
    return acc;
  }, 0);
  return ages;
};

const getRoRoOthers = (informationSheet) => {
  const others = [informationSheet?.movement?.consignee, informationSheet?.movement?.consignor, informationSheet?.movement?.haulier];
  return others;
};

const TargetPersonUtil = {
  additionalPhotoContent: getAdditionalPhotoContent,
  additionalPhotoContents: getAdditionalPhotoContents,
  allOnWhitelist: getAllPersonsOnWhitelist,
  allOfInterest: getAllPersonsOfInterest,
  coTravellers: getCoTravellers,
  document: getDocument,
  documents: getDocuments,
  docNumber: getDocumentNumber,
  dob: getDob,
  firstName: getFirstName,
  formattedGender: getFormattedGender,
  fullName: getFullName,
  genderNode: getGenderNode,
  getAll: getAllPersons,
  getRoRoOthers,
  imageUrl: getImageUrl,
  lastName: getLastName,
  nationality: getNationality,
  nationalityCountryIso2: getNationalityCountryIso2,
  nationalityCountryIso3: getNationalityCountryIso3,
  nationalityNode: getNationalityNode,
  ofInterest: getPersonsOfInterest,
  others: getOthers,
  person: getPersons,
  persons: getPersons,
  passengerStatus: getPassengerStatus,
  role: getPersonRole,
  watchList: {
    added: getWatchListAdded,
    referenceNumber: getWatchListReferenceNumber,
  },
  seatNumber: getSeatNumber,
  under18s: getUnder18s,
};

export default TargetPersonUtil;

export {
  getAdditionalPhotoContent,
  getAdditionalPhotoContents,
  getAllPersonsOnWhitelist,
  getAllPersonsOfInterest,
  getCoTravellers,
  getDocument,
  getDocuments,
  getDocumentNumber,
  getDob,
  getFirstName,
  getFormattedGender,
  getFullName,
  getGenderNode,
  getAllPersons,
  getImageUrl,
  getLastName,
  getNationality,
  getNationalityCountryIso2,
  getNationalityCountryIso3,
  getNationalityNode,
  getOthers,
  getPersons,
  getPersonsOfInterest,
  getPersonRole,
  getWatchListAdded,
  getWatchListReferenceNumber,
  getSeatNumber,
  getUnder18s,
};
