// Config(s)
import { DIRECTION, INFORM_FREIGHT_AND_TOURIST, PNC_FIELDS, PNC_OUTCOMES, RORO_MODES } from '../constants';
import { STRINGS, VIEW } from '../../constants';

// Util(s)
import { booleanToString, replaceInvalidValues } from '../../String/stringUtil';
import { toControlStrategies, toId, toIssuingHub, toOperation, toTargetReceiptTeam } from './common';
import toValueAndLabel from './toValueAndLabel';
import AccountUtil from '../../Account/accountUtil';
import adaptTypeToLabel from './adaptNominalLabel';
import ConsigneeUtil from '../../Goods/consigneeUtil';
import ConsignorUtil from '../../Goods/consignorUtil';
import CredibilityChecksUtil from '../../Person/credibilityChecksUtil';
import DateTimeUtil from '../../Datetime/datetimeUtil';
import DocumentUtil from '../../Document/documentUtil';
import GoodsUtil from '../../Goods/goodsUtil';
import HaulierUtil from '../../Haulier/haulierUtil';
import MovementUtil from '../../Movement/movementUtil';
import JourneyUtil from '../../Journey/journeyUtil';
import PersonUtil from '../../Person/personUtil';
import RisksUtil from '../../Risks/risksUtil';
import ValidateRefData from '../validate/validateRefData';
import VesselUtil from '../../Vessel/vesselUtil';

const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:mm';

const toWhySelected = (formData) => {
  if (!formData?.selectionReasoning) {
    return null;
  }

  return {
    whySelected: replaceInvalidValues(formData.selectionReasoning),
  };
};

const toSelectorsSummary = (formData) => {
  if (!formData) {
    return null;
  }

  return {
    selectorsSummary: formData?.risks?.selectors?.map((selector) => {
      return {
        ...selector,
        warning: selector?.warning?.types || [],
      };
    }),
  };
};

const toAdditionalInformation = (formData) => {
  if (!formData?.additionalInformation) {
    return null;
  }

  return {
    additionalInformation: replaceInvalidValues(formData.additionalInformation),
  };
};

const toInformFreightAndTourist = (formData) => {
  let informFreightAndTourist = [];

  if (formData?.recipients?.informFreightAndTourist === true) {
    informFreightAndTourist = [INFORM_FREIGHT_AND_TOURIST];
  }

  return { informFreightAndTourist };
};

const toNominalChecks = (formData) => {
  if (!formData?.nominalChecks?.length) {
    return null;
  }

  return {
    nominalChecks: formData?.nominalChecks.map((nominalCheck) => {
      return {
        id: nominalCheck?.id || Date.now().toString(),
        nominalType: toValueAndLabel(
          replaceInvalidValues(nominalCheck?.type),
          adaptTypeToLabel(nominalCheck.type),
        ),
        systemsCheck: nominalCheck.checks.map((check) => {
          return {
            ...check,
            ...toValueAndLabel(check?.id, replaceInvalidValues(check?.name)),
          };
        }),
        comments: replaceInvalidValues(nominalCheck?.comments),
      };
    }),
  };
};

const toRemarks = (formData) => {
  if (!formData?.remarks) {
    return null;
  }

  return {
    remarks: formData?.remarks,
  };
};

const toPublicInterestImmunity = (formData) => {
  if (formData?.publicInterestImmunity == null) {
    return {
      publicInterestImmunity: 'Yes',
    };
  }

  return {
    publicInterestImmunity: formData?.publicInterestImmunity ? 'Yes' : 'No',
  };
};

const toGroupReference = (formData) => {
  const risks = RisksUtil.getRisks(formData);
  const selector = risks?.selector;
  if (!selector) {
    return null;
  }

  return {
    groupReference: replaceInvalidValues(selector?.groupReference),
  };
};

const toCategory = (formData) => {
  const risks = RisksUtil.getRisks(formData);
  if (!risks?.selector) {
    return null;
  }

  const category = risks?.selector?.category;
  if (!category) {
    return null;
  }

  return {
    category: {
      ...toValueAndLabel(category, category),
    },
  };
};

const toTargetingIndicators = (formData) => {
  const risks = RisksUtil.getRisks(formData);
  const targetingIndicators = RisksUtil.targetingIndicators(risks);
  if (!targetingIndicators?.length) {
    return null;
  }

  return {
    targetingIndicators: targetingIndicators.map((ti) => {
      if (ValidateRefData.validate(ti, toValueAndLabel('id', 'userfacingtext'))) {
        return {
          ...ti,
          ...toValueAndLabel(ti.id, ti.userfacingtext),
        };
      }
      return null;
    }).filter((ti) => !!ti),
  };
};

const toReasoning = (formData) => {
  if (!formData?.selectionReasoning) {
    return null;
  }

  return {
    selectionReasoning: replaceInvalidValues(formData?.selectionReasoning),
  };
};

const toPncDefaultIfRequired = (view) => {
  if (view !== VIEW.RORO) {
    return null;
  }

  return {
    pncOutcome: PNC_OUTCOMES.INSUFFICIENT_TIME,
  };
};

const toRoRoPncChecks = (person) => {
  const pnc = CredibilityChecksUtil.getPnc(person);
  const categories = pnc?.categories;

  return ({
    pncOutcome: person?.pncResult?.outcome || 'INSUFFICIENT_TIME',
    pncFound: {
      pncId: person?.pncResult?.found?.pncId,
      safetyInformation: person?.pncResult?.found?.safetyInformation ? 'Yes' : 'No',
    },
    pncDetails: person?.pncResult?.details,
    pncCategories: {
      ...(categories?.drugsPossession?.length && {
        drugsPossession: [PNC_FIELDS.DRUGS],
        possessionClass: categories?.drugsPossession,
      }),
      ...(categories?.drugsSupply?.length && {
        drugsSupply: [PNC_FIELDS.SUPPLY],
        supplyClass: categories?.drugsSupply,
      }),
      ...(categories?.drugsTrafficking?.length && {
        drugsTrafficking: [PNC_FIELDS.TRAFFICKING],
        traffickingClass: categories?.drugsTrafficking,
      }),
      ...(categories?.weaponsPossession?.length && {
        weaponsPossession: [PNC_FIELDS.POSSESSION],
        weaponsPossessionOpts: categories?.weaponsPossession,
      }),
      ...(categories?.weaponsSupply?.length && {
        weaponsSupply: [PNC_FIELDS.SUPPLY],
        weaponsSupplyOptions: categories?.weaponsSupply,
      }),
      ...(categories?.goodsSmuggling?.length && {
        goodsSmuggling: [PNC_FIELDS.GOODS_SMUGGLING],
        goodsSmugglingOpts: categories?.goodsSmuggling,
      }),
      ...(categories?.peopleSmuggling?.length && {
        peopleSmuggling: [PNC_FIELDS.PEOPLE_SMUGGLING],
        peopleSmugglingOpts: categories?.peopleSmuggling,
      }),
    },
  });
};

const toPoliceNationalComputerCheck = (person, view) => {
  const pnc = CredibilityChecksUtil.getPnc(person);
  const pncResult = pnc?.result;

  if (!pncResult?.outcome) {
    return toPncDefaultIfRequired(view);
  }

  const { outcome, found, details, categories } = pncResult;
  return {
    pncOutcome: outcome,
    ...(outcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND && {
      pncFound: {
        pncId: found?.pncId,
        safetyInformation: found.safetyInformation ? STRINGS.YES : STRINGS.NO,
      },
      ...(found.safetyInformation && {
        pncDetails: {
          ...(details.types && details?.types?.length && {
            types: details.types,
          }),
          ...(details?.types && details?.types.includes(PNC_FIELDS.STAFF_CONCERN) && {
            staffConcerns: details?.staffConcerns,
          }),
          ...(details?.types && details?.types.includes(PNC_FIELDS.POI_CONCERN) && {
            poiConcerns: details?.poiConcerns,
          }),
        },
      }),
    }),
    ...(outcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND && {
      pncCategories: {
        ...(categories?.drugsPossession && categories?.drugsPossession?.length && {
          drugsPossession: [PNC_FIELDS.DRUGS],
          possessionClass: categories?.drugsPossession,
        }),
        ...(categories?.drugsSupply && categories?.drugsSupply?.length && {
          drugsSupply: [PNC_FIELDS.SUPPLY],
          supplyClass: categories?.drugsSupply,
        }),
        ...(categories?.drugsTrafficking && categories?.drugsTrafficking?.length && {
          drugsTrafficking: [PNC_FIELDS.TRAFFICKING],
          traffickingClass: categories?.drugsTrafficking,
        }),
        ...(categories?.weaponsPossession && categories?.weaponsPossession?.length && {
          weaponsPossession: [PNC_FIELDS.POSSESSION],
          weaponsPossessionOpts: categories?.weaponsPossession,
        }),
        ...(categories?.weaponsSupply && categories?.weaponsSupply?.length && {
          weaponsSupply: [PNC_FIELDS.SUPPLY],
          weaponsSupplyOptions: categories?.weaponsSupply,
        }),
        ...(categories?.goodsSmuggling && categories?.goodsSmuggling?.length && {
          goodsSmuggling: [PNC_FIELDS.GOODS_SMUGGLING],
          goodsSmugglingOpts: categories?.goodsSmuggling,
        }),
        ...(categories?.peopleSmuggling && categories?.peopleSmuggling?.length && {
          peopleSmuggling: [PNC_FIELDS.PEOPLE_SMUGGLING],
          peopleSmugglingOpts: categories?.peopleSmuggling,
        }),
      },
    }),
  };
};

const toAddedToWatchList = (person) => {
  const watchList = person?.watchList;

  if (!watchList) {
    return {
      watchList: STRINGS.NO.toUpperCase(),
    };
  }

  return {
    watchList: booleanToString(watchList.added).toUpperCase(),
    individualReferenceNumber: replaceInvalidValues(watchList.referenceNumber),
  };
};

const toPerson = (person, document, baggage, view, index = 0) => {
  if (!person) {
    return null;
  }

  return {
    id: `${Date.now().toString()}${index}`,
    name: { ...person?.name },
    dateOfBirth: replaceInvalidValues(DateTimeUtil.format(person?.dateOfBirth, dateFormat)),
    ...(ValidateRefData.validate(person?.nationality, toValueAndLabel('id', 'nationality')) && {
      nationality: {
        ...person?.nationality,
        ...toValueAndLabel(
          person?.nationality?.id,
          person?.nationality?.nationality,
        ),
      },
    }),
    ...(ValidateRefData.validate(person?.gender, toValueAndLabel('id', 'name')) && {
      sex: {
        ...person?.gender,
        ...toValueAndLabel(
          person?.gender?.id,
          person?.gender?.name,
        ),
      },
    }),
    document: {
      ...(ValidateRefData.validate(document?.type, toValueAndLabel('id', 'shortdescription')) && {
        type: {
          ...document?.type,
          ...toValueAndLabel(
            document?.type?.id,
            document?.type?.shortdescription,
          ),
        },
      }),
      documentNumber: replaceInvalidValues(person?.document?.number),
      documentExpiry: replaceInvalidValues(DateTimeUtil.format(person?.document?.expiry, dateFormat)),
      issueCountry: {
        ...toValueAndLabel(
          document?.issueCountry?.isoCode,
          document?.issueCountry?.name,
        ),
      },
    },
    ...toPoliceNationalComputerCheck(person, view),
    ...toAddedToWatchList(person),
    passengerStatus: PersonUtil.passengerStatus(person),
    seatNumber: replaceInvalidValues(person?.seatNumber),
    ...(baggage && {
      baggage: {
        bagCount: replaceInvalidValues(baggage?.numberOfCheckedBags),
        weight: replaceInvalidValues(baggage?.weight),
        tags: replaceInvalidValues(baggage?.tags),
      },
    }),
  };
};

const toOtherPersons = (data, view) => {
  const othersPersons = PersonUtil.getOthers(data);
  if (!othersPersons?.length) {
    return null;
  }

  return {
    otherPersons: othersPersons.map((person, index) => {
      const document = DocumentUtil.get(person);
      const baggage = person?.baggage;
      return toPerson(person, document, baggage, view, index + 1);
    }),
  };
};

const toMainPerson = (data, view) => {
  const person = PersonUtil.get(data);
  if (!person) {
    return null;
  }

  const baggage = person?.baggage;
  const document = DocumentUtil.get(person);
  return {
    person: {
      ...toPerson(person, document, baggage, view),
    },
  };
};

const toVehicle = (formData) => {
  if (!formData?.movement?.vehicle) {
    return null;
  }

  let vehicle = formData?.movement?.vehicle;
  if (!ValidateRefData.validate(vehicle?.nationality, toValueAndLabel('id', 'nationality'))) {
    delete vehicle.nationality;
  } else {
    vehicle = {
      ...vehicle,
      nationality: {
        ...vehicle.nationality,
        ...toValueAndLabel(
          vehicle.nationality.id,
          vehicle.nationality.nationality,
        ),
      },
    };
  }
  return {
    vehicle,
  };
};

const toTrailer = (formData) => {
  if (!formData?.movement?.trailer) {
    return null;
  }

  let trailer = formData?.movement?.trailer;
  if (!ValidateRefData.validate(trailer?.nationality, toValueAndLabel('id', 'nationality'))) {
    delete trailer.nationality;
  } else {
    trailer = {
      ...trailer,
      nationality: {
        ...trailer.nationality,
        ...toValueAndLabel(
          trailer.nationality.id,
          trailer.nationality.nationality,
        ),
      },
    };
  }
  return {
    trailer,
  };
};

const toGoods = (formData, view) => {
  const goods = GoodsUtil.get(formData);
  if (!goods) {
    return null;
  }

  const consignee = ConsigneeUtil.get(formData);
  const consigneeAddress = ConsigneeUtil.address(consignee);
  const consignor = ConsignorUtil.get(formData);
  const consignorAddress = ConsignorUtil.address(consignor);
  const haulier = HaulierUtil.get(formData);
  const haulierAddress = HaulierUtil.address(haulier);
  const hazardous = GoodsUtil.format.hazardous(GoodsUtil.hazardous(goods));
  const formattedManifestedWeight = GoodsUtil.format.weight(goods);
  const formattedGrossWeight = GoodsUtil.format.grossWeight(goods);
  const formattedNetWeight = GoodsUtil.format.netWeight(goods);

  /**
   * Looks at the users and determines if they should be available as
   * selected in the form based on formData.
   *
   * @returns {[string]} An array of strings corresponding to the
   * selection ids in the 'details are available for'
   */
  const getDetailsAvailable = () => {
    const users = [
      { consignee: formData?.movement?.consignee },
      { consignor: formData?.movement?.consignor },
      { haulier: formData?.movement?.haulier },
    ];
    const available = [];
    users?.forEach((u) => {
      const key = Object.keys(u)[0];
      if (u?.[key] === null) {
        return;
      }

      if (u?.[key]?.address !== null && Object.values(u?.[key]?.address)?.some((a) => a !== null)) {
        available.push(key.toString());
      }
    });
    return available;
  };

  return {
    goods: {
      load: replaceInvalidValues(goods?.description),
      manifestedWeight: replaceInvalidValues(formattedManifestedWeight),
      grossWeight: replaceInvalidValues(formattedGrossWeight),
      netWeight: replaceInvalidValues(formattedNetWeight),
      destinationCountry: goods?.destination,
      detailsAvailable: [
        ...(getDetailsAvailable()),
      ],
      consignor: {
        name: replaceInvalidValues(ConsignorUtil.name(consignor)),
        line1: replaceInvalidValues(consignorAddress?.line1),
        line2: replaceInvalidValues(consignorAddress?.line2),
        line3: replaceInvalidValues(consignorAddress?.line3),
        city: replaceInvalidValues(consignorAddress?.city),
        postcode: replaceInvalidValues(consignorAddress?.postcode),
        country: replaceInvalidValues(consignorAddress?.country),
        ...toRoRoPncChecks(consignor, view),
      },
      consignee: {
        name: replaceInvalidValues(ConsigneeUtil.name(consignee)),
        line1: replaceInvalidValues(consigneeAddress?.line1),
        line2: replaceInvalidValues(consigneeAddress?.line2),
        line3: replaceInvalidValues(consigneeAddress?.line3),
        city: replaceInvalidValues(consigneeAddress?.city),
        postcode: replaceInvalidValues(consigneeAddress?.postcode),
        country: replaceInvalidValues(consigneeAddress?.country),
        ...toRoRoPncChecks(consignee, view),
      },
      haulier: {
        name: replaceInvalidValues(HaulierUtil.name(haulier)),
        line1: replaceInvalidValues(haulierAddress?.line1),
        line2: replaceInvalidValues(haulierAddress?.line2),
        line3: replaceInvalidValues(haulierAddress?.line3),
        city: replaceInvalidValues(haulierAddress?.city),
        postcode: replaceInvalidValues(haulierAddress?.postcode),
        country: replaceInvalidValues(haulierAddress?.country),
        ...toRoRoPncChecks(haulier, view),
      },
      ...(hazardous && { hazardous: hazardous?.toUpperCase() }),
    },
  };
};

const toPreArrival = (formData) => {
  const account = AccountUtil.get(formData);
  return {
    preArrival: {
      accountName: replaceInvalidValues(AccountUtil.name(account)),
      accountNumber: replaceInvalidValues(AccountUtil.number(account)),
      whySelected: replaceInvalidValues(formData?.selectionReasoning),
    },
  };
};

const toInterception = (formData) => {
  const vessel = VesselUtil.get(formData);
  const journey = JourneyUtil.get(formData);
  return {
    interception: {
      vesselName: replaceInvalidValues(VesselUtil.name(vessel)),
      shippingCompany: replaceInvalidValues(VesselUtil.operator(vessel)),
      arrival: {
        date: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.arrivalTime(journey), dateFormat)),
        time: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.arrivalTime(journey), timeFormat)),
      },
      departure: {
        date: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.departureTime(journey), dateFormat)),
        time: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.departureTime(journey), timeFormat)),
      },
    },
  };
};

const toPort = (formData) => {
  if (ValidateRefData.validate(formData.eventPort, toValueAndLabel('id', 'name'))) {
    const direction = JourneyUtil.direction(formData?.movement?.journey);
    if (direction === DIRECTION.INBOUND && formData?.eventPort) {
      return {
        arrivalPort: {
          ...formData.eventPort,
          ...toValueAndLabel(
            formData.eventPort.id,
            formData.eventPort.name,
          ),
        },
      };
    }
    if (direction === DIRECTION.OUTBOUND && formData?.eventPort) {
      return {
        departurePort: {
          ...formData.eventPort,
          ...toValueAndLabel(
            formData.eventPort.id,
            formData.eventPort.name,
          ),
        },
      };
    }
  }
  return null;
};

const toMovement = (formData) => {
  const journey = JourneyUtil.get(formData);
  const mode = MovementUtil.movementMode(formData);
  return {
    movement: {
      id: replaceInvalidValues(formData?.movement?.id),
      ...(!RORO_MODES.includes(mode) && { flightNumber: replaceInvalidValues(formData?.movement?.journey?.id) }),
      route: replaceInvalidValues(JourneyUtil.movementRoute(journey)),
      direction: replaceInvalidValues(JourneyUtil.direction(journey)),
      ...toPort(formData),
      arrival: {
        date: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.arrivalTime(journey), dateFormat)),
        time: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.arrivalTime(journey), timeFormat)),
      },
      departure: {
        date: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.departureTime(journey), dateFormat)),
        time: replaceInvalidValues(DateTimeUtil.format(JourneyUtil.departureTime(journey), timeFormat)),
      },
    },
  };
};

const toDirection = (formData) => {
  const journey = JourneyUtil.get(formData);
  if (!journey?.direction || journey?.direction?.toUpperCase() === STRINGS.UNKNOWN.toUpperCase()) {
    return null;
  }

  return {
    direction: journey?.direction,
  };
};

const toRefDataMode = (formData) => {
  if (!formData?.movement?.refDataMode) {
    return null;
  }

  const refDataMode = formData.movement.refDataMode;
  if (!ValidateRefData.validate(refDataMode, toValueAndLabel('id', 'mode'))) {
    return null;
  }

  return {
    refDataMode: {
      ...refDataMode,
      ...toValueAndLabel(
        refDataMode?.id,
        refDataMode?.mode,
      ),
    },
  };
};

const toMode = (formData) => {
  if (!formData?.movement?.mode) {
    return null;
  }

  return {
    mode: formData?.movement?.mode,
  };
};

const toAutoPopulationPayload = (informationSheet, view) => {
  let autoPopulationPayload = {};
  if (informationSheet) {
    autoPopulationPayload = {
      ...toId(informationSheet),
      ...toSelectorsSummary(informationSheet),
      ...toControlStrategies(informationSheet),
      ...toMode(informationSheet),
      ...toRefDataMode(informationSheet),
      ...toDirection(informationSheet),
      ...toInterception(informationSheet),
      ...toPort(informationSheet),
      ...toVehicle(informationSheet),
      ...toTrailer(informationSheet),
      ...toGoods(informationSheet, view),
      ...toMovement(informationSheet),
      ...toIssuingHub(informationSheet),
      ...toMainPerson(informationSheet, view),
      ...toOtherPersons(informationSheet, view),
      ...toReasoning(informationSheet),
      ...toPreArrival(informationSheet),
      ...toOperation(informationSheet),
      ...toTargetingIndicators(informationSheet),
      ...toCategory(informationSheet),
      ...toRemarks(informationSheet),
      ...toPublicInterestImmunity(informationSheet),
      ...toGroupReference(informationSheet),
      ...toNominalChecks(informationSheet),
      ...toInformFreightAndTourist(informationSheet),
      ...toTargetReceiptTeam(informationSheet),
      ...toWhySelected(informationSheet),
      ...toAdditionalInformation(informationSheet),
    };
  }
  return autoPopulationPayload;
};

export default toAutoPopulationPayload;
