import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTargetSheet = (targetId, enabled, _targetSheet = null, justificationId = null) => {
  const keycloak = useKeycloak();
  const { canReadTask, canReadTarget } = usePermission();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();

  const getTargetSheet = async () => {
    if (!canReadTask && !canReadTarget) {
      return null;
    }

    if (_targetSheet) {
      return Promise.resolve(_targetSheet);
    }

    return AxiosRequests.targetSheet(apiClient, targetId, justificationId);
  };

  const { data: targetSheet, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TARGET_SHEET, targetId, _targetSheet, justificationId],
    queryFn: () => getTargetSheet(),
    initialData: null,
    enabled,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { targetSheet, isLoading };
};

export default useFetchTargetSheet;
