import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Button } from '@ukhomeoffice/cop-react-components';
// Context
import { usePermission } from '../../context/PermissionContext';
import { useView } from '../../context/ViewContext';
// Components
import DomainList from './DomainList';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
// Config
import { PATHS, RBAC } from '../../utils/constants';
import { DOMAIN_TYPE } from './constants';
// Style
import './ViewApprovedDomains.scss';
const ViewApprovedDomains = () => {
    const navigate = useNavigate();
    const { canCreateTarget, hasRole } = usePermission();
    const { setIsUseFullScreen } = useView();
    useEffect(() => {
        setIsUseFullScreen(true);
        return () => setIsUseFullScreen(false);
    });
    if (!canCreateTarget) {
        return _jsx("p", { children: "You are not authorised to view this page." });
    }
    const goToEditPage = () => navigate(PATHS.APPROVED_DOMAINS + PATHS.EDIT);
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { size: "l", children: "Approved email domains" }), _jsxs("div", { className: "domain-description", children: [_jsx("p", { children: "An email domain is the part of the email address after the @ symbol that denotes the organisation." }), _jsx("p", { children: "Domains listed here are UK only email domains for UK government and law enforcement agencies that targeters may need to routinely send target sheets to." }), _jsx("p", { children: "Targeters will not be warned or required to confirm when including an email address with a domain listed below on a target sheet." })] }), _jsx(Heading, { size: "m", children: "Approved email domains" }), _jsx(DomainList, { type: DOMAIN_TYPE.APPROVED }), _jsx(ComponentWrapper, { show: hasRole(RBAC.TARGETER_AIRPAX_OSDT), children: _jsx(Button, { onClick: goToEditPage, children: "Edit domains" }) })] }));
};
export default ViewApprovedDomains;
