import { Button, NotificationBanner } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';

// Components
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';

// Hooks
import useFetchJustifications from '../../../../../../utils/Hooks/useFetchJustifications';

// Utils
import toJustificationOptions from './helper/toJustificationOptions';

// Styling
import './AddJustification.scss';

const AddJustification = ({ isMaskingEnabled, getTaskWithJustification, toCreateJustification }) => {
  const { justifications, isLoading: isLoadingJustifications } = useFetchJustifications(isMaskingEnabled);
  const [expanded, setExpanded] = useState(false);

  const onOpenAndCloseAction = () => {
    setExpanded(!expanded);
  };

  if (!isMaskingEnabled) {
    return null;
  }

  if (isLoadingJustifications) {
    return <LoadingSpinner />;
  }

  return (
    <div className="govuk-grid-column">
      <NotificationBanner
        id="add-justification-banner"
        className="add-justification-banner"
        title="PNR data over 6 months old is currently hidden"
      >
        {!expanded
          ? (
            <Button
              id="add-justification"
              className="govuk-!-margin-bottom-3"
              onClick={onOpenAndCloseAction}
            >
              Add justification to show hidden data
            </Button>
          )
          : (
            <Button
              id="close"
              classModifiers="secondary"
              className="govuk-!-margin-bottom-3"
              onClick={onOpenAndCloseAction}
            >
              Cancel and close
            </Button>
          )}
        {expanded
          && (
            <div className="add-justification-content-container govuk-!-margin-top-2 govuk-!-padding-top-2">
              {toJustificationOptions(justifications, onOpenAndCloseAction, toCreateJustification, getTaskWithJustification)}
            </div>
          )}
      </NotificationBanner>

    </div>
  );
};

export default AddJustification;
