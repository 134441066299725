import { v4 as uuidv4 } from 'uuid';
// Config
import { CONTENT_TYPE } from '../constants';
const isMatchingEntity = (entityId1, entityId2) => {
    if (!entityId1 || !entityId2) {
        return false;
    }
    return (entityId1.svxId === entityId2.svxId
        && entityId1.poleV1Id === entityId2.poleV1Id
        && entityId1.poleV2Id === entityId2.poleV2Id
        && entityId1.pnrPoleV1Id === entityId2.pnrPoleV1Id
        && entityId1.pnrPoleV2Id === entityId2.pnrPoleV2Id);
};
const insertIf = (condition, elements) => {
    if (condition) {
        if (Array.isArray(elements)) {
            return elements;
        }
        return [elements];
    }
    return [];
};
const toAllEntityContent = (task, entities, data, dateTimeStamp) => {
    const toEntityPosition = (entityName) => {
        const nameParts = entityName.split('_');
        return {
            passengerIndex: Number(nameParts[1]),
            documentIndex: Number(nameParts[3]),
            documentNumber: nameParts[4],
        };
    };
    const toAllContent = () => {
        var _a, _b;
        const poiLen = (_b = (_a = task === null || task === void 0 ? void 0 : task.movement) === null || _a === void 0 ? void 0 : _a.personsOfInterest) === null || _b === void 0 ? void 0 : _b.length;
        return entities.map((entity) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            const entityPosition = toEntityPosition(entity);
            if (entity === 'BOOKING') {
                return [{
                        type: 'BOOKING',
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.TEXT,
                            content: data.bookingDetails,
                        },
                        timestamp: dateTimeStamp,
                    }];
            }
            if (entity === 'BAGGAGE') {
                return [{
                        type: 'BAGGAGE',
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.TEXT,
                            content: data.baggageDetails,
                        },
                        timestamp: dateTimeStamp,
                    }];
            }
            if (entity === 'VOYAGE') {
                return [{
                        type: 'VOYAGE',
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.TEXT,
                            content: data.voyageDetails,
                        },
                        timestamp: dateTimeStamp,
                    }];
            }
            if (entity === `PASSENGER_${entityPosition.passengerIndex}`) {
                return [
                    ...insertIf(data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Details`], {
                        type: 'PASSENGER',
                        entityId: entityPosition.passengerIndex > poiLen
                            ? (_a = task.movement.otherPersons[entityPosition.passengerIndex - poiLen - 1]) === null || _a === void 0 ? void 0 : _a.entityId
                            : (_b = task.movement.personsOfInterest[entityPosition.passengerIndex - 1]) === null || _b === void 0 ? void 0 : _b.entityId,
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.TEXT,
                            content: data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Details`],
                        },
                        timestamp: dateTimeStamp,
                    }),
                ];
            }
            if (entity === `PASSENGER_${entityPosition.passengerIndex}_PHOTO`) {
                return [
                    ...insertIf((_c = data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Photo`]) === null || _c === void 0 ? void 0 : _c.photo, {
                        type: 'PASSENGER',
                        entityId: entityPosition.passengerIndex > poiLen
                            ? (_d = task.movement.otherPersons[entityPosition.passengerIndex - poiLen - 1]) === null || _d === void 0 ? void 0 : _d.entityId
                            : (_e = task.movement.personsOfInterest[entityPosition.passengerIndex - 1]) === null || _e === void 0 ? void 0 : _e.entityId,
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.PHOTO,
                            file: (_f = data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Photo`]) === null || _f === void 0 ? void 0 : _f.photo,
                            filename: (_h = (_g = data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Photo`]) === null || _g === void 0 ? void 0 : _g.photo) === null || _h === void 0 ? void 0 : _h.name,
                            source: (_j = data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Photo`]) === null || _j === void 0 ? void 0 : _j.source,
                            approximateDateTaken: (_k = data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}Photo`]) === null || _k === void 0 ? void 0 : _k.approximateDate,
                        },
                        timestamp: dateTimeStamp,
                    }),
                ];
            }
            if (entity.startsWith(`PASSENGER_${entityPosition.passengerIndex}_DOCUMENT`)) {
                return [
                    ...insertIf(data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}DocumentDetails_${entityPosition.documentIndex}_${entityPosition.documentNumber}`], {
                        type: 'DOCUMENT',
                        entityId: entityPosition.passengerIndex > poiLen
                            ? (_m = (_l = task.movement.otherPersons[entityPosition.passengerIndex - poiLen - 1]) === null || _l === void 0 ? void 0 : _l.documents[entityPosition.documentIndex - 1]) === null || _m === void 0 ? void 0 : _m.entityId
                            : (_p = (_o = task.movement.personsOfInterest[entityPosition.passengerIndex - 1]) === null || _o === void 0 ? void 0 : _o.documents[entityPosition.documentIndex - 1]) === null || _p === void 0 ? void 0 : _p.entityId,
                        content: {
                            id: uuidv4(),
                            type: CONTENT_TYPE.TEXT,
                            content: data === null || data === void 0 ? void 0 : data[`passenger${entityPosition.passengerIndex}DocumentDetails_${entityPosition.documentIndex}_${entityPosition.documentNumber}`],
                        },
                        timestamp: dateTimeStamp,
                    }),
                ];
            }
            return [];
        })
            .flat(1);
    };
    return [
        ...toAllContent(),
    ];
};
const toSubmissionPayload = (task, data, dateTimeStamp) => {
    return toAllEntityContent(task, data.addDetail, data, dateTimeStamp);
};
const EntityUtil = {
    isMatchingEntity,
    toSubmissionPayload,
};
export default EntityUtil;
export { toSubmissionPayload, };
