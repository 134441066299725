import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

const FullScreenContext = createContext({});

const FullScreenProvider = ({ children }) => {
  const [fullScreenComponent, setFullScreen] = useState(null);

  const closeFullScreen = () => {
    setFullScreen(null);
  };

  const getBackLinkElement = () => {
    return document.getElementById('govuk-back-link');
  };

  const hideBackLink = () => {
    const element = getBackLinkElement();
    if (element) {
      element.style.display = 'none';
      element.style.visibility = 'hidden';
    }
  };

  const showBackLink = () => {
    const element = getBackLinkElement();
    if (element) {
      element.style.display = 'inline-block';
      element.style.visibility = 'visible';
    }
  };

  useEffect(() => {
    if (fullScreenComponent) {
      hideBackLink();
      return;
    }

    showBackLink();

    return () => showBackLink();
  }, [fullScreenComponent]);

  const value = useMemo(() => ({
    setFullScreen,
    closeFullScreen,
  }), [fullScreenComponent]);

  return (
    <FullScreenContext.Provider value={value}>
      {fullScreenComponent}
      <div hidden={fullScreenComponent !== null}>
        {children}
      </div>
    </FullScreenContext.Provider>
  );
};

/**
 * @returns {{ setFullScreen: (component: React.ReactNode) => void, closeFullScreen: () => void}}
 */
const useFullScreen = () => useContext(FullScreenContext);

export { FullScreenProvider, FullScreenContext, useFullScreen };
