var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Utils } from '@ukhomeoffice/cop-react-components';
// Styling
import './ValueField.scss';
const ValueField = (_a) => {
    var { value, classNames: _classNames } = _a, props = __rest(_a, ["value", "classNames"]);
    const DEFAULT_CLASS = 'value-field';
    const classes = Utils.classBuilder(DEFAULT_CLASS, null, _classNames);
    if (!value) {
        return null;
    }
    return _jsx("p", Object.assign({}, props, { className: classes(), children: value }));
};
export default ValueField;
