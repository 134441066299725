import React, { useMemo, createContext, useContext, useState } from 'react';

const TaskContext = createContext({});
const TaskProvider = ({ children }) => {
  const [task, setTask] = useState(null);
  const [target, setTarget] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [taskState, setTaskState] = useState({
    isClaimedByUser: false,
    isTargetIssued: false,
    isWithdrawnByTargeter: false,
    isDismissedByTargeter: false,
    isCompletedByFrontline: false,
  });
  const [isUpdateTask, setIsUpdateTask] = useState(false);
  const [isEditTarget, setIsEditTarget] = useState(false);
  const [isAddDetail, setIsAddDetail] = useState(false);
  const [subMode, setSubMode] = useState(null);
  const [isEditNoteFormOpen, setIsEditNoteFormOpen] = useState(false);
  const [isEditDetailFormOpen, setIsEditDetailFormOpen] = useState(false);
  const [editorContentProps, setEditorContentProps] = useState(null);
  const [additionalContent, setAdditionalContent] = useState(null);
  const [editedAdditionalContent, setEditedAdditionalContent] = useState(null);
  const [deletedAdditionalContent, setDeletedAdditionalContent] = useState(null);
  const [photosToDelete, setPhotosToDelete] = useState(null);
  const [credibilityChecks, setCredibilityChecks] = useState(null);
  const [originalTask, setOriginalTask] = useState(null);
  const [isEditsMade, setIsEditsMade] = useState(false);

  const value = useMemo(() => ({
    isUpdateTask,
    setIsUpdateTask,
    isEditTarget,
    setIsAddDetail,
    isAddDetail,
    setIsEditTarget,
    taskState,
    setTaskState,
    taskId,
    setTaskId,
    task,
    originalTask,
    setOriginalTask,
    setTask,
    target,
    setTarget,
    subMode,
    setSubMode,
    isEditNoteFormOpen,
    setIsEditNoteFormOpen,
    isEditDetailFormOpen,
    setIsEditDetailFormOpen,
    editorContentProps,
    setEditorContentProps,
    additionalContent,
    setAdditionalContent,
    editedAdditionalContent,
    setEditedAdditionalContent,
    deletedAdditionalContent,
    setDeletedAdditionalContent,
    photosToDelete,
    setPhotosToDelete,
    credibilityChecks,
    setCredibilityChecks,
    isEditsMade,
    setIsEditsMade,
  }), [
    isUpdateTask,
    setIsUpdateTask,
    isEditTarget,
    setIsEditTarget,
    setIsAddDetail,
    isAddDetail,
    taskId,
    setTaskId,
    task,
    setTask,
    originalTask,
    setOriginalTask,
    target,
    setTarget,
    subMode,
    setSubMode,
    taskState,
    setTaskState,
    isEditNoteFormOpen,
    setIsEditNoteFormOpen,
    isEditDetailFormOpen,
    setIsEditDetailFormOpen,
    editorContentProps,
    setEditorContentProps,
    additionalContent,
    setAdditionalContent,
    editedAdditionalContent,
    setEditedAdditionalContent,
    deletedAdditionalContent,
    setDeletedAdditionalContent,
    photosToDelete,
    setPhotosToDelete,
    credibilityChecks,
    setCredibilityChecks,
    isEditsMade,
    setIsEditsMade,
  ]);

  return (
    <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
  );
};
/**
 *
 * @returns {{    isUpdateTask,
 *       setIsUpdateTask,
 *       isEditTarget,
 *       setIsAddDetail,
 *       isAddDetail,
 *       setIsEditTarget,
 *       taskState,
 *       setTaskState,
 *       taskId,
 *       setTaskId,
 *       task,
 *       originalTask,
 *       setOriginalTask,
 *       setTask,
 *       target,
 *       setTarget,
 *       subMode,
 *       setSubMode,
 *       isEditNoteFormOpen,
 *       setIsEditNoteFormOpen,
 *       isEditDetailFormOpen,
 *       setIsEditDetailFormOpen,
 *       editorContentProps,
 *       setEditorContentProps,
 *       additionalContent,
 *       setAdditionalContent,
 *       editedAdditionalContent,
 *       setEditedAdditionalContent,
 *       deletedAdditionalContent,
 *       setDeletedAdditionalContent,
 *       photosToDelete,
 *       setPhotosToDelete,
 *       credibilityChecks,
 *       setCredibilityChecks,
 *       isEditsMade,
 *       setIsEditsMade,}}
 */
const useTask = () => useContext(TaskContext);

export { TaskContext, TaskProvider, useTask };
