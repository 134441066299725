import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Config
import { TASK_STATUS } from '../../../utils/constants';

// context
import { PermissionContext } from '../../../context/PermissionContext';

import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../../context/Keycloak';

import AxiosRequests from '../../../api/axiosRequests';

// Config
import config from '../../../utils/config';

// Components
import CommonButton from '../../../components/Buttons/CommonButton';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';

const ClaimUnclaimTask = ({ assignee, currentUser, source, buttonType, taskId, taskStatus, isTaskDetails }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const navigate = useNavigate();
  const isAssignedTo = assignee === currentUser ? 'you' : assignee;
  const [isAssignmentInProgress, setIsAssignmentInProgress] = useState(false);
  const [isAlreadyAssignedWarning] = useState(false);
  const { canUpdateTask } = useContext(PermissionContext);

  // The unclaim button depends on task being in progress, the assignee being
  // the current user, and the user having canUpdateTask permissions;
  const shouldShowUnclaimButton = () => {
    return taskStatus === TASK_STATUS.IN_PROGRESS && canUpdateTask;
  };

  // This text depends on the task being in progress;
  const shouldShowUnclaimText = () => {
    return taskStatus === TASK_STATUS.IN_PROGRESS && assignee;
  };

  const shouldShowClaimButton = () => {
    return taskStatus === TASK_STATUS.NEW && !assignee && !isAlreadyAssignedWarning && canUpdateTask;
  };

  const isAssignedToOtherUser = () => {
    return isAlreadyAssignedWarning;
  };

  const toCommonText = () => {
    return buttonType === 'textLink' ? 'Task not assigned' : null;
  };

  const toClassName = () => {
    return buttonType === 'textLink' ? 'link-button govuk-!-font-size-19' : 'link-button govuk-!-font-weight-bold govuk-button';
  };

  const handleClaim = async () => {
    setIsAssignmentInProgress(true);
    await AxiosRequests.claim(apiClient, taskId).then(() => {
      navigate(`${source}/${taskId}`);
      if (isTaskDetails) {
        navigate(0);
      }
    }).catch(() => {
      setIsAssignmentInProgress(false);
    });
  };

  const handleUnclaim = async () => {
    setIsAssignmentInProgress(true);
    await AxiosRequests.unclaim(apiClient, taskId).then(() => {
      // Reloads the page
      navigate(0);
      window.scrollTo(0, 0);
    }).catch(() => setIsAssignmentInProgress(false));
  };

  if (isAssignedToOtherUser()) {
    return (
      <div className="govuk-warning-text">
        <span className="govuk-warning-text__icon" aria-hidden="true">!</span>
        <strong className="govuk-warning-text__text">
          <span className="govuk-warning-text__assistive">Warning</span>
          {`Task already assigned to ${assignee}`}
        </strong>
      </div>
    );
  }

  if (shouldShowClaimButton()) {
    return (
      <>
        <span className="govuk-body task-list--assignee">
          {toCommonText()}&nbsp;
        </span>
        <CommonButton
          inProgress={isAssignmentInProgress}
          commonProps={{
            className: toClassName(),
            onClick: handleClaim,
            children: 'Claim',
            taskId,
          }}
        />
      </>
    );
  }

  if (shouldShowUnclaimText()) {
    return (
      <>
        <span className="govuk-body task-list--assignee">
          {`Claimed by ${isAssignedTo}`}&nbsp;
        </span>
        <ComponentWrapper show={shouldShowUnclaimButton()}>
          <CommonButton
            inProgress={isAssignmentInProgress}
            commonProps={{
              className: toClassName(),
              onClick: handleUnclaim,
              children: 'Unclaim task',
              taskId,
            }}
          />
        </ComponentWrapper>
      </>
    );
  }

  return null;
};

export default ClaimUnclaimTask;

ClaimUnclaimTask.propTypes = {
  assignee: PropTypes.string,
  currentUser: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  taskStatus: PropTypes.string.isRequired,
  isTaskDetails: PropTypes.bool,
};

ClaimUnclaimTask.defaultProps = {
  assignee: null,
  isTaskDetails: false,
};
