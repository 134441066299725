var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState, useEffect } from 'react';
// Config
import config from '../../../../../../../../utils/config';
import { CONTENT_TYPE, DATE_FORMATS, LOCAL_STORAGE_KEYS } from '../../../../../../../../utils/constants';
// Context(s)
import { useKeycloak } from '../../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../../context/TaskContext';
// Components
import DetailModal from './DetailModal';
import UpliftedForm from '../forms/UpliftedForm';
// Helpers
import addAdditionalContent from '../../../helper/addAdditionalContent';
// Hook(s)
import { useAxiosInstance } from '../../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';
// Util(s)
import BaggageUtil from '../../../../../../../../utils/Baggage/baggageUtil';
import BookingUtil from '../../../../../../../../utils/Booking/bookingUtil';
import { DateTimeUtil } from '../../../../../../../../utils';
import DocumentUploader from '../../../../../../../../utils/Form/ReactForm/Uplift/documentUploader';
import { toSubmissionPayload } from '../../../../../../../../utils/Entity/entityUtil';
import PersonUtil from '../../../../../../../../utils/Person/Uplift/personUtil';
// Form
import form from '../../../../../../../../forms/uplift/addDetail';
// Styling
import './AddDetail.scss';
const upgradeTask = (task) => {
    var _a, _b, _c, _d;
    if (((_b = (_a = task === null || task === void 0 ? void 0 : task.movement) === null || _a === void 0 ? void 0 : _a.personsOfInterest) === null || _b === void 0 ? void 0 : _b.length) === 0 && ((_c = task === null || task === void 0 ? void 0 : task.movement) === null || _c === void 0 ? void 0 : _c.person)) {
        task.movement.personsOfInterest = [(_d = task === null || task === void 0 ? void 0 : task.movement) === null || _d === void 0 ? void 0 : _d.person];
    }
    return task;
};
const AddDetail = forwardRef((_a, ref) => {
    var _b, _c;
    var { onClose, offsetTop } = _a, props = __rest(_a, ["onClose", "offsetTop"]);
    const keycloak = useKeycloak();
    const uploadApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);
    const { task, setTask, originalTask, setOriginalTask, additionalContent, setAdditionalContent, taskId } = useTask();
    const booking = BookingUtil.get(task);
    const baggage = BaggageUtil.get(task);
    const persons = PersonUtil.allPersons(task);
    const [submissionPayload, setSubmissionPayload] = useState(null);
    // #20656 this will need removing when the BE provides
    // personsOfInterest in the informationSheet data;
    if (!((_c = (_b = task === null || task === void 0 ? void 0 : task.movement) === null || _b === void 0 ? void 0 : _b.personsOfInterest) === null || _c === void 0 ? void 0 : _c.filter((item) => !!item).length)) {
        upgradeTask(task);
    }
    useSetTabTitle(TAB_TITLES.ADD_TASK_DETAIL);
    const uploadPhotoDocument = (photoSubmission) => __awaiter(void 0, void 0, void 0, function* () {
        const uploadedToUrl = yield DocumentUploader.upload(uploadApiClient, taskId, photoSubmission.content.file.file);
        if (uploadedToUrl) {
            photoSubmission.content.url = uploadedToUrl;
            delete photoSubmission.content.file;
        }
        return photoSubmission;
    });
    const uploadDocuments = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const photoSubmissions = payload.filter((submission) => submission.content.type === CONTENT_TYPE.PHOTO);
        if (photoSubmissions.length > 0) {
            return Promise.all(photoSubmissions.map((submission) => uploadPhotoDocument(submission)));
        }
        return Promise.resolve();
    });
    const checkIfOriginalTaskSet = () => !!originalTask;
    const onSubmit = (_a) => __awaiter(void 0, [_a], void 0, function* ({ data }) {
        const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
        const payload = toSubmissionPayload(task, data, dateTimeStamp);
        // #20824 If a task note is added after a detail, it erases the detail
        // so we use this to ensure it's add properly;
        localStorage.setItem(LOCAL_STORAGE_KEYS.ADDITIONAL_CONTENT_TRACKER, JSON.stringify(payload));
        yield uploadDocuments(payload);
        setSubmissionPayload(payload);
    });
    useEffect(() => {
        if (submissionPayload && submissionPayload.length > 0) {
            const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
            const user = {
                active: true,
                firstName: keycloak.tokenParsed.given_name,
                lastName: keycloak.tokenParsed.family_name,
                email: keycloak.tokenParsed.email,
            };
            if (!checkIfOriginalTaskSet()) {
                setOriginalTask(JSON.parse(JSON.stringify(task)));
            }
            setTask(addAdditionalContent(task, submissionPayload, user, dateTimeStamp, !additionalContent || additionalContent.length === 0, false));
            setAdditionalContent(additionalContent ? [...additionalContent, ...submissionPayload] : submissionPayload);
            onClose();
        }
    }, [submissionPayload, onClose]);
    return (_jsx("div", Object.assign({ ref: ref }, props, { children: _jsx(UpliftedForm, { form: form(booking, baggage, persons), onSubmit: onSubmit, onCancel: onClose, offsetTop: offsetTop, uploadDocument: false, cancelModal: _jsx(DetailModal, { onProceed: onClose }) }) })));
});
export default AddDetail;
