// Global import(s)
import { BLACK } from 'govuk-colours';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
`;

const Spinner = styled.div`
    text-align: center;
    border: 12px solid #dee0e2;
    border-radius: 50%;
    border-top-color: #005ea5;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerText = styled.div`
  line-height: 2;
  text-align: center;
  color: ${(props) => (props.colour ? props.colour : BLACK)};
`;

/**
 *
 * @param label
 * @param colour
 * @param props
 * @returns {React.ReactNode}
 * @constructor
 */
const ApplicationSpinner = ({ label, colour, ...props }) => {
  return (
    <div {...props} className="hods-width-container">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <SpinnerContainer>
            <Spinner />
            <SpinnerText colour={colour}>
              {label}
            </SpinnerText>
          </SpinnerContainer>
        </div>
      </div>
    </div>
  );
};

ApplicationSpinner.propTypes = {
  label: PropTypes.string,
  colour: PropTypes.string,
};

ApplicationSpinner.defaultProps = {
  label: 'Loading',
  colour: BLACK,
};

export default ApplicationSpinner;
