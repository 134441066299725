// Global imports
import { FormGroup, Readonly, TextInput, Utils } from '@ukhomeoffice/cop-react-components';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './DateTime.scss';

const toDateFromString = (str) => {
  if (str) {
    const [day, month, year, hour, minute] = str.split('-');
    return { day, month, year, hour, minute };
  }
  return { day: '', month: '', year: '', hour: '', minute: '' };
};

const toStringFromDate = (date) => {
  if (date) {
    const str = `${date.day}-${date.month}-${date.year}-${date.hour}-${date.minute}`;
    return str === '--' ? '' : str;
  }
  return '';
};

export const DEFAULT_CLASS = 'govuk-date-input';

/**
 * DateTime component for filter;
 *
 * @param {*} id id to use for jsx element
 * @param {*} fieldId id to use for jsx element
 * @param {*} error error text to show
 * @param {*} propsInError are there errors
 * @param {*} value value
 * @param {*} onChange function to invoke
 * @param {*} readonly is this a read only element
 * @param {*} classBlock the class to use
 * @param {*} classModifiers any class modifiers
 * @param {*} className the main class name
 *
 * This is blatantly a copy of DateInput from cop-react-components
 * but we needed a time component incorporated too, and with a
 * response to the API that it is expecting
 *
 * @returns {JSX.Element} A JSX element;
 */
const DateTime = ({
  id,
  fieldId,
  propsInError,
  value,
  onChange,
  readonly,
  classBlock,
  classModifiers,
  className,
  ...attrs
}) => {
  const classes = Utils.classBuilder(classBlock, classModifiers, className);
  const [date, setDate] = useState(undefined);
  const [dateChanged, setDateChanged] = useState(false);

  const DATE_PARTS = [
    { id: 'day', width: '2', label: 'Day' },
    { id: 'month', width: '2', label: 'Month' },
    { id: 'year', width: '4', label: 'Year' },
    { id: 'hour', width: '2', label: 'Hour' },
    { id: 'minute', width: '2', label: 'Minute' },
  ];

  useEffect(() => {
    setDate((prev) => {
      const existingDate = toStringFromDate(prev);
      if (existingDate !== (value || '')) {
        setDateChanged(true);
        return toDateFromString(value);
      }
      return prev || toDateFromString('');
    });
  }, [value, setDate, setDateChanged]);

  const handleChange = (event) => {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const name = event.target.name.replace(`${fieldId}-`, '');

    const { value: _value } = event.target;
    if (date && date[name] !== _value) {
      setDate((prev) => ({ ...prev, [name]: _value }));
      setDateChanged(true);
    }
  };

  useEffect(() => {
    if (typeof onChange === 'function' && dateChanged) {
      const newValue = toStringFromDate(date);
      setDateChanged(false);
      onChange({ target: { name: fieldId, value: newValue } });
    }
  }, [dateChanged, date, fieldId, onChange, setDateChanged]);

  if (!date) {
    return null;
  }

  if (readonly) {
    return (
      <Readonly id={id} name={fieldId} classModifiers={classModifiers} className={className} {...attrs}>
        {date.day} {Utils.getMonthName(date.month)} {date.year} {date.hour}:{date.minute}
      </Readonly>
    );
  }

  return (
    <div className={DEFAULT_CLASS} id={id} {...attrs}>
      {DATE_PARTS.map((part) => (
        <FormGroup id={`${id}-${part.id}`} label={part.label} required classBlock={classes('item')} key={`${id}-${part.id}`}>
          <TextInput
            id={`${id}-${part.id}`}
            fieldId={`${fieldId}-${part.id}`}
            value={date[part.id]}
            onChange={handleChange}
            pattern="[0-9]*"
            inputMode="numeric"
            error={propsInError && propsInError[part.id] ? 'error' : ''}
            className={classes('input')}
            classModifiers={`width-${part.width}`}
          />
        </FormGroup>
      ))}
    </div>
  );
};

export default DateTime;

DateTime.defaultProps = {
  classBlock: DEFAULT_CLASS,
  classModifiers: [],
  className: '',
  error: null,
  onChange: undefined,
  propsInError: null,
  readonly: false,
  value: '',
};

DateTime.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  classBlock: PropTypes.string,
  classModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  error: PropTypes.any,
  propsInError: PropTypes.shape({
    year: PropTypes.bool,
    month: PropTypes.bool,
    day: PropTypes.bool,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};
