import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { STRINGS } from '../../../../../utils/constants';

// Components
import Accordion from '../../../../../components/AccordionCustom/Accordion';
import AccordionRow from '../../../../../components/AccordionCustom/AccordionRow';
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import PersonTemplate from './PersonTemplate';
import PncCheck from '../../Shared/components/PncCheck/PncCheck';

// Utils
import {
  CommonUtil,
  TargetConsigneeUtil,
  TargetConsignorUtil,
  TargetGoodsUtil,
  TargetHaulierUtil,
  TargetPersonUtil,
  TargetTrailerUtil,
  TargetVehicleUtil,
} from '../../../../../utils';
import { toPncWarningTag } from '../../../../../utils/PNC/adaptPncContent';

import './RoRoTargetingInformation.scss';

const RoRoTargetingInformation = ({ informationSheet }) => {
  const toConsignmentAndHaulier = () => {
    const goods = TargetGoodsUtil.get(informationSheet);
    const haulier = TargetHaulierUtil.get(informationSheet);
    const consignor = TargetConsignorUtil.get(informationSheet);
    const consignee = TargetConsigneeUtil.get(informationSheet);

    // Determine if the user has been altered; if the default is INSUFFICIENT_TIME
    // then we need to be sure that something else has been altered otherwise the
    // operator might just have altered nothing; a likely change is the first line
    // of the address;
    const canAssigneeBeDisplayed = (who) => {
      return (who?.pncResult?.outcome === 'INSUFFICIENT_TIME' && (who?.name?.length !== 0 || Object.values(who?.address).some((a) => a?.length > 0)))
        || who?.pncResult?.outcome !== 'INSUFFICIENT_TIME';
    };

    return {
      id: 'consignment-and-haulier',
      heading: 'Consignment and haulier',
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Manifested Load"
            value={TargetGoodsUtil.description(goods)}
            id="manifested-load"
          />
          <AccordionRow
            label="Manifested Weight"
            value={TargetGoodsUtil.weight(goods)}
            id="manifested-weight"
          />
          <AccordionRow
            label="Gross Weight"
            value={TargetGoodsUtil.grossWeight(goods)}
            id="gross-weight"
          />
          <AccordionRow
            label="Net Weight"
            value={TargetGoodsUtil.netWeight(goods)}
            id="net-weight"
          />
          <AccordionRow
            label="Hazardous"
            value={TargetGoodsUtil.format.hazardous(TargetGoodsUtil.hazardous(goods))}
            id="hazardous"
          />

          <ComponentWrapper show={canAssigneeBeDisplayed(consignor)}>
            <AccordionRow
              label="Consignor"
              value={TargetConsignorUtil.name(consignor)}
              id="consignor-name"
            />
            <ComponentWrapper show={TargetConsignorUtil.address(consignor) !== undefined}>
              <AccordionRow
                label="Address"
                value={TargetConsignorUtil.address(consignor)}
                id="consignor-address"
              />
            </ComponentWrapper>
            <div className="govuk-summary-list__row pnc-check">
              <dt />
              <dd>
                <PncCheck person={consignor} targetSheet />
              </dd>
            </div>
          </ComponentWrapper>

          <ComponentWrapper show={canAssigneeBeDisplayed(consignee)}>
            <AccordionRow
              label="Consignee"
              value={TargetConsigneeUtil.name(consignee)}
              id="consignee-name"
            />
            <ComponentWrapper show={TargetConsigneeUtil.address(consignee) !== undefined}>
              <AccordionRow
                label="Address"
                value={TargetConsigneeUtil.address(consignee)}
                id="consignee-address"
              />
            </ComponentWrapper>
            <div className="govuk-summary-list__row pnc-check">
              <dt />
              <dd>
                <PncCheck person={consignee} targetSheet />
              </dd>
            </div>
          </ComponentWrapper>

          <ComponentWrapper show={canAssigneeBeDisplayed(haulier)}>
            <AccordionRow
              label="Haulier"
              value={TargetHaulierUtil.name(haulier)}
              id="haulier-name"
            />
            <ComponentWrapper show={TargetHaulierUtil.address(haulier) !== undefined}>
              <AccordionRow
                label="Address"
                value={TargetHaulierUtil.address(haulier)}
                id="haulier-address"
              />
            </ComponentWrapper>
            <div className="govuk-summary-list__row pnc-check">
              <dt />
              <dd>
                <PncCheck person={haulier} targetSheet />
              </dd>
            </div>
          </ComponentWrapper>
        </dl>
      ),
    };
  };

  const toTrailer = () => {
    const trailer = TargetTrailerUtil.get(informationSheet);
    if (!trailer) {
      return null;
    }

    return {
      id: 'trailer-details',
      heading: `Trailer: ${TargetTrailerUtil.registration(trailer) || STRINGS.UNKNOWN}`,
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Trailer type"
            value={TargetTrailerUtil.type(trailer)}
            id="trailer-type"
          />
          <AccordionRow
            label="Trailer registration nationality"
            value={TargetTrailerUtil.nationality(trailer)}
            id="trailer-registration-nationality"
          />
        </dl>
      ),
    };
  };

  const toVehicle = () => {
    const vehicle = TargetVehicleUtil.get(informationSheet);
    if (!vehicle) {
      return null;
    }

    return {
      id: 'vehicle-details',
      heading: `Vehicle: ${TargetVehicleUtil.registration(vehicle) || STRINGS.UNKNOWN}`,
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Vehicle make"
            value={TargetVehicleUtil.make(vehicle)}
            id="vehicle-make"
          />
          <AccordionRow
            label="Vehicle model"
            value={TargetVehicleUtil.model(vehicle)}
            id="vehicle-model"
          />
          <AccordionRow
            label="Vehicle colour"
            value={TargetVehicleUtil.colour(vehicle)}
            id="vehicle-colour"
          />
          <AccordionRow
            label="Vehicle registration nationality"
            value={TargetVehicleUtil.nationality(vehicle)}
            id="vehicle-registration-number"
          />
        </dl>
      ),
    };
  };

  const toPassengers = () => {
    const coTravellers = CommonUtil.uniqueId(TargetPersonUtil.others(informationSheet));

    if (!coTravellers.length) {
      return [];
    }

    return coTravellers.map((person, index) => {
      return {
        id: `associated-passenger-details-${index}`,
        key: person.id,
        heading: `Associated passenger: ${TargetPersonUtil.fullName(person) || STRINGS.UNKNOWN}`,
        summary: toPncWarningTag(person),
        expanded: false,
        children: <PersonTemplate person={person} document={person?.documents ? person.documents[0] : null} />,
      };
    });
  };

  const toMainPerson = () => {
    const persons = TargetPersonUtil.person(informationSheet);
    if (!persons) {
      return null;
    }

    return persons.map((person, index) => {
      const document = person?.documents ? person.documents[0] : null;
      return {
        id: `person-details-${index}`,
        heading: `Driver: ${TargetPersonUtil.fullName(person)}`,
        summary: toPncWarningTag(person),
        expanded: false,
        children: (
          <div>
            <PersonTemplate person={person} document={document} />
          </div>
        ),
      };
    });
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <Accordion
          classnames="target-information-accordion"
          id={`target-information-${informationSheet.id}`}
          items={[
            ...toMainPerson(),
            ...toPassengers(),
            toVehicle(),
            toTrailer(),
            toConsignmentAndHaulier(),
          ].filter((item) => !!item)}
        />
      </div>
    </div>
  );
};

export default RoRoTargetingInformation;

RoRoTargetingInformation.propTypes = {
  informationSheet: PropTypes.shape({}),
};

RoRoTargetingInformation.defaultProps = {
  informationSheet: null,
};
