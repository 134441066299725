import { jsx as _jsx } from "react/jsx-runtime";
// Hooks
import useFetchDomains from '../../utils/Hooks/useFetchDomains';
// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
// Styles
import './DomainList.scss';
const DomainList = ({ type }) => {
    const { domains, isLoading } = useFetchDomains(type);
    return (_jsx(LoadingSpinner, { loading: isLoading, children: _jsx("div", { className: "domain-list-container", children: domains.length > 0
                ? (_jsx("ul", { children: domains.map((domain) => (_jsx("li", { children: `@${domain}` }, domain))) }))
                : _jsx("p", { id: "no-domains-added", children: "No domains added" }) }) }));
};
export default DomainList;
