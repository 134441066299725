// Config(s)
import { GENDERS, STRINGS, MOVEMENT_ROLE } from '../../constants';

// Util(s)
import { shouldMaskString, shouldMaskDate, getMaskedValue } from '../../Masking/maskingUtil';
import { shouldHideString, shouldHideDate } from '../../Hiding/hidingUtil';

const toFormattedRole = (role) => {
  if (role === MOVEMENT_ROLE.DRIVER) {
    return 'Driver';
  }

  if (role === MOVEMENT_ROLE.PASSENGER) {
    return 'Passenger';
  }

  if (role === MOVEMENT_ROLE.AIR_CREW) {
    return 'Crew';
  }

  if (role === MOVEMENT_ROLE.PILOT) {
    return 'Pilot';
  }

  return null;
};

const getRole = (person) => {
  if (!person?.role) {
    return null;
  }

  if (shouldHideString(person?.role)) {
    return null;
  }

  if (shouldMaskString(person.role)) {
    return getMaskedValue();
  }

  return person.role;
};

const includeUniqueRoleLabelForAll = (persons) => {
  if (!persons?.length) {
    return [];
  }

  const roleCounts = {};
  return persons.map((person) => {
    const role = getRole(person);
    if (!role || role === getMaskedValue()) {
      return {
        ...person,
        roleLabel: null,
      };
    }

    const formattedRole = toFormattedRole(role);
    roleCounts[role] = (roleCounts[role] || 0) + 1;

    return {
      ...person,
      roleLabel: `${formattedRole} ${roleCounts[role]}`,
    };
  });
};

const getAdditionalContent = (person) => {
  if (!person?.additionalContent || !person?.additionalContent?.length) {
    return [];
  }

  return person.additionalContent;
};

const getBaggage = (person) => {
  if (!person?.baggage) {
    return null;
  }

  return person.baggage;
};

const isUnknownName = (name) => {
  if (!name) {
    return true;
  }

  return name.toLowerCase() === STRINGS.UNKNOWN.toLowerCase();
};

const toFormattedName = (firstName) => {
  return (lastName, capitalize = false) => {
    let formattedLastName = lastName;
    if (capitalize) {
      formattedLastName = formattedLastName?.toUpperCase();
    }

    return [firstName, formattedLastName]
      .filter((name) => !!name && !isUnknownName(name))
      .join(' ');
  };
};

const formatGender = (gender) => {
  if (!gender) {
    return null;
  }

  if (shouldHideString(gender)) {
    return null;
  }

  if (shouldMaskString(gender)) {
    return getMaskedValue();
  }

  if (gender.toString().toUpperCase() === GENDERS.MALE.unit.toUpperCase()) {
    return GENDERS.MALE.value;
  }

  if (gender.toString().toUpperCase() === GENDERS.FEMALE.unit.toUpperCase()) {
    return GENDERS.FEMALE.value;
  }

  return null;
};

const getNationality = (person) => {
  if (!person?.nationality) {
    return null;
  }

  if (shouldHideString(person.nationality)) {
    return null;
  }

  if (shouldMaskString(person.nationality)) {
    return getMaskedValue();
  }

  return person.nationality;
};

const getFrequentFlyerNumber = (person) => {
  if (!person?.frequentFlyerNumber) {
    return null;
  }

  if (shouldHideString(person.frequentFlyerNumber)) {
    return null;
  }

  if (shouldMaskString(person.frequentFlyerNumber)) {
    return getMaskedValue();
  }

  return person.frequentFlyerNumber;
};

const getDateOfBirth = (person) => {
  if (!person?.dateOfBirth) {
    return null;
  }

  if (shouldHideDate(person.dateOfBirth)) {
    return null;
  }

  if (shouldMaskDate(person.dateOfBirth)) {
    return getMaskedValue();
  }

  return person.dateOfBirth;
};

const getGender = (person) => {
  if (!person?.gender) {
    return null;
  }

  if (shouldHideString(person.gender)) {
    return null;
  }

  if (shouldMaskString(person.gender)) {
    return getMaskedValue();
  }

  return person.gender;
};

const getLastName = (person, capitalize = true) => {
  if (!person?.name?.last) {
    return null;
  }

  if (isUnknownName(person.name.last)) {
    return null;
  }

  if (shouldHideString(person.name.last)) {
    return null;
  }

  if (shouldMaskString(person.name.last)) {
    return getMaskedValue();
  }

  return capitalize ? person.name.last.toUpperCase() : person.name.last;
};

const getPassengerStatus = (person) => {
  if (!person?.passengerStatus) {
    return null;
  }

  return person.passengerStatus;
};

const getFirstName = (person) => {
  if (!person?.name?.first) {
    return null;
  }

  if (isUnknownName(person.name.first)) {
    return null;
  }

  if (shouldHideString(person.name.first)) {
    return null;
  }

  if (shouldMaskString(person.name.first)) {
    return getMaskedValue();
  }

  return person.name.first;
};

const hasOtherPersons = (task) => {
  return !!task?.movement?.otherPersons?.length;
};

const getOtherPersons = (task) => {
  if (!hasOtherPersons(task)) {
    return [];
  }
  return task.movement.otherPersons;
};

const hasPerson = (task) => {
  return !!task?.movement?.person;
};

const hasPersons = (task) => {
  return !!task?.movement?.personsOfInterest;
};

const getPerson = (task) => {
  if (!hasPerson(task) && !hasPersons(task)) {
    return null;
  }
  return task?.movement?.personsOfInterest ?? task?.movement?.person;
};

const getAllPersons = (task) => {
  const allPersons = [];
  const gp = getPerson(task);
  const person = gp?.length === undefined ? [gp] : gp;
  const otherPersons = getOtherPersons(task);
  if (person) {
    allPersons.push(...person);
  }
  if (otherPersons) {
    allPersons.push(...otherPersons);
  }
  return allPersons.filter((item) => !!item);
};

const PersonUtil = {
  allPersons: getAllPersons,
  additionalContent: getAdditionalContent,
  baggage: getBaggage,
  dob: getDateOfBirth,
  firstname: getFirstName,
  formattedName: toFormattedName,
  gender: getGender,
  get: getPerson,
  getOthers: getOtherPersons,
  lastname: getLastName,
  nationality: getNationality,
  passengerStatus: getPassengerStatus,
  frequentFlyerNumber: getFrequentFlyerNumber,
  role: getRole,
  addRoleLabel: includeUniqueRoleLabelForAll,
  format: {
    gender: formatGender,
    role: toFormattedRole,
  },
};

export default PersonUtil;

export {
  formatGender,
  getAdditionalContent,
  getBaggage,
  getDateOfBirth,
  getFirstName,
  getFrequentFlyerNumber,
  getGender,
  getLastName,
  getNationality,
  getOtherPersons,
  getPerson,
  getRole,
  includeUniqueRoleLabelForAll,
  toFormattedName,
  toFormattedRole,
};
