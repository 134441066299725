var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// Global imports
import axios from 'axios';
import { useEffect, useState } from 'react';
// Local imports
import config from '../../utils/config';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import FormUtils, { Renderers } from '../../utils/Form/ReactForm';
import { useKeycloak } from '../../context/Keycloak';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ReactForm from './Forms/ReactForm';
const RenderForm = ({ formName, form: _form, onSubmit, onFinish, onCancel, onChange, preFillData, saveTisData, viewOnly, uploadDocument, overrideSubmit, children, }) => {
    const [form, setForm] = useState(_form);
    const [isLoaderVisible, setLoaderVisibility] = useState(true);
    const [formattedPreFillData, setFormattedPreFillData] = useState({ data: {} });
    const [submitted, setSubmitted] = useState(false);
    const keycloak = useKeycloak();
    const formApiClient = useAxiosInstance(keycloak, config.formApiUrl);
    useEffect(() => {
        const source = axios.CancelToken.source();
        const loadForm = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (formName) {
                    const formRenderer = FormUtils.getRenderer(formName);
                    const formEndpoint = formRenderer === Renderers.REACT ? 'copform' : 'form';
                    const { data } = yield formApiClient.get(`/${formEndpoint}/name/${formName}`);
                    setForm(data);
                }
            }
            finally {
                setLoaderVisibility(false);
            }
        });
        loadForm();
        return () => {
            source.cancel('Cancelling request');
        };
    }, []);
    useEffect(() => {
        setFormattedPreFillData({
            data: Object.assign({ environmentContext: {
                    referenceDataUrl: config.refdataApiUrl,
                } }, preFillData || {}),
        });
    }, [preFillData]);
    if (submitted && children) {
        return children;
    }
    if (isLoaderVisible) {
        return _jsx(LoadingSpinner, {});
    }
    return (_jsx(ReactForm, { form: form, formattedPreFillData: formattedPreFillData, saveTisData: saveTisData, onFinish: onFinish, setSubmitted: setSubmitted, setLoaderVisibility: setLoaderVisibility, onCancel: onCancel, onChange: onChange, onSubmit: onSubmit, viewOnly: viewOnly, uploadDocument: uploadDocument, overrideSubmit: overrideSubmit }));
};
export default RenderForm;
