import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

// Config(s)
import { TASKS_TABS, FILTER_TYPES_WITH_CUSTOM_OPTIONS } from '../../../routes/Task/TaskList/shared/constants';
import { VIEW } from '../../../utils/constants';

// Context(s)
import { useTabs } from '../../../context/TabContext';

// Util(s)
import { CommonUtil } from '../../../utils';
import setupFilterGroupCounts from '../helper/setupFilterGroupCounts';
import setupFilterTaskCounts from '../helper/setupFilterTaskCounts';
import setupComponentErrors from '../helper/setupComponentErrors';

const useFilters = (appliedFilters, filterCounts) => {
  const [filtersData, setFiltersData] = useState(appliedFilters);
  const { tabIndex } = useTabs();

  const updateFilterCounts = () => {
    if (!filterCounts?.length) {
      return appliedFilters;
    }

    const containsOnly = (param, value) => {
      return Array.isArray(param) ? param.length === 1 && param[0] === value : param === value;
    };

    const findFilterAndSelectorCount = (option, filterType) => {
      const foundFilterAndSelectorCount = filterCounts?.find((filterAndSelectorCount) => filterAndSelectorCount.filterParams.taskStatuses.includes(TASKS_TABS[tabIndex].taskStatus)
        && containsOnly(filterAndSelectorCount.filterParams[filterType], option.value) && filterAndSelectorCount.counterType === filterType);

      const selectedTabId = CommonUtil.findTabByStatus(TASKS_TABS, TASKS_TABS[tabIndex].taskStatus).id;

      if (!foundFilterAndSelectorCount) {
        return option;
      }

      return {
        ...option,
        count: foundFilterAndSelectorCount.statusCounts[selectedTabId],
        label: `${option.originalLabel} (${foundFilterAndSelectorCount.statusCounts[selectedTabId]})`,
      };
    };

    const customOptionsCounts = FILTER_TYPES_WITH_CUSTOM_OPTIONS.reduce((acc, filterType) => {
      const customOption = appliedFilters?.[filterType]?.map((item) => findFilterAndSelectorCount(item, filterType));
      return {
        ...acc,
        ...(!!customOption && { [filterType]: customOption }),
      };
    }, {});

    return {
      ...appliedFilters,
      ...customOptionsCounts,
    };
  };

  useDeepCompareEffect(() => {
    setFiltersData(appliedFilters);
  }, [appliedFilters]);

  useDeepCompareEffect(() => {
    setFiltersData(updateFilterCounts());
  }, [filterCounts]);

  return { filtersData };
};

export const useSetupFilterCounts = (form, selectedTab, filterCounts, groupCounts, view) => {
  if ([VIEW.AIRPAX, VIEW.RORO, VIEW.AIRPAX_V2, VIEW.GENERAL_AVIATION, VIEW.COMMODITIES, VIEW.CTBP, VIEW.IDP, VIEW.OSDT].includes(view)) {
    return setupFilterTaskCounts(form, selectedTab, filterCounts);
  }

  if ([VIEW.AIRPAX_RCCU, VIEW.AIRPAX_HO, VIEW.AIRPAX_IO, VIEW.RORO_IO].includes(view)) {
    return setupFilterGroupCounts(form, groupCounts);
  }

  return form;
};

export const useSetupComponentErrors = (form, errorList) => {
  const [internalForm, setInternalForm] = useState(form);

  useEffect(() => {
    if (errorList?.length) {
      setInternalForm((prev) => {
        prev.pages.forEach((page) => page.components.forEach((component) => setupComponentErrors(component, errorList)));
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              components: page.components,
            };
          }),
        };
      });
    }
  }, [errorList]);

  useEffect(() => setInternalForm(form), [form]);
  return internalForm;
};

export default useFilters;
