import React from 'react';

// Config
import { TIME_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { getTaskId } from '../../../../../../utils/Task/taskUtil';
import { toEventTime } from '../../helper/toEventDate';
import JourneyUtil from '../../../../../../utils/Journey/Uplift/journeyUtil';

const TaskJourneyDetail = ({ task }) => {
  const taskId = getTaskId(task);
  const journey = JourneyUtil.get(task);
  const departureTime = JourneyUtil.departureTime(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);

  return (
    <p id={`movement-journey-detail-${taskId}`} className="ho-body-xs">
      <ComponentWrapper show={departureTime && departureLocation}>
        <span className="departure-time">{toEventTime(departureTime, departureLocation, TIME_FORMATS.SUMMARY)}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={departureLocation}>
        <span className="departure-location ho-heading-m govuk-!-font-weight-bold">{departureLocation}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={departureLocation || arrivalLocation}>
        <span className="right-arrow">&#8594;</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={arrivalLocation}>
        <span className="arrival-location ho-heading-m govuk-!-font-weight-bold">{arrivalLocation}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={arrivalTime && arrivalLocation}>
        <span className="arrival-time">{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.SUMMARY)}</span>
      </ComponentWrapper>
    </p>
  );
};

export default TaskJourneyDetail;
