import { useEffect, useRef } from 'react';
/**
 * Where the ref is placed, that will be where the initial focus of the page will be on first tab
 * After that the DOM/tab order will take over
 * @returns {React.MutableRefObject<null>}
 */
const useForceInitialTabFocus = () => {
    const ref = useRef(null);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Tab') {
                if (ref.current) {
                    event.preventDefault();
                    ref.current.focus();
                    window.removeEventListener('keydown', handleKeyDown);
                }
            }
        };
        const handleFocus = () => {
            // On any focus interaction, no need for these event listeners anymore
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('focusin', handleFocus);
        };
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('focusin', handleFocus);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('focusin', handleFocus);
        };
    }, []);
    return ref;
};
export default useForceInitialTabFocus;
