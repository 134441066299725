// utils/additionalContent.js
import { v4 as uuidv4 } from 'uuid';

import toCredibilityChecksSubmission from './toCredibilityChecksSubmission';

import EntityUtil from '../../../../../../utils/Entity/entityUtil';

const updateCredibilityChecksInTask = (task, entities, user, dateTimeStamp) => {
  const addTaskActivity = (activities) => {
    const activity = {
      id: uuidv4(),
      content: 'credibility checks updated',
      user,
      timestamp: dateTimeStamp,
    };

    activities.unshift(activity);
  };

  const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];

  const updatedPersons = personsToUpdate.map((person) => {
    const matchingEntity = entities.find((entity) => EntityUtil.isMatchingEntity(entity?.entityId, person?.entityId));

    if (matchingEntity) {
      return {
        ...person,
        credibilityChecks: {
          credibilityChecks: matchingEntity.credibilityChecks,
          notNecessary: matchingEntity.notNecessary,
          notNecessaryReason: matchingEntity.notNecessaryReason,
        },
      };
    }
    return person;
  });

  // Update the movement object with updated person and otherPersons arrays
  task.movement.person = updatedPersons[0];
  task.movement.otherPersons = updatedPersons.slice(1);

  addTaskActivity(task.activities);
  return task;
};

const updateCredibilityChecks = (task, submissionPayload, user, dateTime) => {
  const updatedTask = updateCredibilityChecksInTask(task, submissionPayload, user, dateTime);
  return updatedTask;
};

const getCredibilityChecksPayload = (credibilityChecks) => {
  return Object.values(credibilityChecks).map((check) => toCredibilityChecksSubmission(check));
};

export { updateCredibilityChecks, getCredibilityChecksPayload };
