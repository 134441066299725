// Global import(s)
import { Link, Panel } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';

const ReAssignOutcome = ({ assignees, unAssignees, onFinish }) => {
  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-full">
        <div id="reassigned-outcome-container">
          <Panel
            aria-label="target reassigned"
            id="reassign-outcome-message"
            title="Target reassigned"
          />
          <ComponentWrapper show={unAssignees?.length}>
            <p
              id="target-unassigned-from-label"
              aria-label="target unassigned from"
              className="govuk-body-s govuk-!-margin-bottom-0"
            >
              Target unassigned from:
            </p>
            <ul id="unassigned-from-list" className="govuk-!-margin-top-0">
              {unAssignees.map((unAssignee) => (
                <li
                  aria-label={`target unassigned from ${unAssignee.label}`}
                  id={`unassigned-from-${unAssignee.label}`}
                  key={unAssignee.label}
                >
                  {unAssignee.label}
                </li>
              ))}
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={assignees?.length}>
            <p
              id="target-assigned-to-label"
              aria-label="target assigned to"
              className="govuk-body-s govuk-!-margin-bottom-0"
            >
              Target assigned to:
            </p>
            <ul id="assigned-to-list" className="govuk-!-margin-top-0 govuk-!-margin-bottom-5">
              {assignees.map((assignee) => (
                <li
                  aria-label={`target assigned to ${assignee.label})`}
                  id={`assigned-to-${assignee.label}`}
                  key={assignee.label}
                >
                  {assignee.label}
                </li>
              ))}
            </ul>
          </ComponentWrapper>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link aria-label="return to targets" onClick={onFinish}>
            Return to targets
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReAssignOutcome;

ReAssignOutcome.propTypes = {
  assignees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unAssignees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFinish: PropTypes.func.isRequired,
};
