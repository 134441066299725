// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Component(s)
import BookingDetails from '../booking/BookingDetails';
import CoTravellers from '../person/CoTravellers';
import FlightDetails from '../flight/FlightDetails';
import Persons from '../person/Persons';
import PersonsOfInterest from '../person/PersonsOfInterest';
import PreArrival from '../details/uplift/PreArrival';
import ReferralReason from '../details/ReferralReason';
import TargetingDetails from '../details/uplift/TargetingDetails';
import TargetIssuedBy from '../details/uplift/TargetIssuedBy';

const AirpaxTargetSheet = ({ informationSheet, persons, mode, preview }) => {
  return (
    <div id="target-sheet-container">
      {mode === MOVEMENT_MODES.AIR_PASSENGER && (
        <>
          <PersonsOfInterest informationSheet={informationSheet} mode={mode} preview={preview} />
          <CoTravellers informationSheet={informationSheet} mode={mode} preview={preview} />
          <BookingDetails informationSheet={informationSheet} />
          <TargetIssuedBy informationSheet={informationSheet} preview={preview} />
        </>
      )}

      {mode !== MOVEMENT_MODES.AIR_PASSENGER && (
        <>
          <FlightDetails informationSheet={informationSheet} mode={mode} />
          <ReferralReason informationSheet={informationSheet} />
          <PreArrival informationSheet={informationSheet} />
          <Persons persons={persons} mode={mode} />
          <BookingDetails informationSheet={informationSheet} />
          <TargetingDetails informationSheet={informationSheet} />
        </>
      )}
    </div>
  );
};

AirpaxTargetSheet.propTypes = {
  targetSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

AirpaxTargetSheet.defaultProps = {
  preview: false,
};

export default memo(AirpaxTargetSheet);
