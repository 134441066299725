import { DateTimeUtil } from '../../../../../../utils';
import { DATE_FORMATS } from '../../../../../../utils/constants';
import { getCredibilityChecksPayload } from './updateCredibilityChecks';

const toAdditionalContentPayload = (newAdditionalContent, editedAdditionalContent, deletedAdditionalContent) => {
  const getNormalizedContent = (content) => {
    let normalizedContent;

    if (Array.isArray(content)) {
      normalizedContent = content;
    } else if (content) {
      normalizedContent = [content];
    } else {
      normalizedContent = null;
    }
    return normalizedContent;
  };

  const deletedAdditionalContentIds = getNormalizedContent(deletedAdditionalContent)?.map((item) => item.id);
  return {
    add: newAdditionalContent ? [...newAdditionalContent] : null,
    update: getNormalizedContent(editedAdditionalContent),
    ...(deletedAdditionalContentIds?.length > 0 && {
      delete: {
        additionIds: deletedAdditionalContentIds,
        timestamp: DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC),
      },
    }),
  };
};

/**
 * Payload generator for saving Task Detail information
 * @param newAdditionalContent
 * @param editedAdditionalContent
 * @param deletedAdditionalContent
 * @param credibilityChecks
 * @returns {{additionalContent: {add: (*[]|null), update: (*[]|null), delete: ({additionalIds, timestamp}|null)} , credibilityChecks}}
 */
const toAdditionalContentAndCredibilityChecksPayload = (newAdditionalContent, editedAdditionalContent, deletedAdditionalContent, credibilityChecks) => ({
  additionalContent: toAdditionalContentPayload(newAdditionalContent, editedAdditionalContent, deletedAdditionalContent),
  credibilityChecks: getCredibilityChecksPayload(credibilityChecks),
});

export default toAdditionalContentAndCredibilityChecksPayload;
