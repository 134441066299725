import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// Utils
import { JustificationUtil } from '../index';
import QUERY_KEYS from './constants';

const useJustificationIdReset = (justificationId) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const invalidateJustificationQueries = async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASK, justificationId] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.JUSTIFICATIONS] }),
      ]);
    };

    if (!justificationId) {
      return;
    }

    if (justificationId === 'CREATE_JUSTIFICATION') {
      JustificationUtil.toJustificationUI();
      return;
    }

    invalidateJustificationQueries();
  }, [justificationId]);
};

export default useJustificationIdReset;
