// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import BaggageTotal from './baggage/BaggageTotal';
import Booking from './booking/Booking';
import Persons from './passenger/Persons';
import Voyage from './voyage/shared/Voyage';

const CtbpMovement = ({
  flight,
  booking,
  journey,
  persons,
  baggage,
  movementId,
  movementIds,
  bookingVsIntent,
  groupSize,
  s4Person,
  s4Journey,
}) => {
  return (
    <>
      <Voyage flight={flight} journey={journey} s4Journey={s4Journey} />
      <Booking
        booking={booking}
        totalPersons={groupSize}
        movementId={movementId}
        movementIds={movementIds}
        bookingVsIntent={bookingVsIntent}
      />
      <Persons
        booking={booking}
        persons={persons}
        journey={journey}
        flight={flight}
        movementId={movementId}
        movementIds={movementIds}
        s4Person={s4Person}
      />
      <BaggageTotal baggage={baggage} />
    </>
  );
};

CtbpMovement.propTypes = {
  flight: PropTypes.shape({}),
  booking: PropTypes.shape({}),
  journey: PropTypes.shape({}),
  persons: PropTypes.arrayOf(PropTypes.shape({})),
  baggage: PropTypes.shape({}),
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupSize: PropTypes.number.isRequired,
  bookingVsIntent: PropTypes.number,
  s4Person: PropTypes.shape({}),
  s4Journey: PropTypes.shape({}),
};

CtbpMovement.defaultProps = {
  flight: null,
  booking: null,
  journey: null,
  persons: [],
  baggage: null,
  bookingVsIntent: 0,
  s4Person: null,
  s4Journey: null,
};

export default memo(CtbpMovement);
