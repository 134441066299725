// Global import(s)
import { Heading, ErrorSummary } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { forwardRef, useState, useEffect, useLayoutEffect, useContext } from 'react';

// Config(s)
import { COMPONENT_ORDER } from '../../forms/constants';
import { VIEW } from '../../utils/constants';

// Context(s)
import { PermissionContext } from '../../context/PermissionContext';
import { useView } from '../../context/ViewContext';
import { useTabs } from '../../context/TabContext';

// Component(s)
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import FilterControl from './FilterControl';

// Hook(s)
import { useSetupComponentErrors, useSetupFilterCounts } from './hooks/Filter.hooks';

// Util(s)
import cleanPayload from './Custom/Data/cleanPayload';
import elevateNestedComponents from './Custom/Util/elevateNestedComponents';
import getComponent from './Custom/Component/getComponent';
import getVisibleComponents from './Custom/Component/getVisibleComponents';
import orderComponents from './Custom/Component/orderComponents';
import setupComponent from './Custom/Component/setupComponent';
import setupCustomActions from './Custom/Component/setupCustomActions';
import setupRefDataOptions from './Custom/Data/setupRefDataOptions';
import toCustomActions from './Custom/Component/toCustomActions';
import validateComponent from './Custom/Validate/validateComponent';
import setUpCustomOptions from './Custom/Component/setupCustomOptions';

const InternalFilter = forwardRef((props, ref) => {
  const {
    form: _form,
    data: _data,
    filterCounts,
    groupCounts,
    customOptions: _customOptions,
    onApply,
    onClear,
    filterControlStyles,
    showFilterControlsAtTop,
    isToolsTab,
  } = props;
  const [errorList, setErrorList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState(_data);
  const { selectedTab } = useTabs();
  const { view } = useView();
  const { isLiveServicesUser } = useContext(PermissionContext);

  setupRefDataOptions(_form.pages[0].components);
  setUpCustomOptions(_form.pages[0].components, _customOptions);
  let form = useSetupFilterCounts(_form, selectedTab, filterCounts, groupCounts, view);
  form = useSetupComponentErrors(form, errorList);
  const visibleComponents = orderComponents(getVisibleComponents(form.pages[0].components, data), COMPONENT_ORDER[view]);

  const validate = () => {
    const allComponents = elevateNestedComponents(visibleComponents, data);
    const errors = [];
    allComponents.forEach((component) => {
      const { message } = validateComponent(component, data);
      if (component?.required && !!message) {
        errors.push({
          id: component.id,
          error: message,
        });
      }
    });
    if (errors.length) {
      setErrorList(errors);
      setHasError(true);
      return false;
    }
    setErrorList([]);
    setHasError(false);
    return true;
  };

  const onInternalApply = () => {
    const payload = cleanPayload(visibleComponents, visibleComponents, data);
    if (!validate()) {
      return;
    }

    onApply(payload);
  };

  // Set up a custom action to be given directly to the required component.
  setupCustomActions(visibleComponents, toCustomActions({
    onApply: onInternalApply,
  }));

  const onChange = ({ target }) => {
    setData((prev) => {
      return { ...prev, [target.name]: target.value };
    });
  };

  const onInternalClear = (e) => {
    e.preventDefault();
    onClear(e);
    setErrorList([]);
    setHasError(false);
  };

  useLayoutEffect(() => {
    if (ref?.current) {
      ref.current.focus();
    }
  }, []);

  useEffect(() => {
    if (_data !== data) {
      setData(_data);
    }
  }, [_data]);

  return (
    <div className="cop-filters-container" ref={ref}>
      <ComponentWrapper show={!isLiveServicesUser || ![VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.IDP, VIEW.COMMODITIES, VIEW.CTBP, VIEW.OSDT].includes(view)}>
        <div className="filters-header">
          <Heading id="cop-filter-header" size="m" useHodsTag>Filters</Heading>
        </div>
      </ComponentWrapper>
      <ComponentWrapper show={isToolsTab}>
        <div className="tools-header">
          <Heading id="cop-tools-header" size="m">Delete matches</Heading>
        </div>
      </ComponentWrapper>
      {hasError && <ErrorSummary errors={errorList} />}
      <ComponentWrapper show={showFilterControlsAtTop}>
        <FilterControl
          id="filter-controls-top"
          idSuffix="top"
          className="govuk-!-margin-bottom-6 filter-controls"
          onApply={() => {
            if (validate()) {
              onInternalApply();
            }
          }}
          onClear={(e) => onInternalClear(e)}
        />
      </ComponentWrapper>
      <div className="filter-content">
        {visibleComponents.map((component, index) => {
          const { wrapperOptions, componentOptions } = setupComponent(data, component, onChange);
          return getComponent(index, component, wrapperOptions, componentOptions, data);
        })}
      </div>
      <FilterControl
        id="filter-controls-bottom"
        idSuffix="bottom"
        className="filter-controls"
        filterControlStyles={filterControlStyles}
        onApply={() => {
          if (validate()) {
            onInternalApply();
          }
        }}
        onClear={(e) => onInternalClear(e)}
      />
    </div>
  );
});

InternalFilter.propTypes = {
  form: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
  filterCounts: PropTypes.shape({
    movementModeCounts: PropTypes.arrayOf(PropTypes.shape({})),
    taskGenerationTypeCounts: PropTypes.arrayOf(PropTypes.shape({})),
    passengerStatusCounts: PropTypes.arrayOf(PropTypes.shape({})),
    targetingTeamCounts: PropTypes.arrayOf(PropTypes.shape({})),
    ruleCounts: PropTypes.arrayOf(PropTypes.shape({})),
    selectorGroupReferencesCounts: PropTypes.arrayOf(PropTypes.shape({})),
    portOfArrivalCounts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  groupCounts: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    groupCode: PropTypes.string,
  })),
  customOptions: PropTypes.shape({}),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  showFilterControlsAtTop: PropTypes.bool,
  filterControlStyles: PropTypes.shape({}),
  isToolsTab: PropTypes.bool,
};

InternalFilter.defaultProps = {
  data: {},
  filterCounts: {},
  groupCounts: [],
  customOptions: {},
  showFilterControlsAtTop: true,
  isToolsTab: false,
  filterControlStyles: {
    clearFiltersText: 'Clear filters',
  },
};

export default InternalFilter;
