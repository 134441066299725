var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
// Config(s)
import { PATHS, TAB_ID, STRINGS, LOCAL_STORAGE_KEYS, FILTER_TAB_ID, VIEW } from '../../utils/constants';
import DEFAULTS from '../../routes/Task/TaskList/shared/constants';
// Context(s)
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';
// Component
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import CreateTaskButton from '../../routes/Task/TaskList/air/components/shared/buttons/CreateTaskButton';
import LabelField from '../../routes/Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelField';
import ShowHideFiltersButton from '../../routes/Task/TaskList/roro/components/ShowHideFilters';
// Hook
import useSetTabTitle from '../../utils/Hooks/useSetTabTitle';
// Styling
import './TaskManagementHeader.scss';
const TaskManagementHeader = (_a) => {
    var { headerLabel, size = 'xl', links = [] } = _a, props = __rest(_a, ["headerLabel", "size", "links"]);
    const { hasRole } = usePermission();
    const { setSelectedTab, setTabIndex, setSelectedFilterTab } = useTabs();
    const { setSelectedForDelete, setRules } = useTasks();
    const { view, setView } = useView();
    const navigate = useNavigate();
    useSetTabTitle(headerLabel);
    const availableLinks = links.filter((link) => { var _a; return !!link && link.show && hasRole((_a = link.rbac) === null || _a === void 0 ? void 0 : _a.readRole); });
    const idp = availableLinks.find((l) => l.label === 'IDP');
    if (idp) {
        idp.label = 'Documents';
    }
    const onClick = (linkView) => {
        if (![STRINGS.TASK_LINK_HEADERS.ADMIN, STRINGS.TASK_LINK_HEADERS.AIRPAX].includes(linkView)) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.VIEW_SWAP, linkView);
        }
        const viewLink = availableLinks.find((l) => l.view === linkView);
        setView(viewLink.view);
        setTabIndex(0);
        setSelectedTab(TAB_ID.NEW);
        setSelectedFilterTab(FILTER_TAB_ID.FILTERS);
        setSelectedForDelete([]);
        setRules(null);
        localStorage.removeItem(DEFAULTS[view].filters.key);
        navigate(viewLink.url);
    };
    const toSelectComponent = (link) => (_jsx("option", { value: link.view, disabled: view === link.view, children: link.label }, link.view));
    const onChange = (e) => {
        var _a, _b;
        const selectedIndex = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.selectedIndex;
        const data = (_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b[selectedIndex];
        return onClick(data.value);
    };
    const currentView = () => {
        switch (view) {
            case VIEW.IDP: {
                return 'Documents';
            }
            case VIEW.COMMODITIES: {
                return 'Commodities';
            }
            default: {
                return view;
            }
        }
    };
    const optionsToShow = () => availableLinks.map(toSelectComponent);
    return (_jsxs("div", Object.assign({ className: "heading-container" }, props, { children: [_jsx(Heading, { id: "task-management-header", size: size, children: headerLabel }), _jsx(ShowHideFiltersButton, {}), _jsx(ComponentWrapper, { show: view === VIEW.GENERAL_AVIATION, children: _jsx(CreateTaskButton, { className: "govuk-!-font-weight-bold float-right", onClick: () => navigate(`${DEFAULTS[view].redirectPath}${PATHS.CREATE_GENERAL_AVIATION_TASK}`) }) }), _jsx(ComponentWrapper, { show: availableLinks.length > 0, children: _jsxs("div", { className: "grid-item select-task-group", children: [_jsx(LabelField, { "aria-label": "view mode", className: "select-labels", id: "select-task-label", value: "View mode", classNames: undefined }), _jsxs("select", { "aria-labelledby": "select-task-label", "aria-label": "dropdown list of modes", defaultValue: currentView(), id: "select-task", name: "select-task", onChange: onChange, children: [_jsx("option", { disabled: true, children: currentView() }), optionsToShow()] })] }) })] })));
};
export default memo(TaskManagementHeader);
