import { Button, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config
import { PNC_OUTCOMES } from '../../../../../../../../utils/TargetInformation/constants';

// Context(s)
import { useChecksValidation } from '../../../../../../../../context/ChecksValidationContext';

// Component
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LinkButton from '../../../../../../../../components/Buttons/LinkButton';

// Hook(s)
import useScreenSize from '../../../../../../../../utils/Hooks/useScreenSize';

// Util(s)
import toAccordionRow from '../../../helper/toAccordionRow';
import {
  toFormattedOutcome,
  toFormattedPncType,
  toFormattedValue,
  toRecordsFound,
} from '../../../../../../../../utils/PNC/adaptPncContent';

// Styling
import './PncCheckSummary.scss';

const toPncChangeElementId = (passengerPosition) => {
  return `passenger-${passengerPosition}-change-pnc`;
};

const FormGroupWrapper = ({ passengerPositionedAt, errors, containerId, children }) => {
  return (
    <FormGroup
      id={toPncChangeElementId(passengerPositionedAt)}
      label={<></>}
      error={errors?.[containerId]?.[toPncChangeElementId(passengerPositionedAt)]}
    >
      {children}
    </FormGroup>
  );
};

const AddPncCheck = ({ pncOutcome, isNineteenTwentyOrMorePixels, isEditable, passengerPositionedAt, containerId, errors, onPncChange }) => {
  const buttonProps = {
    id: toPncChangeElementId(passengerPositionedAt),
    label: 'Add PNC check result',
    onClick: (event) => onPncChange(event, passengerPositionedAt),
    ariaLabel: 'add pnc check result',
  };

  return (
    <ComponentWrapper show={!pncOutcome && isEditable}>
      <FormGroupWrapper passengerPositionedAt={passengerPositionedAt} errors={errors} containerId={containerId}>
        <ComponentWrapper show={isNineteenTwentyOrMorePixels}>
          <Button
            id={buttonProps.id}
            className="pnc-check-container-button"
            classModifiers="secondary"
            onClick={buttonProps.onClick}
            aria-label={buttonProps.ariaLabel}
          >
            {buttonProps.label}
          </Button>
        </ComponentWrapper>
        <ComponentWrapper show={!isNineteenTwentyOrMorePixels}>
          <LinkButton
            id={buttonProps.id}
            className="govuk-link govuk-!-margin-bottom-6 pnc-check-container-button"
            onClick={buttonProps.onClick}
            aria-label={buttonProps.ariaLabel}
          >
            {buttonProps.label}
          </LinkButton>
        </ComponentWrapper>
      </FormGroupWrapper>
    </ComponentWrapper>
  );
};

const PncCheckSummary = ({ formData, containerId, passengerPositionedAt, onPncChange, isEditable }) => {
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { errors } = useChecksValidation();
  const policeNationalComputerCheck = formData?.[containerId]?.pnc;
  const pncOutcome = policeNationalComputerCheck?.pncOutcome;

  const containsRelevantPncOutcome = () => {
    return policeNationalComputerCheck?.pncOutcome
      && policeNationalComputerCheck?.pncOutcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND;
  };

  const toWarningTypes = () => {
    const EXCLUDE = ['STAFF_CONCERN', 'POI_CONCERN'];
    return policeNationalComputerCheck?.pncDetails?.types?.filter((item) => !EXCLUDE.includes(item) && !!item) || [];
  };

  const toSummary = () => {
    if (!containsRelevantPncOutcome()) {
      return <p id={`passenger-${passengerPositionedAt}-pnc-outcome`}>{toFormattedOutcome(policeNationalComputerCheck?.pncOutcome)}</p>;
    }

    return (
      <dl id={`pnc-checks-passenger-${passengerPositionedAt}-summary`} className="govuk-summary-list">
        {toAccordionRow(
          'Relevant records found',
          '',
          `passenger-${passengerPositionedAt}-records-found`,
        )}
        {toAccordionRow(
          'PNC ID:',
          toFormattedValue(policeNationalComputerCheck?.pncFound?.pncId),
          `passenger-${passengerPositionedAt}-pnc-id`,
        )}
        {toAccordionRow(
          'Safety Warnings:',
          toWarningTypes().map((element) => toFormattedPncType(element)).join(', ') || 'None',
          `passenger-${passengerPositionedAt}-pnc-safety-warnings`,
        )}
        {toAccordionRow(
          'Concerns for the safety of frontline staff?',
          toFormattedValue(policeNationalComputerCheck?.pncDetails?.staffConcerns),
          `passenger-${passengerPositionedAt}-pnc-staff-safety-concern`,
        )}
        {toAccordionRow(
          'Potentially involved in:',
          toRecordsFound(policeNationalComputerCheck?.pncCategories),
          'pnc-involvement',
          `passenger-${passengerPositionedAt}-pnc-involvement`,
        )}
      </dl>
    );
  };

  return (
    <div className="pnc-check-summary">
      <div className="pnc-check-header">
        <ComponentWrapper show={pncOutcome}>
          <Heading size="s">PNC Check</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={pncOutcome && isEditable}>
          <LinkButton
            id={toPncChangeElementId(passengerPositionedAt)}
            className="govuk-link"
            onClick={(event) => onPncChange(event, passengerPositionedAt)}
          >
            Change
          </LinkButton>
        </ComponentWrapper>
      </div>
      <AddPncCheck
        isNineteenTwentyOrMorePixels={isNineteenTwentyOrMorePixels}
        isEditable={isEditable}
        onPncChange={onPncChange}
        passengerPositionedAt={passengerPositionedAt}
        containerId={containerId}
        pncOutcome={pncOutcome}
        errors={errors}
      />
      <ComponentWrapper show={pncOutcome}>
        {toSummary()}
      </ComponentWrapper>
    </div>
  );
};

PncCheckSummary.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  containerId: PropTypes.string.isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
  onPncChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

AddPncCheck.propTypes = {
  pncOutcome: PropTypes.string.isRequired,
  isNineteenTwentyOrMorePixels: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  onPncChange: PropTypes.func.isRequired,
};

AddPncCheck.defaultProps = {
  errors: null,
};

FormGroupWrapper.propTypes = {
  passengerPositionedAt: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  containerId: PropTypes.string.isRequired,
};

FormGroupWrapper.defaultProps = {
  errors: null,
};

export default PncCheckSummary;

export { toPncChangeElementId };
