// Global import(s)
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { groupBy, pick } from 'lodash';

// Config(s)
import { VIEW } from '../../utils/constants';
import { FILTER_COUNT_TYPES, FILTER_TYPES } from '../../routes/Task/TaskList/shared/constants';

// Context(s)
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Component(s)
import InternalFilter from './InternalFilter';

// Hook(s)
import useFilters from './hooks/Filter.hooks';

// Util(s)
import FormUtils from '../../utils/Form/ReactForm';

// JSON
import copFilter from '../../forms/copFilter';
import tasksFilter from '../../forms/tasksFilter';

// Styling
import './Filter.scss';

const Filter = forwardRef((props, ref) => {
  const { currentUser, data, filterCounts, groupCounts, customOptions, groups, onApply, onClear } = props;
  const { selectedTab } = useTabs();
  const { view } = useView();
  const { filtersData } = useFilters(data, filterCounts);

  if (!view) {
    return null;
  }

  const groupedFilterCounts = groupBy(filterCounts, 'counterType');
  const adaptedGroupedFilterCounts = Object.fromEntries(
    Object.entries(groupedFilterCounts)
      .map(([key, value]) => [FILTER_COUNT_TYPES[key] || key, value]),
  );

  if ([VIEW.AIRPAX_RCCU, VIEW.AIRPAX_HO, VIEW.AIRPAX_IO, VIEW.RORO_IO].includes(view)) {
    return (
      <InternalFilter
        form={copFilter(groups, view)}
        data={data}
        groupCounts={groupCounts}
        customOptions={customOptions}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  if ([VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.IDP, VIEW.CTBP, VIEW.COMMODITIES, VIEW.OSDT].includes(view)) {
    return (
      <InternalFilter
        form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), view)}
        data={filtersData}
        filterCounts={adaptedGroupedFilterCounts}
        customOptions={pick(customOptions, FILTER_TYPES.RULES, FILTER_TYPES.TARGETING_TEAMS)}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  if ([VIEW.GENERAL_AVIATION].includes(view)) {
    return (
      <InternalFilter
        form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), VIEW.GENERAL_AVIATION)}
        data={filtersData}
        filterCounts={adaptedGroupedFilterCounts}
        customOptions={pick(customOptions, FILTER_TYPES.RULES, FILTER_TYPES.TARGETING_TEAMS)}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  // RoRo view
  return (
    <InternalFilter
      form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), VIEW.RORO)}
      data={filtersData}
      filterCounts={adaptedGroupedFilterCounts}
      customOptions={pick(customOptions, FILTER_TYPES.RULES, FILTER_TYPES.SELECTOR_GROUP_REFERENCES, FILTER_TYPES.PORT_OF_ARRIVAL)}
      ref={ref}
      onApply={onApply}
      onClear={onClear}
    />
  );
});

Filter.propTypes = {
  currentUser: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  filterCounts: PropTypes.arrayOf(PropTypes.shape({})),
  groupCounts: PropTypes.arrayOf(PropTypes.shape({})),
  groups: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  data: PropTypes.shape({}),
  filterCounts: [],
  groupCounts: [],
  customOptions: PropTypes.shape({}),
  groups: [],
};

export default Filter;
