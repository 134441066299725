import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { SYSTEM_CHECKS_LABELS } from '../../../../../../../Target/TargetsListPage/constants';

// Component(s)
import Table from '../../../../../../../../components/Table/Table';

// Util(s)
import { CredibilityChecksUtil } from '../../../../../../../../utils';

// Styling
import './OtherChecksSummary.scss';

const OtherChecksSummary = ({ passenger, passengerPositionedAt }) => {
  const credibilityChecks = CredibilityChecksUtil.getChecksWithoutPnc(passenger);
  const checksCompleted = credibilityChecks.filter((credibilityCheck) => !!credibilityCheck.result);
  const checksNotCompleted = credibilityChecks.filter((credibilityCheck) => !credibilityCheck.result);

  const formatTrace = (trace) => {
    if (trace) {
      return 'Trace';
    }
    return 'No trace';
  };

  const toChecksCompletedRows = () => {
    return checksCompleted.map((checkCompleted) => {
      if (checkCompleted.type === 'TRACE') {
        return [
          SYSTEM_CHECKS_LABELS[checkCompleted.system],
          formatTrace(checkCompleted.result.trace),
          checkCompleted.result.comment,
        ];
      }

      if (checkCompleted.type === 'OTHER') {
        return [
          checkCompleted.result.systemChecked,
          formatTrace(checkCompleted.result.trace),
          checkCompleted.result.comment,
        ];
      }

      return null;
    });
  };

  const toChecksCompleted = () => {
    if (!checksCompleted?.length) {
      return null;
    }

    return (
      <div id={`passenger-${passengerPositionedAt}-checks-completed`}>
        <Heading size="s">Other checks completed</Heading>
        <Table
          className="checks-summary-completed"
          headings={['Check', 'Result', 'Comment']}
          rows={[
            ...toChecksCompletedRows(),
          ]}
        />
      </div>
    );
  };

  const toChecksNotCompleted = () => {
    if (!checksNotCompleted?.length) {
      return null;
    }

    return (
      <div id={`passenger-${passengerPositionedAt}-checks-not-completed`}>
        <Heading size="s">Checks not completed</Heading>
        {checksNotCompleted.map((checkNotCompleted) => (
          <p
            key={`check-not-completed-passenger-${passengerPositionedAt}-check-${checkNotCompleted.system}`}
            id={`passenger-${passengerPositionedAt}-check-not-completed-${checkNotCompleted.system}`}
          >
            {SYSTEM_CHECKS_LABELS[checkNotCompleted.system]}
          </p>
        ))}
      </div>
    );
  };

  const toChecksSummary = () => {
    if (CredibilityChecksUtil.notRequired(passenger?.credibilityChecks)) {
      return (
        <div id={`passenger-${passengerPositionedAt}-checks-not-required`}>
          <Heading size="s">Credibility checks not necessary</Heading>
          <p id={`passenger-${passengerPositionedAt}-checks-not-required-reason`}>
            {CredibilityChecksUtil.notRequiredReason(passenger?.credibilityChecks)}
          </p>
        </div>
      );
    }

    return (
      <>
        {toChecksCompleted()}
        {toChecksNotCompleted()}
      </>
    );
  };

  return (
    <div id={`passenger-${passengerPositionedAt}-checks-summary`}>
      {toChecksSummary()}
    </div>
  );
};

OtherChecksSummary.propTypes = {
  passenger: PropTypes.shape({}).isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
};

export default OtherChecksSummary;
