import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Global import(s)
import { Heading, Link } from '@ukhomeoffice/cop-react-components';
import { useParams } from 'react-router-dom';
// Config(s)
import { TIME_FORMATS, STRINGS } from '../../../../../../../../../utils/constants';
// Components
import Baggage from '../baggage/Baggage';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Contents from '../../content/Contents';
import DepartureStatus from '../../../../../../../../../components/DepartureStatus/DepartureStatus';
import Details from '../../generics/Details';
import Documents from './Documents';
import EntitySearchModal from '../../prompts/EntitySearchModal';
import HeaderModule from '../../generics/HeaderModule';
import LabelValuePair from '../../generics/LabelValuePair';
import ValueField from '../../generics/ValueField';
// Util(s)
import BookingUtil from '../../../../../../../../../utils/Booking/bookingUtil';
import CommonUtil from '../../../../../../../../../utils/Common/Uplift/commonUtil';
import DateTimeUtil from '../../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import DocumentUtil from '../../../../../../../../../utils/Document/Uplift/documentUtil';
import FlightUtil from '../../../../../../../../../utils/Flight/Uplift/flightUtil';
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import PersonUtil from '../../../../../../../../../utils/Person/Uplift/personUtil';
import PersonS4Util from '../../../../../../../../../utils/Person/personS4Util';
import { getMaskedValue } from '../../../../../../../../../utils/Masking/maskingUtil';
import { isPersonFullNameMasked, toAgeAtTravel, toDateOfBirth } from './helper/common';
const Person = ({ person, booking, flight, journey, movementId, movementIds, positionedAt, s4Person, title }) => {
    const { taskId } = useParams();
    const adjustedPositionedAt = positionedAt + 1;
    const additionalContent = PersonUtil.additionalContent(person);
    const baggage = PersonUtil.baggage(person);
    const documents = DocumentUtil.getDocs(person);
    const fullName = PersonUtil.formattedName(PersonUtil.firstname(person))(PersonUtil.lastname(person, true));
    const { otherMovementsUrl, movementCount } = CommonUtil.otherMovementsLinkParams(person);
    const movementSearchLabel = CommonUtil.otherMovementsLabel(movementCount);
    const otherTasksListURL = CommonUtil.otherTasksListURL(CommonUtil.otherTasksLinkParams(movementId, person, taskId, movementIds));
    const otherTasksURLLabel = CommonUtil.otherTasksListLabel(person);
    const departureStatus = PersonUtil.passengerStatus(person);
    const iso2NationalityCode = PersonUtil.nationality(person);
    const formattedNationality = CommonUtil.format.nationality(iso2NationalityCode);
    const iso3NationalityCode = CommonUtil.iso3Code(iso2NationalityCode);
    const departureLocation = JourneyUtil.departureLoc(journey);
    const departureTime = JourneyUtil.departureTime(journey);
    const gender = PersonUtil.gender(person);
    const formattedGender = PersonUtil.format.gender(gender);
    const seatNumber = FlightUtil.seatNumber(flight);
    const frequentFlyerNumber = PersonUtil.frequentFlyerNumber(person);
    const dateOfBirth = PersonUtil.dob(person);
    const checkInTime = BookingUtil.checkInAt(booking);
    const typeIdentifer = PersonS4Util.typeIdentifer(s4Person);
    const portOfEmbark = PersonS4Util.portOfEmbark(s4Person);
    const distinct = PersonS4Util.distinct(s4Person);
    const typeCode = PersonS4Util.typeCode(s4Person);
    const sipURL = CommonUtil.sipEntitySearchURL(person);
    const centaurURL = CommonUtil.centaurEntitySearchURL(person);
    const toCheckInTime = () => {
        if (!departureLocation || !checkInTime) {
            return null;
        }
        if ([departureLocation, checkInTime].includes(getMaskedValue())) {
            return getMaskedValue();
        }
        const timezone = DateTimeUtil.timezone(departureLocation);
        return DateTimeUtil.format.toTimezone(checkInTime, timezone, TIME_FORMATS.BOOKING_CHECK_IN);
    };
    const toCleanedDifference = (value) => {
        const CLEAN_STRINGS = [STRINGS.DAYJS_FUTURE, STRINGS.DAYJS_PAST];
        if (value) {
            CLEAN_STRINGS.forEach((string) => {
                value = value.replace(string, '');
            });
        }
        return value;
    };
    const toCheckInDifference = () => {
        const timezone = DateTimeUtil.timezone(departureLocation);
        if (!departureLocation || !timezone || !departureTime || !checkInTime) {
            return null;
        }
        if ([departureLocation, departureTime, checkInTime].includes(getMaskedValue())) {
            return getMaskedValue();
        }
        const departureLocalDate = DateTimeUtil.local.date(departureTime, timezone);
        const checkInLocalDate = DateTimeUtil.local.date(checkInTime, timezone);
        const difference = departureLocalDate.from(checkInLocalDate);
        return `${toCleanedDifference(difference)}pre-departure`;
    };
    if (!person) {
        return null;
    }
    const toDistinct = () => {
        return distinct === null || distinct === void 0 ? void 0 : distinct.toString();
    };
    const idPrefix = `${title.toLowerCase()}-${adjustedPositionedAt}`;
    return (_jsxs("div", { id: `${idPrefix}-module`, className: "govuk-grid-column-full", children: [_jsx(HeaderModule, { id: `${idPrefix}-details`, items: [
                    {
                        content: (_jsxs("div", { className: "grid-item", children: [_jsx(Heading, { id: `${idPrefix}-header`, size: "m", children: `${title} ${adjustedPositionedAt}` }), _jsx(DepartureStatus, { id: `${idPrefix}-departure-status`, departureStatus: departureStatus })] }, "1")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: fullName, children: _jsxs("div", { className: "grid-item", children: [_jsx(ComponentWrapper, { show: !sipURL && !centaurURL, children: _jsx(ValueField, { id: `${idPrefix}-fullname`, value: fullName }) }), _jsx(ComponentWrapper, { show: sipURL || centaurURL, children: !isPersonFullNameMasked(PersonUtil.firstname(person)) && !isPersonFullNameMasked(PersonUtil.lastname(person, true)) ? (_jsx(EntitySearchModal, { id: `${idPrefix}-fullname`, modalHeading: `Search full name ${fullName} on:`, label: fullName, sipURL: sipURL, centaurURL: centaurURL })) : _jsx(ValueField, { id: `document-${idPrefix}-number`, value: fullName }) })] }) }, "2")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: otherMovementsUrl && movementSearchLabel, children: _jsx("div", { className: "grid-item", children: _jsx(Link, { id: `${idPrefix}-movement-link`, href: otherMovementsUrl, target: "_blank", rel: "noreferrer noopener", children: movementSearchLabel }) }) }, "3")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: otherTasksListURL && otherTasksURLLabel, children: _jsx("div", { className: "grid-item", children: _jsx(Link, { id: `${idPrefix}-other-tasks-link`, href: otherTasksListURL, target: "_blank", rel: "noreferrer noopener", children: otherTasksURLLabel }) }) }, "5")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: title === 'Passenger', children: _jsxs("div", { className: "grid-item", id: person.taskId, children: ["Task ID: ", person.taskId] }) })),
                    },
                ] }), _jsx(Details, { items: [
                    {
                        content: (_jsx(ComponentWrapper, { show: iso2NationalityCode && formattedNationality, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-nationality`, label: "Nationality", value: `${formattedNationality} - ${iso3NationalityCode}` }) }) }, "1")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: gender, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-gender`, label: "Gender", value: formattedGender }) }) }, "2")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: dateOfBirth && departureTime, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-age`, label: "Age at travel", value: toAgeAtTravel(dateOfBirth, departureTime) }) }) }, "3")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: dateOfBirth, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-dob`, label: "Date of birth", value: toDateOfBirth(dateOfBirth) }) }) }, "4")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: checkInTime && positionedAt === 0, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-checked-in`, label: "Checked in", value: toCheckInTime(), secondaryValue: toCheckInDifference() }) }) }, "5")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: seatNumber && positionedAt === 0, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-seat-number`, label: "Seat number", value: seatNumber }) }) }, "6")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: frequentFlyerNumber && positionedAt === 0, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-frequent-flyer-number`, label: "Frequent Flyer Number", value: frequentFlyerNumber }) }) }, "7")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: typeIdentifer, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-passenger-type-identifer`, label: "Passenger Identifier Type", value: typeIdentifer }) }) }, "8")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: portOfEmbark, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-port-of-embark`, label: "Port of embark", value: portOfEmbark }) }) }, "9")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: distinct !== null, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-distinct-flag`, label: "Distinct", value: toDistinct() }) }) }, "10")),
                    },
                    {
                        content: (_jsx(ComponentWrapper, { show: typeCode, children: _jsx("div", { className: "grid-item", children: _jsx(LabelValuePair, { id: `${idPrefix}-passenger-type-code`, label: "Passenger Type Code", value: typeCode }) }) }, "11")),
                    },
                ] }), _jsx(Contents, { id: `${idPrefix}-contents`, contents: additionalContent }), _jsx(Documents, { documents: documents, journey: journey, movementId: movementId, movementIds: movementIds }), _jsx(Baggage, { baggage: baggage, type: title, positionedAt: positionedAt })] }));
};
export default Person;
