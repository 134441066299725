import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Config
import { TARGET_OPERATION } from '../../../../../../../../../utils/constants';

// Component(s)
import LoadingSpinner from '../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../../../../components/RenderForm/RenderForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import addDataToComponent from '../helper/addDataToComponent';
import containsRecipient from '../validate/containsRecipient';
import { Renderers } from '../../../../../../../../../utils/Form/ReactForm';

// Form(s)
import targetRecipient from '../../../../../../../../../forms/uplift/targetRecipient';

const CommoditiesAddRecipient = ({ actionType, onSave, formData, setFormData, onCancel, targetRecipientOptions }) => {
  const formWithoutTargetRecipientOptions = targetRecipient(!formData.targetRecipients?.length);
  const form = addDataToComponent(formWithoutTargetRecipientOptions, 'FRONTLINE_TEAM', targetRecipientOptions);

  setTabTitle(TAB_TITLES.ADD_TARGET_SHEET_RECIPIENT);

  const onInternalSaveAndClose = (data) => {
    if (containsRecipient(formData, data)) {
      // Do not save the data, instead call the onSave callback.
      if (typeof onSave === 'function') {
        onSave();
        return;
      }
    }

    const saveTargetRecipients = (prev) => {
      if (data?.FRONTLINE_TEAM) {
        const newFrontlineTeam = Array.isArray(data.FRONTLINE_TEAM) ? data.FRONTLINE_TEAM : [data.FRONTLINE_TEAM];
        const prevName = prev.targetRecipients.map((recipient) => recipient.customName);
        return {
          targetRecipients: [
            ...(prev?.targetRecipients ? prev.targetRecipients : []),
            ...newFrontlineTeam.filter((team) => !prevName.includes(team.customName)),
          ],
        };
      }
      return {
        targetRecipients: [
          ...(prev?.targetRecipients ? prev.targetRecipients : []),
        ],
      };
    };

    const saveBorderOfficers = (prev) => {
      if (data?.BORDER_OFFICER) {
        const prevOfficer = prev.borderOfficers.map((officer) => officer.email);
        return {
          borderOfficers: [
            ...(prev?.borderOfficers ? prev.borderOfficers : []),
            ...data?.BORDER_OFFICER.filter((officer) => !prevOfficer.includes(officer.email)),
          ],

        };
      }
      return {
        borderOfficers: [
          ...(prev?.borderOfficers ? prev.borderOfficers : []),
        ],
      };
    };

    setFormData((prev) => {
      return {
        ...prev,
        ...(saveBorderOfficers(prev)),
        ...(saveTargetRecipients(prev)),
      };
    });

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    return () => setTabTitle(actionType === TARGET_OPERATION.CREATE ? TAB_TITLES.CREATE_TARGET : TAB_TITLES.EDIT_TARGET);
  }, []);

  if (!form) {
    return <LoadingSpinner />;
  }

  return (
    <div id="add-recipient" className="govuk-grid-row">
      <div className="govuk-grid-column-one-third">
        <RenderForm
          form={form}
          viewOnly={false}
          onSubmit={async ({ data }) => onInternalSaveAndClose(data)}
          renderer={Renderers.REACT}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

CommoditiesAddRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  formData: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

CommoditiesAddRecipient.defaultProps = {
  targetRecipientOptions: [],
};

export default CommoditiesAddRecipient;
