/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
// Global import(s)
import { Tag, VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TAB_STATUS } from '../../constants';
import { MOVEMENT_MODES, TARGET_STATUS, TARGETER_ACTIONS_MONITOR, TIME_FORMATS } from '../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import RowCell, { CombinedRowCell } from './RowCell';
import TargetListItemOptions from '../options/TargetListItemOptions';
import TargetOutcome from '../status/TargetOutcome';

// Util(s)
import DateTimeUtil from '../../../../../utils/Datetime/Uplift/datetimeUtil';
import { TargetMovementUtil, TargetPersonUtil, TargetRisksUtil, TargetTrailerUtil, TargetVehicleUtil } from '../../../../../utils';

// Styling
import './TargetRow.scss';

const toStatusLabel = (status) => {
  return !status || status === TARGET_STATUS.ASSIGNED ? (
    <div className="status-label">
      <Tag classModifiers="red">new</Tag>
    </div>
  ) : null;
};

const toTargeterAction = (informationSheet) => {
  const { remarks } = informationSheet;

  if (!remarks) {
    return null;
  }

  return remarks.toUpperCase() === TARGETER_ACTIONS_MONITOR.toUpperCase() ? (
    <div>
      <Tag className="tag-targeter-actions" classModifiers="blue">
        {remarks}
      </Tag>
    </div>
  ) : (
    <div>
      <span>{remarks}</span>
    </div>
  );
};

const isChecked = (checkedRadioBtnName, radioBtnName) => {
  return checkedRadioBtnName === radioBtnName;
};

const getRadioVisibilityClass = (status) => {
  return status === TARGET_STATUS.WITHDRAWN ? 'visibility--hidden' : null;
};

const toRadioColumn = (status, targetId, name, onChange, checkedRadioBtnName) => {
  const columnClasses = classNames(
    'govuk-radios__item',
    'govuk-radios--small',
    getRadioVisibilityClass(status),
  );

  return (
    <div className={columnClasses}>
      <input
        aria-label={`target ${targetId}`}
        type="radio"
        className="govuk-radios__input"
        id={`radio-${targetId}`}
        name={name}
        onChange={onChange}
        checked={isChecked(checkedRadioBtnName, name)}
      />
      <label
        aria-hidden="true"
        className="govuk-label govuk-radios__label"
        htmlFor={`radio-${targetId}`}
      >
        <VisuallyHidden>{targetId}</VisuallyHidden>
      </label>
    </div>
  );
};

const AirpaxRow = ({
  tableHeaders,
  target,
  handleRadioButton,
  checkedRadioBtnName,
  selectedTab,
}) => {
  const { status, informationSheet } = target;
  const radioBtnName = `target ${informationSheet.id}`;
  const selector = TargetRisksUtil.selector(informationSheet);
  const persons = TargetPersonUtil.persons(informationSheet);

  const rowClass = classNames(
    'row',
    tableHeaders.includes('Outcome') && 'with-outcome',
    isChecked(checkedRadioBtnName, radioBtnName) && 'highlighted-row',
  );

  return persons.map((person, index) => {
    const document = TargetPersonUtil.document(person);
    return (
      <div className={rowClass} key={`person-row-${index}`}>
        <div className="airpax-target-radio">
          {toRadioColumn(
            status,
            informationSheet.id,
            radioBtnName,
            (e) => handleRadioButton(e, target),
            checkedRadioBtnName,
          )}
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[1]}</span>
          <span className="ho-body">
            <RowCell value={TargetPersonUtil.lastName(person)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[2]}</span>
          <span className="ho-body">
            <RowCell value={TargetPersonUtil.docNumber(document)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[3]}</span>
          <span className="ho-body">
            <RowCell value={DateTimeUtil.format.asUTC(TargetPersonUtil.dob(person), null, TIME_FORMATS.SHORT)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[4]}</span>
          <span className="ho-body">
            <RowCell value={TargetPersonUtil.nationality(person)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[5]}</span>
          <span className="ho-body">
            <RowCell value={TargetRisksUtil.category(selector)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[6]}</span>
          <span className="ho-body">
            <RowCell value={TargetRisksUtil.pncWarning(informationSheet)} />
          </span>
        </div>
        <div>
          <span className="tablet-heading ho-secondary-text-s">{tableHeaders[7]}</span>
          {selectedTab === TAB_STATUS.COMPLETE ? (
            <TargetOutcome targetSheet={target} isTargetList />
          ) : null}
        </div>
        <div className="airpax-target-id-tablet">
          <span className="tablet-heading ho-secondary-text-s">Target ID</span>
          <span className="ho-body">
            <RowCell value={informationSheet.id} />
          </span>
        </div>
        <div className="airpax-target-id">
          <span className="ho-secondary-text-xs">
            Target ID:{informationSheet.id}
          </span>
        </div>
      </div>
    );
  });
};

const RoRoRow = ({ tableHeaders, target, handleRadioButton, checkedRadioBtnName }) => {
  const { status, informationSheet } = target;
  const radioBtnName = `target ${informationSheet.id}`;
  const mode = TargetMovementUtil.refDataMode(informationSheet);
  const vehicle = TargetVehicleUtil.get(informationSheet);
  const trailer = TargetTrailerUtil.get(informationSheet);
  const persons = TargetPersonUtil.persons(informationSheet);
  const selector = TargetRisksUtil.selector(informationSheet);

  return persons.map((person) => {
    return (
      <tr
        role="row"
        key={informationSheet.id}
        className={classNames(
          'hods-table__row',
          isChecked(checkedRadioBtnName, radioBtnName) && 'highlighted-row',
        )}
      >
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[0]}</span>
          {toRadioColumn(
            status,
            informationSheet.id,
            radioBtnName,
            (e) => handleRadioButton(e, target),
            checkedRadioBtnName,
          )}
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[1]}:&nbsp;&nbsp;</span>
          <RowCell value={mode} />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[2]}:&nbsp;&nbsp;</span>
          <RowCell value={TargetVehicleUtil.make(vehicle)} />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[3]}:&nbsp;&nbsp;</span>
          <RowCell
            value={(
              <CombinedRowCell
                value1={<>{TargetVehicleUtil.registration(vehicle)}&nbsp;&nbsp;</>}
                value2={TargetTrailerUtil.registration(trailer)}
              />
            )}
          />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[4]}:&nbsp;&nbsp;</span>
          <RowCell value={TargetPersonUtil.lastName(person)} />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[5]}:&nbsp;&nbsp;</span>
          <RowCell value={TargetRisksUtil.category(selector)} />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[6]}:&nbsp;&nbsp;</span>
          <RowCell value={TargetRisksUtil.pncWarning(informationSheet)} />
        </td>
        <td role="cell" className="hods-table__cell">
          <span className="hods-table__heading">{tableHeaders[7]}:&nbsp;&nbsp;</span>
          <RowCell
            value={(
              <CombinedRowCell
                value1={<>{toStatusLabel(status)}&nbsp;</>}
                value2={toTargeterAction(informationSheet)}
              />
            )}
          />
        </td>
      </tr>
    );
  });
};

const MenuRow = ({ actions, columnSpan, selectedTarget }) => {
  const {
    handleOnRCCUReceipt,
    handleOnReceipt,
    handleOnAccept,
    handleViewDetails,
    handleRecordOutcome,
    handleAssign,
    handleReAssign,
    handleOnCapture,
    handleUndoCapture,
  } = actions;
  const { informationSheet } = selectedTarget;
  const targetId = TargetMovementUtil.targetId(informationSheet);

  return (
    <tr
      id={`target-menu-${targetId}`}
      aria-label={`actions for target ${targetId}`}
      role="row"
      className="hods-table__row"
    >
      <td role="cell" className="hods-table__cell target-menu" colSpan={columnSpan} aria-label={`actions for target ${targetId}`}>
        <TargetListItemOptions
          handleOnRCCUReceipt={handleOnRCCUReceipt}
          handleOnReceipt={handleOnReceipt}
          handleOnAccept={handleOnAccept}
          handleViewDetails={handleViewDetails}
          handleRecordOutcome={handleRecordOutcome}
          handleAssign={handleAssign}
          handleReAssign={handleReAssign}
          handleOnCapture={handleOnCapture}
          handleUndoCapture={handleUndoCapture}
          selectedTarget={selectedTarget}
        />
      </td>
    </tr>
  );
};

const AirPaxMenuRow = ({ actions, selectedTarget }) => {
  const {
    handleOnRCCUReceipt,
    handleOnReceipt,
    handleOnAccept,
    handleViewDetails,
    handleRecordOutcome,
    handleAssign,
    handleReAssign,
    handleOnCapture,
    handleUndoCapture,
  } = actions;
  return (
    <div className="airpax-target-options">
      <TargetListItemOptions
        handleOnRCCUReceipt={handleOnRCCUReceipt}
        handleOnReceipt={handleOnReceipt}
        handleOnAccept={handleOnAccept}
        handleViewDetails={handleViewDetails}
        handleRecordOutcome={handleRecordOutcome}
        handleAssign={handleAssign}
        handleReAssign={handleReAssign}
        handleOnCapture={handleOnCapture}
        handleUndoCapture={handleUndoCapture}
        selectedTarget={selectedTarget}
      />
    </div>
  );
};

const TargetRow = ({
  mode,
  target,
  tableHeaders,
  handleRadioButton,
  checkedRadioBtnName,
  selectedTab,
  handleOnRCCUReceipt,
  handleOnReceipt,
  handleOnAccept,
  handleViewDetails,
  handleRecordOutcome,
  handleAssign,
  handleReAssign,
  handleOnCapture,
  handleUndoCapture,
  selectedTarget,
}) => {
  const actions = {
    handleOnRCCUReceipt,
    handleOnReceipt,
    handleOnAccept,
    handleViewDetails,
    handleRecordOutcome,
    handleAssign,
    handleReAssign,
    handleOnCapture,
    handleUndoCapture,
  };
  const columnSpan = 8;
  const isSelected = (() => {
    const selectedInformationSheet = selectedTarget?.informationSheet;
    const informationSheet = target?.informationSheet;
    return TargetMovementUtil.targetId(selectedInformationSheet) === TargetMovementUtil.targetId(informationSheet);
  })();

  if ([MOVEMENT_MODES.ACCOMPANIED_FREIGHT, MOVEMENT_MODES.UNACCOMPANIED_FREIGHT, MOVEMENT_MODES.TOURIST].includes(mode)) {
    return (
      <>
        <RoRoRow
          tableHeaders={tableHeaders}
          target={target}
          handleRadioButton={handleRadioButton}
          checkedRadioBtnName={checkedRadioBtnName}
        />
        <ComponentWrapper show={isSelected}>
          <MenuRow actions={actions} columnSpan={columnSpan} selectedTarget={selectedTarget} />
        </ComponentWrapper>
      </>
    );
  }

  if ([MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION].includes(mode)) {
    return (
      <>
        <AirpaxRow
          tableHeaders={tableHeaders}
          target={target}
          handleRadioButton={handleRadioButton}
          checkedRadioBtnName={checkedRadioBtnName}
          selectedTab={selectedTab}
        />
        <ComponentWrapper show={isSelected}>
          <AirPaxMenuRow actions={actions} selectedTarget={selectedTarget} />
        </ComponentWrapper>
      </>
    );
  }

  return null;
};

const actionTypes = {
  handleOnCapture: PropTypes.func.isRequired,
  handleOnRCCUReceipt: PropTypes.func.isRequired,
  handleOnReceipt: PropTypes.func.isRequired,
  handleOnAccept: PropTypes.func.isRequired,
  handleUndoCapture: PropTypes.func.isRequired,
  handleRecordOutcome: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  handleReAssign: PropTypes.func.isRequired,
  handleViewDetails: PropTypes.func.isRequired,
};

const commonTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleRadioButton: PropTypes.func.isRequired,
  checkedRadioBtnName: PropTypes.string,
};

const rowType = {
  target: PropTypes.shape({
    status: PropTypes.string,
    informationSheet: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  ...commonTypes,
};

TargetRow.propTypes = {
  mode: PropTypes.string.isRequired,
  target: PropTypes.shape({
    informationSheet: PropTypes.shape({}),
  }).isRequired,
  selectedTab: PropTypes.string.isRequired,
  selectedTarget: PropTypes.shape({
    informationSheet: PropTypes.shape({}),
  }),
  ...actionTypes,
  ...commonTypes,
};

TargetRow.defaultProps = {
  selectedTarget: {},
};

MenuRow.propTypes = {
  selectedTarget: PropTypes.shape({
    informationSheet: PropTypes.shape({}),
  }),
  columnSpan: PropTypes.number,
  actions: PropTypes.shape(actionTypes).isRequired,
};

MenuRow.defaultProps = {
  selectedTarget: null,
  columnSpan: 1,
};

RoRoRow.propTypes = {
  ...rowType,
};

AirpaxRow.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  ...rowType,
};

export default TargetRow;
