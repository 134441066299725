// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Config
import { FORM_MESSAGES, TASK_STATUS } from '../../../../../utils/constants';

// Context(s)
import { useKeycloak } from '../../../../../context/Keycloak';
import { usePermission } from '../../../../../context/PermissionContext';

// Component(s)
import ActionButton from './components/ActionButton';
import ActivityLog from '../../../../../components/ActivityLog/ActivityLog';
import AddJustification from '../../Shared/components/Justifications/AddJustification';
import ClaimUnclaimTask from '../../../Common/ClaimUnclaimTask';
import CompleteTask from './components/CompleteTask';
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import DismissTask from './components/DismissTask';
import IssueTarget from './components/IssueTarget';
import RelistTask from './components/RelistTask';
import Tabs from '../../../../../components/Tabs/Tabs';
import TaskNotes from './components/TaskNotes';
import TaskOutcomeMessage from './components/TaskOutcomeMessage';
import WithdrawTask from './components/WithdrawTask';

// helpers
import toBorderEventTab from '../../Shared/helpers/toBorderEventTab';
import toTargetSheetTab from '../../Shared/helpers/toTargetSheetTab';
import toTaskVersionsTab from '../../Shared/helpers/toTaskVersionsTab';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';

// Utils
import { CommonUtil, StringUtil } from '../../../../../utils';

const InternalTaskDetailsPage = ({
  setRefreshTaskData,
  taskData,
  targetSheet,
  getTaskWithJustification,
  toCreateJustification,
  hasEab,
  borderEvent,
}) => {
  const { taskId } = useParams();
  const keycloak = useKeycloak();
  const location = useLocation();
  const currentUser = keycloak.tokenParsed.email;
  const [isSubmitted, setSubmitted] = useState(false);
  const [formStates, setFormStates] = useState({
    isCompleteTaskFormOpen: false,
    isDismissTaskFormOpen: false,
    isIssueTargetFormOpen: false,
    isRelistTaskFormOpen: false,
    isWithdrawTaskFormOpen: false,
  });
  const [showActionButtons, setShowActionButtons] = useState(true);
  const { canCreateTarget, canUpdateTask } = usePermission();
  const assignee = useMemo(() => {
    return taskData?.claimedBy?.email;
  }, [taskData]);
  const status = taskData?.status ?? null;

  const formsClosed = () => {
    const _formStates = Object.keys(formStates);
    return _formStates.every((fs) => !formStates[fs]);
  };

  const shouldDisplayNotes = () => {
    return formsClosed() && currentUser === assignee && canUpdateTask;
  };

  const shouldShowClaimButton = () => {
    return !isSubmitted
      && !formStates.isIssueTargetFormOpen
      && !formStates.isCompleteTaskFormOpen
      && !formStates.isDismissTaskFormOpen
      && [TASK_STATUS.NEW, TASK_STATUS.IN_PROGRESS].includes(status);
  };

  const shouldShowUnclaimButton = () => {
    return !isSubmitted
      && assignee === currentUser
      && canUpdateTask
      && status === TASK_STATUS.IN_PROGRESS;
  };

  const shouldShowClaimUnclaim = () => {
    return [shouldShowClaimButton(), shouldShowUnclaimButton()].some((c) => !!c);
  };

  const onCancelConfirmation = (onCancel) => {
    // eslint-disable-next-line no-alert
    if (confirm(FORM_MESSAGES.ON_CANCELLATION)) {
      CommonUtil.removePreviousFlag();
      onCancel();
      window.scrollTo(0, 0);
    }
  };

  const setActionButtons = (
    issueTargetFormOpen,
    dismissTaskFormOpen,
    completeTaskFormOpen,
    relistTaskFormOpen,
    withdrawTaskFormOpen,
    showButtons,
  ) => {
    setFormStates((prev) => {
      return {
        ...prev,
        isCompleteTaskFormOpen: completeTaskFormOpen,
        isDismissTaskFormOpen: dismissTaskFormOpen,
        isIssueTargetFormOpen: issueTargetFormOpen,
        isRelistTaskFormOpen: relistTaskFormOpen,
        isWithdrawTaskFormOpen: withdrawTaskFormOpen,
      };
    });

    setShowActionButtons(showButtons);
  };

  const updateFormState = (formStateName, value) => {
    setFormStates((prev) => {
      return {
        ...prev,
        [formStateName]: value,
      };
    });
  };

  useEffect(() => {
    if (formsClosed()) {
      setShowActionButtons(true);
      setTabTitle(TAB_TITLES.TASK_DETAILS);
    }
  }, [formStates]);

  if (!taskData) {
    return null;
  }

  return (
    <>
      <div className="govuk-grid-row govuk-task-detail-header govuk-!-padding-bottom-9">
        <div className="govuk-grid-column-one-half">
          <h1 className="govuk-heading-xl govuk-!-margin-bottom-2">Overview</h1>
          <span className="govuk-caption-xl">{taskId}</span>
          <Tag className="tag tag--task-status" text={StringUtil.format.word(taskData?.status)} />
          <ComponentWrapper show={shouldShowClaimUnclaim()}>
            <p className="govuk-body">
              <ClaimUnclaimTask
                assignee={taskData?.claimedBy?.email}
                currentUser={currentUser}
                taskId={taskData.id}
                taskStatus={taskData.status}
                source={CommonUtil.getListPath(location.pathname)}
                buttonType="textLink"
                isTaskDetails
              />
            </p>
          </ComponentWrapper>
        </div>
        <div className="govuk-grid-column-one-half task-actions--buttons">
          {!isSubmitted && assignee === currentUser
            && taskData?.status === TASK_STATUS.IN_PROGRESS
            && (
              <>
                <ActionButton
                  classes="govuk-!-margin-right-1"
                  onClick={() => {
                    setActionButtons(true, false, false, false, false, false);
                  }}
                  buttonText="Issue target"
                  permission={canCreateTarget}
                  enabled={showActionButtons}
                />

                <ActionButton
                  classes="govuk-button--secondary govuk-!-margin-right-1"
                  onClick={() => setActionButtons(false, false, true, false, false, false)}
                  buttonText="Assessment complete"
                  permission={canUpdateTask}
                  enabled={showActionButtons}
                />

                <ActionButton
                  classes="govuk-button--warning"
                  onClick={() => setActionButtons(false, true, false, false, false, false)}
                  buttonText="Dismiss"
                  permission={canUpdateTask}
                  enabled={showActionButtons}
                />
              </>
            )}
          {!isSubmitted && [TASK_STATUS.ISSUED, TASK_STATUS.RECEIVED].includes(taskData?.status) && (
            <ActionButton
              classes="govuk-button--warning"
              onClick={() => setActionButtons(false, false, false, false, true, false)}
              buttonText="Withdraw target"
              permission={canUpdateTask}
              enabled={showActionButtons}
            />
          )}
          {!isSubmitted && taskData.relistable && (
            <ActionButton
              classes="govuk-!-margin-right-1"
              onClick={() => setActionButtons(false, false, false, true, false, false)}
              buttonText="Relist task"
              permission={canUpdateTask}
              enabled={showActionButtons}
            />
          )}
        </div>
      </div>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <AddJustification
            isMaskingEnabled={!taskData?.hidingEnabled && taskData?.maskingEnabled}
            getTaskWithJustification={getTaskWithJustification}
            toCreateJustification={toCreateJustification}
          />
          {formStates.isIssueTargetFormOpen && (
            <IssueTarget
              onCancel={() => onCancelConfirmation(() => updateFormState('isIssueTargetFormOpen', false))}
              isSubmitted={isSubmitted}
              setSubmitted={() => setSubmitted(true)}
              taskData={taskData}
            >
              <TaskOutcomeMessage
                message="Target created successfully"
                messageDescription={(
                  <>
                    <p className="govuk-body">We have sent your request to the relevant team.</p>
                    <h2 className="govuk-heading-m">What happens next</h2>
                    <p className="govuk-body">The target has been issued and is pending receipt by a frontline officer.</p>
                  </>
                )}
                onFinish={() => {
                  updateFormState('isIssueTargetFormOpen', false);
                  setSubmitted(false);
                }}
                setRefreshTaskData={() => setRefreshTaskData(true)}
                tabTitle={TAB_TITLES.TARGET_CREATED}
              />
            </IssueTarget>
          )}
          {formStates.isCompleteTaskFormOpen && (
            <CompleteTask
              taskId={taskId}
              onCancel={() => onCancelConfirmation(() => updateFormState('isCompleteTaskFormOpen', false))}
              isSubmitted={isSubmitted}
              setSubmitted={() => setSubmitted(true)}
            >
              <TaskOutcomeMessage
                message="Task has been completed"
                onFinish={() => {
                  updateFormState('isCompleteTaskFormOpen', false);
                  setSubmitted(false);
                }}
                setRefreshTaskData={() => setRefreshTaskData(true)}
                tabTitle={TAB_TITLES.TASK_COMPLETED}
              />
            </CompleteTask>
          )}
          {formStates.isDismissTaskFormOpen && (
            <DismissTask
              taskId={taskId}
              onCancel={() => onCancelConfirmation(() => updateFormState('isDismissTaskFormOpen', false))}
              isSubmitted={isSubmitted}
              setSubmitted={() => setSubmitted(true)}
            >
              <TaskOutcomeMessage
                message="Task has been dismissed"
                onFinish={() => {
                  updateFormState('isDismissTaskFormOpen', false);
                  setSubmitted(false);
                }}
                setRefreshTaskData={() => setRefreshTaskData(true)}
                tabTitle={TAB_TITLES.TASK_DISMISSED}
              />
            </DismissTask>
          )}
          {formStates.isRelistTaskFormOpen && (
            <RelistTask
              taskId={taskId}
              onCancel={() => onCancelConfirmation(() => updateFormState('isRelistTaskFormOpen', false))}
              isSubmitted={isSubmitted}
              setSubmitted={() => setSubmitted(true)}
            >
              <TaskOutcomeMessage
                message="Target successfully relisted"
                onFinish={() => {
                  updateFormState('isRelistTaskFormOpen', false);
                  setSubmitted(false);
                }}
                setRefreshTaskData={() => setRefreshTaskData(true)}
                displayInfo={false}
                tabTitle={TAB_TITLES.TARGET_RELISTED}
              />
            </RelistTask>
          )}
          {formStates.isWithdrawTaskFormOpen && (
            <WithdrawTask
              taskId={taskId}
              onCancel={() => onCancelConfirmation(() => updateFormState('isWithdrawTaskFormOpen', false))}
              isSubmitted={isSubmitted}
              setSubmitted={() => setSubmitted(true)}
            >
              <TaskOutcomeMessage
                message="Target successfully withdrawn"
                onFinish={() => {
                  updateFormState('isWithdrawTaskFormOpen', false);
                  setSubmitted(false);
                }}
                setRefreshTaskData={() => setRefreshTaskData(true)}
                displayInfo={false}
                tabTitle={TAB_TITLES.TARGET_WITHDRAWN}
              />
            </WithdrawTask>
          )}
          {formsClosed() && taskData && (
            <Tabs
              id="task-versions-data"
              items={[
                toTaskVersionsTab(taskData.versions, taskId),
                toTargetSheetTab(targetSheet),
                toBorderEventTab(hasEab, borderEvent),
              ].filter((tab) => !!tab)}
            />
          )}
        </div>
        <div className="govuk-grid-column-one-third">
          {shouldDisplayNotes() && (
            <TaskNotes
              displayForm={assignee === currentUser && TASK_STATUS.IN_PROGRESS === taskData?.status
                && formsClosed()}
              taskId={taskId}
              setRefreshTaskData={() => setRefreshTaskData(true)}
            />
          )}
          <ActivityLog
            activityLog={taskData?.notes}
          />
        </div>
      </div>
    </>
  );
};

export default InternalTaskDetailsPage;
